// 数据库中的送审记录
let first2secondsList1 = [
    {
        "subject_rank_id": "安全工程",
        "institution_id": "安全科学与工程"
    },
    {
        "subject_rank_id": "火炮、自动武器与弹药工程",
        "institution_id": "兵器科学与技术"
    },
    {
        "subject_rank_id": "智能武器技术与工程",
        "institution_id": "兵器科学与技术"
    },
    {
        "subject_rank_id": "兵器工程",
        "institution_id": "兵器科学与技术"
    },
    {
        "subject_rank_id": "兵器科学与技术",
        "institution_id": "兵器科学与技术"
    },
    {
        "subject_rank_id": "磁光电材料物性与器件",
        "institution_id": "材料科学与工程"
    },
    {
        "subject_rank_id": "材料学",
        "institution_id": "材料科学与工程"
    },
    {
        "subject_rank_id": "材料物理与化学",
        "institution_id": "材料科学与工程"
    },
    {
        "subject_rank_id": "材料加工工程",
        "institution_id": "材料科学与工程"
    },
    {
        "subject_rank_id": "材料工程",
        "institution_id": "材料科学与工程"
    },
    {
        "subject_rank_id": "材料科学与工程（核技术与材料工程）",
        "institution_id": "材料科学与工程"
    },
    {
        "subject_rank_id": "材料与化工",
        "institution_id": "材料科学与工程"
    },
    {
        "subject_rank_id": "材料科学与工程",
        "institution_id": "材料科学与工程"
    },
    {
        "subject_rank_id": "地图学与地理信息系统",
        "institution_id": "测绘科学与技术"
    },
    {
        "subject_rank_id": "地图制图学与地理信息工程",
        "institution_id": "测绘科学与技术"
    },
    {
        "subject_rank_id": "测绘工程",
        "institution_id": "测绘科学与技术"
    },
    {
        "subject_rank_id": "大地测量学与测量工程",
        "institution_id": "测绘科学与技术"
    },
    {
        "subject_rank_id": "城乡规划学",
        "institution_id": "城乡规划学"
    },
    {
        "subject_rank_id": "城市规划",
        "institution_id": "城乡规划学"
    },
    {
        "subject_rank_id": "畜牧",
        "institution_id": "畜牧学"
    },
    {
        "subject_rank_id": "畜牧学",
        "institution_id": "畜牧学"
    },
    {
        "subject_rank_id": "船舶与海洋工程",
        "institution_id": "船舶与海洋工程"
    },
    {
        "subject_rank_id": "人文地理学",
        "institution_id": "地理学"
    },
    {
        "subject_rank_id": "地质资源与地质工程",
        "institution_id": "地质资源与地质工程"
    },
    {
        "subject_rank_id": "地质工程",
        "institution_id": "地质资源与地质工程"
    },
    {
        "subject_rank_id": "电工理论与新技术",
        "institution_id": "电气工程"
    },
    {
        "subject_rank_id": "电力电子与电力传动",
        "institution_id": "电气工程"
    },
    {
        "subject_rank_id": "高电压与绝缘技术",
        "institution_id": "电气工程"
    },
    {
        "subject_rank_id": "电力系统及其自动化",
        "institution_id": "电气工程"
    },
    {
        "subject_rank_id": "智能电网与控制",
        "institution_id": "电气工程"
    },
    {
        "subject_rank_id": "电气工程",
        "institution_id": "电气工程"
    },
    {
        "subject_rank_id": "通信工程（含宽带网络、移动通信等）",
        "institution_id": "电子科学与技术"
    },
    {
        "subject_rank_id": "物理电子学",
        "institution_id": "电子科学与技术"
    },
    {
        "subject_rank_id": "微电子学与固体电子学",
        "institution_id": "电子科学与技术"
    },
    {
        "subject_rank_id": "电路与系统",
        "institution_id": "电子科学与技术"
    },
    {
        "subject_rank_id": "集成电路工程",
        "institution_id": "电子科学与技术"
    },
    {
        "subject_rank_id": "化工过程机械",
        "institution_id": "动力工程及工程热物理"
    },
    {
        "subject_rank_id": "流体机械及工程",
        "institution_id": "动力工程及工程热物理"
    },
    {
        "subject_rank_id": "新能源科学与工程",
        "institution_id": "动力工程及工程热物理"
    },
    {
        "subject_rank_id": "热能工程",
        "institution_id": "动力工程及工程热物理"
    },
    {
        "subject_rank_id": "工程热物理",
        "institution_id": "动力工程及工程热物理"
    },
    {
        "subject_rank_id": "动力工程",
        "institution_id": "动力工程及工程热物理"
    },
    {
        "subject_rank_id": "能源动力",
        "institution_id": "动力工程及工程热物理"
    },
    {
        "subject_rank_id": "动力工程与工程热物理",
        "institution_id": "动力工程与工程热物理"
    },
    {
        "subject_rank_id": "刑法学",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "民商法学",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "经济法学",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "环境与资源保护法学",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "法学理论",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "财税法学",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "诉讼法学",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "宪法学与行政法学",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "法律",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "法律（非法学）",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "法律（法学）",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "法学",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "服装产业经济与管理",
        "institution_id": "纺织科学与工程"
    },
    {
        "subject_rank_id": "服装设计与工程",
        "institution_id": "纺织科学与工程"
    },
    {
        "subject_rank_id": "纺织化学与染整工程",
        "institution_id": "纺织科学与工程"
    },
    {
        "subject_rank_id": "纺织工程",
        "institution_id": "纺织科学与工程"
    },
    {
        "subject_rank_id": "风景园林",
        "institution_id": "风景园林学"
    },
    {
        "subject_rank_id": "风景园林学",
        "institution_id": "风景园林学"
    },
    {
        "subject_rank_id": "大数据与财务管理",
        "institution_id": "工商管理"
    },
    {
        "subject_rank_id": "大数据与会计",
        "institution_id": "工商管理"
    },
    {
        "subject_rank_id": "电子商务",
        "institution_id": "工商管理"
    },
    {
        "subject_rank_id": "项目管理（领域）",
        "institution_id": "工商管理"
    },
    {
        "subject_rank_id": "技术经济及管理",
        "institution_id": "工商管理"
    },
    {
        "subject_rank_id": "知识产权与科技创新管理",
        "institution_id": "工商管理"
    },
    {
        "subject_rank_id": "商务统计学",
        "institution_id": "工商管理"
    },
    {
        "subject_rank_id": "高级工商管理",
        "institution_id": "工商管理"
    },
    {
        "subject_rank_id": "会计学",
        "institution_id": "工商管理学"
    },
    {
        "subject_rank_id": "企业管理",
        "institution_id": "工商管理学"
    },
    {
        "subject_rank_id": "工商管理",
        "institution_id": "工商管理学"
    },
    {
        "subject_rank_id": "会计",
        "institution_id": "工商管理学"
    },
    {
        "subject_rank_id": "公共管理（MPA)",
        "institution_id": "公共管理"
    },
    {
        "subject_rank_id": "教育经济与管理",
        "institution_id": "公共管理学"
    },
    {
        "subject_rank_id": "行政管理",
        "institution_id": "公共管理学"
    },
    {
        "subject_rank_id": "公共管理",
        "institution_id": "公共管理学"
    },
    {
        "subject_rank_id": "公共管理学",
        "institution_id": "公共管理学"
    },
    {
        "subject_rank_id": "流行病与卫生统计学",
        "institution_id": "公共卫生与预防医学"
    },
    {
        "subject_rank_id": "公共卫生与预防医学",
        "institution_id": "公共卫生与预防医学"
    },
    {
        "subject_rank_id": "公共卫生",
        "institution_id": "公共卫生与预防医学"
    },
    {
        "subject_rank_id": "健康管理",
        "institution_id": "管理科学与工程"
    },
    {
        "subject_rank_id": "工程造价",
        "institution_id": "管理科学与工程"
    },
    {
        "subject_rank_id": "现代物流管理",
        "institution_id": "管理科学与工程"
    },
    {
        "subject_rank_id": "物流管理",
        "institution_id": "管理科学与工程"
    },
    {
        "subject_rank_id": "工程管理MEM",
        "institution_id": "管理科学与工程"
    },
    {
        "subject_rank_id": "项目管理",
        "institution_id": "管理科学与工程"
    },
    {
        "subject_rank_id": "知识产权",
        "institution_id": "管理科学与工程"
    },
    {
        "subject_rank_id": "物流工程与管理",
        "institution_id": "管理科学与工程"
    },
    {
        "subject_rank_id": "工业工程与管理",
        "institution_id": "管理科学与工程"
    },
    {
        "subject_rank_id": "管理科学与工程",
        "institution_id": "管理科学与工程"
    },
    {
        "subject_rank_id": "工程管理",
        "institution_id": "管理科学与工程"
    },
    {
        "subject_rank_id": "光电功能与信息材料",
        "institution_id": "光学工程"
    },
    {
        "subject_rank_id": "光电信息工程",
        "institution_id": "光学工程"
    },
    {
        "subject_rank_id": "光学工程",
        "institution_id": "光学工程"
    },
    {
        "subject_rank_id": "航天仿生科学与技术",
        "institution_id": "航空宇航科学与技术"
    },
    {
        "subject_rank_id": "航空宇航推进理论与工程",
        "institution_id": "航空宇航科学与技术"
    },
    {
        "subject_rank_id": "航空宇航制造工程",
        "institution_id": "航空宇航科学与技术"
    },
    {
        "subject_rank_id": "人机与环境工程",
        "institution_id": "航空宇航科学与技术"
    },
    {
        "subject_rank_id": "航空宇航科学与技术",
        "institution_id": "航空宇航科学与技术"
    },
    {
        "subject_rank_id": "核科学与技术",
        "institution_id": "核科学与技术"
    },
    {
        "subject_rank_id": "护理",
        "institution_id": "护理学"
    },
    {
        "subject_rank_id": "高分子化学与物理",
        "institution_id": "化学"
    },
    {
        "subject_rank_id": "物理化学",
        "institution_id": "化学"
    },
    {
        "subject_rank_id": "有机化学",
        "institution_id": "化学"
    },
    {
        "subject_rank_id": "化学工程（领域）",
        "institution_id": "化学工程与技术"
    },
    {
        "subject_rank_id": "化学工艺",
        "institution_id": "化学工程与技术"
    },
    {
        "subject_rank_id": "工业催化",
        "institution_id": "化学工程与技术"
    },
    {
        "subject_rank_id": "生物化工",
        "institution_id": "化学工程与技术"
    },
    {
        "subject_rank_id": "应用化学",
        "institution_id": "化学工程与技术"
    },
    {
        "subject_rank_id": "化学工程",
        "institution_id": "化学工程与技术"
    },
    {
        "subject_rank_id": "化学工程与技术",
        "institution_id": "化学工程与技术"
    },
    {
        "subject_rank_id": "环境科学",
        "institution_id": "环境科学与工程"
    },
    {
        "subject_rank_id": "海岸带资源与环境",
        "institution_id": "环境科学与工程"
    },
    {
        "subject_rank_id": "资源与环境",
        "institution_id": "环境科学与工程"
    },
    {
        "subject_rank_id": "环境工程",
        "institution_id": "环境科学与工程"
    },
    {
        "subject_rank_id": "环境科学与工程",
        "institution_id": "环境科学与工程"
    },
    {
        "subject_rank_id": "机械设计制造及其自动化",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "机器人工程",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "汽车服务工程",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "工业机器人技术",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "机械设计制造及自动化",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "工业设计工程（领域）",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "先进制造",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "能源装备与过程控制",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "车辆工程",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "机械电子工程",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "工业工程",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "工业设计工程",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "机械设计及理论",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "机械工程（精密驱动与振动利用）",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "机械制造及其自动化",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "航空工程",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "机械工程",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "机械",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "人体解剖与组织胚胎学",
        "institution_id": "基础医学"
    },
    {
        "subject_rank_id": "法医学",
        "institution_id": "基础医学"
    },
    {
        "subject_rank_id": "基础医学",
        "institution_id": "基础医学"
    },
    {
        "subject_rank_id": "免疫学",
        "institution_id": "基础医学"
    },
    {
        "subject_rank_id": "病理学与病理生理学",
        "institution_id": "基础医学"
    },
    {
        "subject_rank_id": "大数据工程技术",
        "institution_id": "计算机科学与技术"
    },
    {
        "subject_rank_id": "网络工程技术",
        "institution_id": "计算机科学与技术"
    },
    {
        "subject_rank_id": "数字媒体技术",
        "institution_id": "计算机科学与技术"
    },
    {
        "subject_rank_id": "计算机技术（领域）",
        "institution_id": "计算机科学与技术"
    },
    {
        "subject_rank_id": "网络与信息安全",
        "institution_id": "计算机科学与技术"
    },
    {
        "subject_rank_id": "计算机应用技术",
        "institution_id": "计算机科学与技术"
    },
    {
        "subject_rank_id": "计算机技术",
        "institution_id": "计算机科学与技术"
    },
    {
        "subject_rank_id": "计算机科学与技术",
        "institution_id": "计算机科学与技术"
    },
    {
        "subject_rank_id": "环境设计及其理论",
        "institution_id": "建筑学"
    },
    {
        "subject_rank_id": "建筑",
        "institution_id": "建筑学"
    },
    {
        "subject_rank_id": "建筑学",
        "institution_id": "建筑学"
    },
    {
        "subject_rank_id": "建筑与土木工程",
        "institution_id": "建筑学"
    },
    {
        "subject_rank_id": "交通运输工程（领域）",
        "institution_id": "交通运输工程"
    },
    {
        "subject_rank_id": "载运工具运用工程",
        "institution_id": "交通运输工程"
    },
    {
        "subject_rank_id": "交通运输规划与管理",
        "institution_id": "交通运输工程"
    },
    {
        "subject_rank_id": "轨道交通运输",
        "institution_id": "交通运输工程"
    },
    {
        "subject_rank_id": "道路与铁道工程",
        "institution_id": "交通运输工程"
    },
    {
        "subject_rank_id": "水路交通运输",
        "institution_id": "交通运输工程"
    },
    {
        "subject_rank_id": "交通运输工程",
        "institution_id": "交通运输工程"
    },
    {
        "subject_rank_id": "交通运输",
        "institution_id": "交通运输工程"
    },
    {
        "subject_rank_id": "教育",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "学科教学（音乐）",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "学科教学（美术）",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "现代教育技术",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "教育技术学",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "学科教学（生物）",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "学科教学（英语）",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "职业技术教育学",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "学科教学（语文）",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "学科教学（物理）",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "学科教学（思政）",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "学科教学（数学）",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "学科教学（历史）",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "学科教学（化学）",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "学科教学（地理）",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "小学教育",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "汉语国际教育",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "职业技术教育",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "国际中文教育",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "教育管理",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "科学技术史",
        "institution_id": "科学技术史"
    },
    {
        "subject_rank_id": "控制工程（领域）",
        "institution_id": "控制科学与工程"
    },
    {
        "subject_rank_id": "农业工程与信息技术",
        "institution_id": "控制科学与工程"
    },
    {
        "subject_rank_id": "模式识别与智能系统",
        "institution_id": "控制科学与工程"
    },
    {
        "subject_rank_id": "检测技术与自动化装置",
        "institution_id": "控制科学与工程"
    },
    {
        "subject_rank_id": "控制理论与控制工程",
        "institution_id": "控制科学与工程"
    },
    {
        "subject_rank_id": "导航、制导与控制",
        "institution_id": "控制科学与工程"
    },
    {
        "subject_rank_id": "电子与信息",
        "institution_id": "控制科学与工程"
    },
    {
        "subject_rank_id": "控制工程",
        "institution_id": "控制科学与工程"
    },
    {
        "subject_rank_id": "电子信息",
        "institution_id": "控制科学与工程"
    },
    {
        "subject_rank_id": "控制科学与工程",
        "institution_id": "控制科学与工程"
    },
    {
        "subject_rank_id": "口腔临床医学",
        "institution_id": "口腔医学"
    },
    {
        "subject_rank_id": "口腔医学",
        "institution_id": "口腔医学"
    },
    {
        "subject_rank_id": "采矿工程",
        "institution_id": "矿业工程"
    },
    {
        "subject_rank_id": "世界经济",
        "institution_id": "理论经济学"
    },
    {
        "subject_rank_id": "固体力学",
        "institution_id": "力学"
    },
    {
        "subject_rank_id": "工程力学",
        "institution_id": "力学"
    },
    {
        "subject_rank_id": "一般力学与力学基础",
        "institution_id": "力学"
    },
    {
        "subject_rank_id": "流体力学",
        "institution_id": "力学"
    },
    {
        "subject_rank_id": "力学",
        "institution_id": "力学"
    },
    {
        "subject_rank_id": "老年医学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "临床病理学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "儿外科学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "重症医学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "精神病与精神卫生学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "核医学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "全科医学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "肿瘤学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "临床检验诊断学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "影像医学与核医学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "眼科学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "神经病学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "内科学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "临床病理",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "外科学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "麻醉学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "临床医学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "康复医学与理疗学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "急诊医学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "骨科学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "妇产科学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "放射肿瘤学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "放射影像学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "耳鼻咽喉科学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "儿科学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "超声医学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "皮肤病与性病学",
        "institution_id": "临床医学 "
    },
    {
        "subject_rank_id": "农业管理",
        "institution_id": "旅游管理"
    },
    {
        "subject_rank_id": "马克思主义与女性主义",
        "institution_id": "马克思主义理论"
    },
    {
        "subject_rank_id": "马克思主义中国化研究",
        "institution_id": "马克思主义理论"
    },
    {
        "subject_rank_id": "思想政治教育",
        "institution_id": "马克思主义理论"
    },
    {
        "subject_rank_id": "马克思主义基本原理",
        "institution_id": "马克思主义理论"
    },
    {
        "subject_rank_id": "美术",
        "institution_id": "美术学"
    },
    {
        "subject_rank_id": "农村发展",
        "institution_id": "农林经济管理"
    },
    {
        "subject_rank_id": "农业水土资源保护",
        "institution_id": "农业工程"
    },
    {
        "subject_rank_id": "农业生物环境与能源工程",
        "institution_id": "农业工程"
    },
    {
        "subject_rank_id": "农业水土工程",
        "institution_id": "农业工程"
    },
    {
        "subject_rank_id": "农田水土工程",
        "institution_id": "农业工程"
    },
    {
        "subject_rank_id": "资源利用与植物保护",
        "institution_id": "农业资源与环境"
    },
    {
        "subject_rank_id": "土壤学",
        "institution_id": "农业资源与环境"
    },
    {
        "subject_rank_id": "软件工程",
        "institution_id": "软件工程"
    },
    {
        "subject_rank_id": "艺术设计（领域）",
        "institution_id": "设计学"
    },
    {
        "subject_rank_id": "视觉艺术学",
        "institution_id": "设计学"
    },
    {
        "subject_rank_id": "工业设计",
        "institution_id": "设计学"
    },
    {
        "subject_rank_id": "设计艺术学",
        "institution_id": "设计学"
    },
    {
        "subject_rank_id": "社会工作",
        "institution_id": "社会学"
    },
    {
        "subject_rank_id": "植物生态学",
        "institution_id": "生态学"
    },
    {
        "subject_rank_id": "生物工程",
        "institution_id": "生物工程"
    },
    {
        "subject_rank_id": "植物学",
        "institution_id": "生物学"
    },
    {
        "subject_rank_id": "遗传学",
        "institution_id": "生物学"
    },
    {
        "subject_rank_id": "细胞生物学",
        "institution_id": "生物学"
    },
    {
        "subject_rank_id": "生物化学与分子生物学",
        "institution_id": "生物学"
    },
    {
        "subject_rank_id": "生理学",
        "institution_id": "生物学"
    },
    {
        "subject_rank_id": "微生物学",
        "institution_id": "生物学"
    },
    {
        "subject_rank_id": "生物技术与工程",
        "institution_id": "生物医学工程"
    },
    {
        "subject_rank_id": "生物与医药",
        "institution_id": "生物医学工程"
    },
    {
        "subject_rank_id": "制药工程",
        "institution_id": "生物医学工程"
    },
    {
        "subject_rank_id": "油气井工程",
        "institution_id": "石油与天然气工程"
    },
    {
        "subject_rank_id": "石油与天然气工程",
        "institution_id": "石油与天然气工程"
    },
    {
        "subject_rank_id": "食品加工与安全",
        "institution_id": "食品科学与工程"
    },
    {
        "subject_rank_id": "食品科学与工程",
        "institution_id": "食品科学与工程"
    },
    {
        "subject_rank_id": "应用数学",
        "institution_id": "数学"
    },
    {
        "subject_rank_id": "水利水电建设与管理",
        "institution_id": "水利工程"
    },
    {
        "subject_rank_id": "水利工程",
        "institution_id": "水利工程"
    },
    {
        "subject_rank_id": "港口海岸与近海工程",
        "institution_id": "水利工程"
    },
    {
        "subject_rank_id": "港口、海岸与近海工程",
        "institution_id": "水利工程"
    },
    {
        "subject_rank_id": "水利水电工程",
        "institution_id": "水利工程"
    },
    {
        "subject_rank_id": "港口、海岸及近海工程",
        "institution_id": "水利工程"
    },
    {
        "subject_rank_id": "水力学及河流动力学",
        "institution_id": "水利工程"
    },
    {
        "subject_rank_id": "水工结构工程",
        "institution_id": "水利工程"
    },
    {
        "subject_rank_id": "水文学及水资源",
        "institution_id": "水利工程"
    },
    {
        "subject_rank_id": "运动训练",
        "institution_id": "体育学"
    },
    {
        "subject_rank_id": "体育教育训练学",
        "institution_id": "体育学"
    },
    {
        "subject_rank_id": "体育教学",
        "institution_id": "体育学"
    },
    {
        "subject_rank_id": "体育",
        "institution_id": "体育学"
    },
    {
        "subject_rank_id": "应用统计学",
        "institution_id": "统计学"
    },
    {
        "subject_rank_id": "应用统计",
        "institution_id": "统计学"
    },
    {
        "subject_rank_id": "图书情报",
        "institution_id": "图书情报与档案管理"
    },
    {
        "subject_rank_id": "建筑与土木工程（领域）",
        "institution_id": "土木工程"
    },
    {
        "subject_rank_id": "供热、供燃气、通风及空调工程",
        "institution_id": "土木工程"
    },
    {
        "subject_rank_id": "岩土工程",
        "institution_id": "土木工程"
    },
    {
        "subject_rank_id": "市政工程",
        "institution_id": "土木工程"
    },
    {
        "subject_rank_id": "防灾减灾工程及防护工程",
        "institution_id": "土木工程"
    },
    {
        "subject_rank_id": "结构工程",
        "institution_id": "土木工程"
    },
    {
        "subject_rank_id": "桥梁与隧道工程",
        "institution_id": "土木工程"
    },
    {
        "subject_rank_id": "土木水利",
        "institution_id": "土木工程"
    },
    {
        "subject_rank_id": "土木工程",
        "institution_id": "土木工程"
    },
    {
        "subject_rank_id": "应用英语",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "商务英语",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "翻译",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "英语口译",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "日语语言文学",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "俄语语言文学",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "外国语言学及应用语言学",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "英语语言文学",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "英语笔译",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "网络空间安全",
        "institution_id": "网络空间安全"
    },
    {
        "subject_rank_id": "光学",
        "institution_id": "物理学"
    },
    {
        "subject_rank_id": "凝聚态物理",
        "institution_id": "物理学"
    },
    {
        "subject_rank_id": "理论物理",
        "institution_id": "物理学"
    },
    {
        "subject_rank_id": "艺术",
        "institution_id": "戏剧与影视学"
    },
    {
        "subject_rank_id": "广播电视",
        "institution_id": "戏剧与影视学"
    },
    {
        "subject_rank_id": "广播电视艺术学",
        "institution_id": "戏剧与影视学"
    },
    {
        "subject_rank_id": "系统科学",
        "institution_id": "系统科学"
    },
    {
        "subject_rank_id": "心理健康教育",
        "institution_id": "心理学"
    },
    {
        "subject_rank_id": "网络与新媒体",
        "institution_id": "新闻传播学"
    },
    {
        "subject_rank_id": "新闻与传播",
        "institution_id": "新闻传播学"
    },
    {
        "subject_rank_id": "物联网技术与应用",
        "institution_id": "信息与通信工程"
    },
    {
        "subject_rank_id": "信号与信息处理",
        "institution_id": "信息与通信工程"
    },
    {
        "subject_rank_id": "电子与通信工程",
        "institution_id": "信息与通信工程"
    },
    {
        "subject_rank_id": "通信工程",
        "institution_id": "信息与通信工程"
    },
    {
        "subject_rank_id": "通信与信息系统",
        "institution_id": "信息与通信工程"
    },
    {
        "subject_rank_id": "信息与通信工程",
        "institution_id": "信息与通信工程"
    },
    {
        "subject_rank_id": "学前教育",
        "institution_id": "学前教育学"
    },
    {
        "subject_rank_id": "药物分析学",
        "institution_id": "药学"
    },
    {
        "subject_rank_id": "药物化学",
        "institution_id": "药学"
    },
    {
        "subject_rank_id": "药剂学",
        "institution_id": "药学"
    },
    {
        "subject_rank_id": "生药学",
        "institution_id": "药学"
    },
    {
        "subject_rank_id": "药理学",
        "institution_id": "药学"
    },
    {
        "subject_rank_id": "药学",
        "institution_id": "药学"
    },
    {
        "subject_rank_id": "医学技术",
        "institution_id": "医学技术"
    },
    {
        "subject_rank_id": "仪器科学与技术（生物医学信息与仪器）",
        "institution_id": "仪器科学与技术"
    },
    {
        "subject_rank_id": "仪器仪表工程",
        "institution_id": "仪器科学与技术"
    },
    {
        "subject_rank_id": "精密仪器及机械",
        "institution_id": "仪器科学与技术"
    },
    {
        "subject_rank_id": "微系统与测控技术",
        "institution_id": "仪器科学与技术"
    },
    {
        "subject_rank_id": "测试计量技术及仪器",
        "institution_id": "仪器科学与技术"
    },
    {
        "subject_rank_id": "生物医学信息与仪器",
        "institution_id": "仪器科学与技术"
    },
    {
        "subject_rank_id": "仪器科学与技术",
        "institution_id": "仪器科学与技术"
    },
    {
        "subject_rank_id": "艺术设计",
        "institution_id": "艺术学"
    },
    {
        "subject_rank_id": "环境艺术设计",
        "institution_id": "艺术学理论"
    },
    {
        "subject_rank_id": "音乐",
        "institution_id": "音乐与舞蹈学"
    },
    {
        "subject_rank_id": "舞蹈",
        "institution_id": "音乐与舞蹈学"
    },
    {
        "subject_rank_id": "音乐学",
        "institution_id": "音乐与舞蹈学"
    },
    {
        "subject_rank_id": "政府经济学",
        "institution_id": "应用经济学"
    },
    {
        "subject_rank_id": "国民经济学",
        "institution_id": "应用经济学"
    },
    {
        "subject_rank_id": "劳动经济学",
        "institution_id": "应用经济学"
    },
    {
        "subject_rank_id": "区域经济学",
        "institution_id": "应用经济学"
    },
    {
        "subject_rank_id": "国际商务",
        "institution_id": "应用经济学"
    },
    {
        "subject_rank_id": "金融学",
        "institution_id": "应用经济学"
    },
    {
        "subject_rank_id": "金融",
        "institution_id": "应用经济学"
    },
    {
        "subject_rank_id": "科学技术哲学",
        "institution_id": "哲学"
    },
    {
        "subject_rank_id": "中共党史",
        "institution_id": "政治学"
    },
    {
        "subject_rank_id": "政治学理论",
        "institution_id": "政治学"
    },
    {
        "subject_rank_id": "科学社会主义与国际共产主义运动",
        "institution_id": "政治学"
    },
    {
        "subject_rank_id": "国际政治",
        "institution_id": "政治学"
    },
    {
        "subject_rank_id": "历史文献学（含∶敦煌学、古文字学）",
        "institution_id": "中国史"
    },
    {
        "subject_rank_id": "中国少数民族语言文学",
        "institution_id": "中国语言文学"
    },
    {
        "subject_rank_id": "文艺学",
        "institution_id": "中国语言文学"
    },
    {
        "subject_rank_id": "中西医结合基础",
        "institution_id": "中西医结合"
    },
    {
        "subject_rank_id": "中西医结合临床",
        "institution_id": "中西医结合"
    },
    {
        "subject_rank_id": "中药",
        "institution_id": "中药学"
    },
    {
        "subject_rank_id": "中医临床及基础",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中医诊断学",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中医医史文献",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中医临床基础",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中医基础理论",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "方剂学",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中医五官科学",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中医外科学",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中医内科学",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中医骨伤科学",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中医妇科学",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中医儿科学",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "针灸推拿学",
        "institution_id": "中医学"
    },

]


// 学硕一级学科对应表
let first2secondsList2 = [
    {
        "subject_rank_id": "哲学",
        "institution_id": "哲学"
    },
    {
        "subject_rank_id": "马克思主义哲学",
        "institution_id": "哲学"
    },
    {
        "subject_rank_id": "中国哲学",
        "institution_id": "哲学"
    },
    {
        "subject_rank_id": "外国哲学",
        "institution_id": "哲学"
    },
    {
        "subject_rank_id": "逻辑学",
        "institution_id": "哲学"
    },
    {
        "subject_rank_id": "伦理学",
        "institution_id": "哲学"
    },
    {
        "subject_rank_id": "美学",
        "institution_id": "哲学"
    },
    {
        "subject_rank_id": "宗教学",
        "institution_id": "哲学"
    },
    {
        "subject_rank_id": "科学技术哲学",
        "institution_id": "哲学"
    },
    {
        "subject_rank_id": "理论经济学",
        "institution_id": "理论经济学"
    },
    {
        "subject_rank_id": "政治经济学",
        "institution_id": "理论经济学"
    },
    {
        "subject_rank_id": "经济思想史",
        "institution_id": "理论经济学"
    },
    {
        "subject_rank_id": "经济史",
        "institution_id": "理论经济学"
    },
    {
        "subject_rank_id": "西方经济学",
        "institution_id": "理论经济学"
    },
    {
        "subject_rank_id": "世界经济",
        "institution_id": "理论经济学"
    },
    {
        "subject_rank_id": "人口、资源与环境经济学",
        "institution_id": "理论经济学"
    },
    {
        "subject_rank_id": "应用经济学",
        "institution_id": "应用经济学"
    },
    {
        "subject_rank_id": "国民经济学",
        "institution_id": "应用经济学"
    },
    {
        "subject_rank_id": "区域经济学",
        "institution_id": "应用经济学"
    },
    {
        "subject_rank_id": "财政学",
        "institution_id": "应用经济学"
    },
    {
        "subject_rank_id": "金融学",
        "institution_id": "应用经济学"
    },
    {
        "subject_rank_id": "产业经济学",
        "institution_id": "应用经济学"
    },
    {
        "subject_rank_id": "国际贸易学",
        "institution_id": "应用经济学"
    },
    {
        "subject_rank_id": "劳动经济学",
        "institution_id": "应用经济学"
    },
    {
        "subject_rank_id": "统计学",
        "institution_id": "应用经济学"
    },
    {
        "subject_rank_id": "数量经济学",
        "institution_id": "应用经济学"
    },
    {
        "subject_rank_id": "国防经济",
        "institution_id": "应用经济学"
    },
    {
        "subject_rank_id": "统计学",
        "institution_id": "统计学"
    },
    {
        "subject_rank_id": "法学",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "法学理论",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "法律史",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "宪法学与行政法学",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "刑法学",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "民商法学",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "诉讼法学",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "经济法学",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "环境与资源保护法学",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "国际法学",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "军事法学",
        "institution_id": "法学"
    },
    {
        "subject_rank_id": "政治学",
        "institution_id": "政治学"
    },
    {
        "subject_rank_id": "政治学理论",
        "institution_id": "政治学"
    },
    {
        "subject_rank_id": "中外政治制度",
        "institution_id": "政治学"
    },
    {
        "subject_rank_id": "科学社会主义与国际共产主义运动",
        "institution_id": "政治学"
    },
    {
        "subject_rank_id": "中共党史",
        "institution_id": "政治学"
    },
    {
        "subject_rank_id": "国际政治",
        "institution_id": "政治学"
    },
    {
        "subject_rank_id": "国际关系",
        "institution_id": "政治学"
    },
    {
        "subject_rank_id": "外交学",
        "institution_id": "政治学"
    },
    {
        "subject_rank_id": "社会学",
        "institution_id": "社会学"
    },
    {
        "subject_rank_id": "社会学",
        "institution_id": "社会学"
    },
    {
        "subject_rank_id": "人口学",
        "institution_id": "社会学"
    },
    {
        "subject_rank_id": "人类学",
        "institution_id": "社会学"
    },
    {
        "subject_rank_id": "民俗学",
        "institution_id": "社会学"
    },
    {
        "subject_rank_id": "民族学",
        "institution_id": "民族学"
    },
    {
        "subject_rank_id": "民族学",
        "institution_id": "民族学"
    },
    {
        "subject_rank_id": "马克思主义民族理论与政策",
        "institution_id": "民族学"
    },
    {
        "subject_rank_id": "中国少数民族经济",
        "institution_id": "民族学"
    },
    {
        "subject_rank_id": "中国少数民族史",
        "institution_id": "民族学"
    },
    {
        "subject_rank_id": "中国少数民族艺术",
        "institution_id": "民族学"
    },
    {
        "subject_rank_id": "马克思主义理论",
        "institution_id": "马克思主义理论"
    },
    {
        "subject_rank_id": "马克思主义基本原理",
        "institution_id": "马克思主义理论"
    },
    {
        "subject_rank_id": "马克思主义发展史",
        "institution_id": "马克思主义理论"
    },
    {
        "subject_rank_id": "马克思主义中国化研究",
        "institution_id": "马克思主义理论"
    },
    {
        "subject_rank_id": "国外马克思主义研究",
        "institution_id": "马克思主义理论"
    },
    {
        "subject_rank_id": "思想政治教育",
        "institution_id": "马克思主义理论"
    },
    {
        "subject_rank_id": "中国近现代史基本问题研究",
        "institution_id": "马克思主义理论"
    },
    {
        "subject_rank_id": "公安学",
        "institution_id": "公安学"
    },
    {
        "subject_rank_id": "教育学",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "教育学原理",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "课程与教学论",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "教育史",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "比较教育学",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "学前教育学",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "高等教育学",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "成人教育学",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "职业技术教育学",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "特殊教育学",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "教育技术学",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "教育法学",
        "institution_id": "教育学"
    },
    {
        "subject_rank_id": "心理学",
        "institution_id": "心理学"
    },
    {
        "subject_rank_id": "基础心理学",
        "institution_id": "心理学"
    },
    {
        "subject_rank_id": "发展与教育心理学",
        "institution_id": "心理学"
    },
    {
        "subject_rank_id": "应用心理学",
        "institution_id": "心理学"
    },
    {
        "subject_rank_id": "体育学",
        "institution_id": "体育学"
    },
    {
        "subject_rank_id": "体育人文社会学",
        "institution_id": "体育学"
    },
    {
        "subject_rank_id": "运动人体科学",
        "institution_id": "体育学"
    },
    {
        "subject_rank_id": "体育教育训练学",
        "institution_id": "体育学"
    },
    {
        "subject_rank_id": "民族传统体育学",
        "institution_id": "体育学"
    },
    {
        "subject_rank_id": "中国语言文学",
        "institution_id": "中国语言文学"
    },
    {
        "subject_rank_id": "文艺学",
        "institution_id": "中国语言文学"
    },
    {
        "subject_rank_id": "语言学及应用语言学",
        "institution_id": "中国语言文学"
    },
    {
        "subject_rank_id": "汉语言文字学",
        "institution_id": "中国语言文学"
    },
    {
        "subject_rank_id": "中国古典文献学",
        "institution_id": "中国语言文学"
    },
    {
        "subject_rank_id": "中国古代文学",
        "institution_id": "中国语言文学"
    },
    {
        "subject_rank_id": "中国现当代文学",
        "institution_id": "中国语言文学"
    },
    {
        "subject_rank_id": "中国少数民族语言文学",
        "institution_id": "中国语言文学"
    },
    {
        "subject_rank_id": "比较文学与世界文学",
        "institution_id": "中国语言文学"
    },
    {
        "subject_rank_id": "外国语言文学",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "英语语言文学",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "俄语语言文学",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "法语语言文学",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "德语语言文学",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "日语语言文学",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "印度语言文学",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "西班牙语语言文学",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "阿拉伯语语言文学",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "欧洲语言文学",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "亚非语言文学",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "外国语言学及应用语言学",
        "institution_id": "外国语言文学"
    },
    {
        "subject_rank_id": "新闻传播学",
        "institution_id": "新闻传播学"
    },
    {
        "subject_rank_id": "新闻学",
        "institution_id": "新闻传播学"
    },
    {
        "subject_rank_id": "传播学",
        "institution_id": "新闻传播学"
    },
    {
        "subject_rank_id": "考古学",
        "institution_id": "考古学"
    },
    {
        "subject_rank_id": "中国史",
        "institution_id": "中国史"
    },
    {
        "subject_rank_id": "历史文献学（含∶敦煌学、古文字学）",
        "institution_id": "中国史"
    },
    {
        "subject_rank_id": "中国古代史",
        "institution_id": "中国史"
    },
    {
        "subject_rank_id": "中国近现代史",
        "institution_id": "中国史"
    },
    {
        "subject_rank_id": "世界史",
        "institution_id": "世界史"
    },
    {
        "subject_rank_id": "数学",
        "institution_id": "数学"
    },
    {
        "subject_rank_id": "基础数学",
        "institution_id": "数学"
    },
    {
        "subject_rank_id": "计算数学",
        "institution_id": "数学"
    },
    {
        "subject_rank_id": "概率论与数理统计",
        "institution_id": "数学"
    },
    {
        "subject_rank_id": "应用数学",
        "institution_id": "数学"
    },
    {
        "subject_rank_id": "运筹学与控制论",
        "institution_id": "数学"
    },
    {
        "subject_rank_id": "物理学",
        "institution_id": "物理学"
    },
    {
        "subject_rank_id": "理论物理",
        "institution_id": "物理学"
    },
    {
        "subject_rank_id": "粒子物理与原子核物理",
        "institution_id": "物理学"
    },
    {
        "subject_rank_id": "原子与分子物理",
        "institution_id": "物理学"
    },
    {
        "subject_rank_id": "等离子体物理",
        "institution_id": "物理学"
    },
    {
        "subject_rank_id": "凝聚态物理",
        "institution_id": "物理学"
    },
    {
        "subject_rank_id": "声学",
        "institution_id": "物理学"
    },
    {
        "subject_rank_id": "光学",
        "institution_id": "物理学"
    },
    {
        "subject_rank_id": "无线电物理",
        "institution_id": "物理学"
    },
    {
        "subject_rank_id": "化学",
        "institution_id": "化学"
    },
    {
        "subject_rank_id": "无机化学",
        "institution_id": "化学"
    },
    {
        "subject_rank_id": "分析化学",
        "institution_id": "化学"
    },
    {
        "subject_rank_id": "有机化学",
        "institution_id": "化学"
    },
    {
        "subject_rank_id": "物理化学",
        "institution_id": "化学"
    },
    {
        "subject_rank_id": "高分子化学与物理",
        "institution_id": "化学"
    },
    {
        "subject_rank_id": "天文学",
        "institution_id": "天文学"
    },
    {
        "subject_rank_id": "天体物理",
        "institution_id": "天文学"
    },
    {
        "subject_rank_id": "天体测量与天体力学",
        "institution_id": "天文学"
    },
    {
        "subject_rank_id": "地理学",
        "institution_id": "地理学"
    },
    {
        "subject_rank_id": "自然地理学",
        "institution_id": "地理学"
    },
    {
        "subject_rank_id": "人文地理学",
        "institution_id": "地理学"
    },
    {
        "subject_rank_id": "地图学与地理信息系统",
        "institution_id": "地理学"
    },
    {
        "subject_rank_id": "大气科学",
        "institution_id": "大气科学"
    },
    {
        "subject_rank_id": "气象学",
        "institution_id": "大气科学"
    },
    {
        "subject_rank_id": "大气物理学与大气环境",
        "institution_id": "大气科学"
    },
    {
        "subject_rank_id": "海洋科学",
        "institution_id": "海洋科学"
    },
    {
        "subject_rank_id": "物理海洋学",
        "institution_id": "海洋科学"
    },
    {
        "subject_rank_id": "海洋化学",
        "institution_id": "海洋科学"
    },
    {
        "subject_rank_id": "海洋生物学",
        "institution_id": "海洋科学"
    },
    {
        "subject_rank_id": "海洋地质",
        "institution_id": "海洋科学"
    },
    {
        "subject_rank_id": "地球物理学",
        "institution_id": "地球物理学"
    },
    {
        "subject_rank_id": "固体地球物理学",
        "institution_id": "地球物理学"
    },
    {
        "subject_rank_id": "空间物理学",
        "institution_id": "地球物理学"
    },
    {
        "subject_rank_id": "地质学",
        "institution_id": "地质学"
    },
    {
        "subject_rank_id": "矿物学、岩石学、矿床学",
        "institution_id": "地质学"
    },
    {
        "subject_rank_id": "地球化学",
        "institution_id": "地质学"
    },
    {
        "subject_rank_id": "古生物学与地层学",
        "institution_id": "地质学"
    },
    {
        "subject_rank_id": "构造地质学",
        "institution_id": "地质学"
    },
    {
        "subject_rank_id": "第四纪地质学",
        "institution_id": "地质学"
    },
    {
        "subject_rank_id": "生物学",
        "institution_id": "生物学"
    },
    {
        "subject_rank_id": "植物学",
        "institution_id": "生物学"
    },
    {
        "subject_rank_id": "动物学",
        "institution_id": "生物学"
    },
    {
        "subject_rank_id": "生理学",
        "institution_id": "生物学"
    },
    {
        "subject_rank_id": "水生生物学",
        "institution_id": "生物学"
    },
    {
        "subject_rank_id": "微生物学",
        "institution_id": "生物学"
    },
    {
        "subject_rank_id": "神经生物学",
        "institution_id": "生物学"
    },
    {
        "subject_rank_id": "遗传学",
        "institution_id": "生物学"
    },
    {
        "subject_rank_id": "发育生物学",
        "institution_id": "生物学"
    },
    {
        "subject_rank_id": "细胞生物学",
        "institution_id": "生物学"
    },
    {
        "subject_rank_id": "生物化学与分子生物学",
        "institution_id": "生物学"
    },
    {
        "subject_rank_id": "生物物理学",
        "institution_id": "生物学"
    },
    {
        "subject_rank_id": "系统科学",
        "institution_id": "系统科学"
    },
    {
        "subject_rank_id": "系统理论",
        "institution_id": "系统科学"
    },
    {
        "subject_rank_id": "系统分析与集成",
        "institution_id": "系统科学"
    },
    {
        "subject_rank_id": "科学技术史",
        "institution_id": "科学技术史"
    },
    {
        "subject_rank_id": "生态学",
        "institution_id": "生态学"
    },
    {
        "subject_rank_id": "统计学",
        "institution_id": "统计学"
    },
    {
        "subject_rank_id": "心理学",
        "institution_id": "心理学"
    },
    {
        "subject_rank_id": "基础心理学",
        "institution_id": "心理学"
    },
    {
        "subject_rank_id": "发展与教育心理学",
        "institution_id": "心理学"
    },
    {
        "subject_rank_id": "应用心理学",
        "institution_id": "心理学"
    },
    {
        "subject_rank_id": "力学",
        "institution_id": "力学"
    },
    {
        "subject_rank_id": "一般力学与力学基础",
        "institution_id": "力学"
    },
    {
        "subject_rank_id": "固体力学",
        "institution_id": "力学"
    },
    {
        "subject_rank_id": "流体力学",
        "institution_id": "力学"
    },
    {
        "subject_rank_id": "工程力学",
        "institution_id": "力学"
    },
    {
        "subject_rank_id": "材料科学与工程",
        "institution_id": "材料科学与工程"
    },
    {
        "subject_rank_id": "材料物理与化学",
        "institution_id": "材料科学与工程"
    },
    {
        "subject_rank_id": "材料学",
        "institution_id": "材料科学与工程"
    },
    {
        "subject_rank_id": "材料加工工程",
        "institution_id": "材料科学与工程"
    },
    {
        "subject_rank_id": "电子科学与技术",
        "institution_id": "电子科学与技术"
    },
    {
        "subject_rank_id": "物理电子学",
        "institution_id": "电子科学与技术"
    },
    {
        "subject_rank_id": "电路与系统",
        "institution_id": "电子科学与技术"
    },
    {
        "subject_rank_id": "微电子学与固体电子学",
        "institution_id": "电子科学与技术"
    },
    {
        "subject_rank_id": "电磁场与微波技术",
        "institution_id": "电子科学与技术"
    },
    {
        "subject_rank_id": "计算机科学与技术",
        "institution_id": "计算机科学与技术"
    },
    {
        "subject_rank_id": "计算机系统结构",
        "institution_id": "计算机科学与技术"
    },
    {
        "subject_rank_id": "计算机软件与理论",
        "institution_id": "计算机科学与技术"
    },
    {
        "subject_rank_id": "计算机应用技术",
        "institution_id": "计算机科学与技术"
    },
    {
        "subject_rank_id": "环境科学与工程",
        "institution_id": "环境科学与工程"
    },
    {
        "subject_rank_id": "环境科学",
        "institution_id": "环境科学与工程"
    },
    {
        "subject_rank_id": "环境工程",
        "institution_id": "环境科学与工程"
    },
    {
        "subject_rank_id": "生物医学工程",
        "institution_id": "生物医学工程"
    },
    {
        "subject_rank_id": "基础医学",
        "institution_id": "基础医学"
    },
    {
        "subject_rank_id": "人体解剖与组织胚胎学",
        "institution_id": "基础医学"
    },
    {
        "subject_rank_id": "免疫学",
        "institution_id": "基础医学"
    },
    {
        "subject_rank_id": "病原生物学",
        "institution_id": "基础医学"
    },
    {
        "subject_rank_id": "病理学与病理生理学",
        "institution_id": "基础医学"
    },
    {
        "subject_rank_id": "法医学",
        "institution_id": "基础医学"
    },
    {
        "subject_rank_id": "放射医学",
        "institution_id": "基础医学"
    },
    {
        "subject_rank_id": "公共卫生与预防医学",
        "institution_id": "公共卫生与预防医学"
    },
    {
        "subject_rank_id": "流行病与卫生统计学",
        "institution_id": "公共卫生与预防医学"
    },
    {
        "subject_rank_id": "劳动卫生与环境卫生学",
        "institution_id": "公共卫生与预防医学"
    },
    {
        "subject_rank_id": "营养与食品卫生学",
        "institution_id": "公共卫生与预防医学"
    },
    {
        "subject_rank_id": "儿少卫生与妇幼保健学",
        "institution_id": "公共卫生与预防医学"
    },
    {
        "subject_rank_id": "卫生毒理学",
        "institution_id": "公共卫生与预防医学"
    },
    {
        "subject_rank_id": "军事预防医学",
        "institution_id": "公共卫生与预防医学"
    },
    {
        "subject_rank_id": "药学",
        "institution_id": "药学"
    },
    {
        "subject_rank_id": "药物化学",
        "institution_id": "药学"
    },
    {
        "subject_rank_id": "药剂学",
        "institution_id": "药学"
    },
    {
        "subject_rank_id": "生药学",
        "institution_id": "药学"
    },
    {
        "subject_rank_id": "药物分析学",
        "institution_id": "药学"
    },
    {
        "subject_rank_id": "微生物与生化药学",
        "institution_id": "药学"
    },
    {
        "subject_rank_id": "药理学",
        "institution_id": "药学"
    },
    {
        "subject_rank_id": "中药学",
        "institution_id": "中药学"
    },
    {
        "subject_rank_id": "医学技术",
        "institution_id": "医学技术"
    },
    {
        "subject_rank_id": "护理学",
        "institution_id": "护理学"
    },
    {
        "subject_rank_id": "力学",
        "institution_id": "力学"
    },
    {
        "subject_rank_id": "一般力学与力学基础",
        "institution_id": "力学"
    },
    {
        "subject_rank_id": "固体力学",
        "institution_id": "力学"
    },
    {
        "subject_rank_id": "流体力学",
        "institution_id": "力学"
    },
    {
        "subject_rank_id": "工程力学",
        "institution_id": "力学"
    },
    {
        "subject_rank_id": "机械工程",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "机械制造及其自动化",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "机械电子工程",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "机械设计及理论",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "车辆工程",
        "institution_id": "机械工程"
    },
    {
        "subject_rank_id": "光学工程",
        "institution_id": "光学工程"
    },
    {
        "subject_rank_id": "仪器科学与技术",
        "institution_id": "仪器科学与技术"
    },
    {
        "subject_rank_id": "精密仪器及机械",
        "institution_id": "仪器科学与技术"
    },
    {
        "subject_rank_id": "测试计量技术及仪器",
        "institution_id": "仪器科学与技术"
    },
    {
        "subject_rank_id": "材料科学与工程",
        "institution_id": "材料科学与工程"
    },
    {
        "subject_rank_id": "材料物理与化学",
        "institution_id": "材料科学与工程"
    },
    {
        "subject_rank_id": "材料学",
        "institution_id": "材料科学与工程"
    },
    {
        "subject_rank_id": "材料加工工程",
        "institution_id": "材料科学与工程"
    },
    {
        "subject_rank_id": "冶金工程",
        "institution_id": "冶金工程"
    },
    {
        "subject_rank_id": "冶金物理化学",
        "institution_id": "冶金工程"
    },
    {
        "subject_rank_id": "钢铁冶金",
        "institution_id": "冶金工程"
    },
    {
        "subject_rank_id": "有色金属冶金",
        "institution_id": "冶金工程"
    },
    {
        "subject_rank_id": "动力工程及工程热物理",
        "institution_id": "动力工程及工程热物理"
    },
    {
        "subject_rank_id": "工程热物理",
        "institution_id": "动力工程及工程热物理"
    },
    {
        "subject_rank_id": "热能工程",
        "institution_id": "动力工程及工程热物理"
    },
    {
        "subject_rank_id": "动力机械及工程",
        "institution_id": "动力工程及工程热物理"
    },
    {
        "subject_rank_id": "流体机械及工程",
        "institution_id": "动力工程及工程热物理"
    },
    {
        "subject_rank_id": "制冷及低温工程",
        "institution_id": "动力工程及工程热物理"
    },
    {
        "subject_rank_id": "化工过程机械",
        "institution_id": "动力工程及工程热物理"
    },
    {
        "subject_rank_id": "电气工程",
        "institution_id": "电气工程"
    },
    {
        "subject_rank_id": "电机与电器",
        "institution_id": "电气工程"
    },
    {
        "subject_rank_id": "电力系统及其自动化",
        "institution_id": "电气工程"
    },
    {
        "subject_rank_id": "高电压与绝缘技术",
        "institution_id": "电气工程"
    },
    {
        "subject_rank_id": "电力电子与电力传动",
        "institution_id": "电气工程"
    },
    {
        "subject_rank_id": "电工理论与新技术",
        "institution_id": "电气工程"
    },
    {
        "subject_rank_id": "电子科学与技术",
        "institution_id": "电子科学与技术"
    },
    {
        "subject_rank_id": "物理电子学",
        "institution_id": "电子科学与技术"
    },
    {
        "subject_rank_id": "电路与系统",
        "institution_id": "电子科学与技术"
    },
    {
        "subject_rank_id": "微电子学与固体电子学",
        "institution_id": "电子科学与技术"
    },
    {
        "subject_rank_id": "电磁场与微波技术",
        "institution_id": "电子科学与技术"
    },
    {
        "subject_rank_id": "信息与通信工程",
        "institution_id": "信息与通信工程"
    },
    {
        "subject_rank_id": "通信与信息系统",
        "institution_id": "信息与通信工程"
    },
    {
        "subject_rank_id": "信号与信息处理",
        "institution_id": "信息与通信工程"
    },
    {
        "subject_rank_id": "控制科学与工程",
        "institution_id": "控制科学与工程"
    },
    {
        "subject_rank_id": "控制理论与控制工程",
        "institution_id": "控制科学与工程"
    },
    {
        "subject_rank_id": "检测技术与自动化装置",
        "institution_id": "控制科学与工程"
    },
    {
        "subject_rank_id": "系统工程",
        "institution_id": "控制科学与工程"
    },
    {
        "subject_rank_id": "模式识别与智能系统",
        "institution_id": "控制科学与工程"
    },
    {
        "subject_rank_id": "导航、制导与控制",
        "institution_id": "控制科学与工程"
    },
    {
        "subject_rank_id": "计算机科学与技术",
        "institution_id": "计算机科学与技术"
    },
    {
        "subject_rank_id": "计算机系统结构",
        "institution_id": "计算机科学与技术"
    },
    {
        "subject_rank_id": "计算机软件与理论",
        "institution_id": "计算机科学与技术"
    },
    {
        "subject_rank_id": "计算机应用技术",
        "institution_id": "计算机科学与技术"
    },
    {
        "subject_rank_id": "建筑学",
        "institution_id": "建筑学"
    },
    {
        "subject_rank_id": "建筑历史与理论",
        "institution_id": "建筑学"
    },
    {
        "subject_rank_id": "建筑设计及其理论",
        "institution_id": "建筑学"
    },
    {
        "subject_rank_id": "建筑技术科学",
        "institution_id": "建筑学"
    },
    {
        "subject_rank_id": "土木工程",
        "institution_id": "土木工程"
    },
    {
        "subject_rank_id": "岩土工程",
        "institution_id": "土木工程"
    },
    {
        "subject_rank_id": "结构工程",
        "institution_id": "土木工程"
    },
    {
        "subject_rank_id": "市政工程",
        "institution_id": "土木工程"
    },
    {
        "subject_rank_id": "供热、供燃气、通风及空调工程",
        "institution_id": "土木工程"
    },
    {
        "subject_rank_id": "防灾减灾工程及防护工程",
        "institution_id": "土木工程"
    },
    {
        "subject_rank_id": "桥梁与隧道工程",
        "institution_id": "土木工程"
    },
    {
        "subject_rank_id": "水利工程",
        "institution_id": "水利工程"
    },
    {
        "subject_rank_id": "水文学及水资源",
        "institution_id": "水利工程"
    },
    {
        "subject_rank_id": "水力学及河流动力学",
        "institution_id": "水利工程"
    },
    {
        "subject_rank_id": "水工结构工程",
        "institution_id": "水利工程"
    },
    {
        "subject_rank_id": "水利水电工程",
        "institution_id": "水利工程"
    },
    {
        "subject_rank_id": "港口、海岸及近海工程",
        "institution_id": "水利工程"
    },
    {
        "subject_rank_id": "测绘科学与技术",
        "institution_id": "测绘科学与技术"
    },
    {
        "subject_rank_id": "大地测量学与测量工程",
        "institution_id": "测绘科学与技术"
    },
    {
        "subject_rank_id": "摄影测量与遥感",
        "institution_id": "测绘科学与技术"
    },
    {
        "subject_rank_id": "地图制图学与地理信息工程",
        "institution_id": "测绘科学与技术"
    },
    {
        "subject_rank_id": "化学工程与技术",
        "institution_id": "化学工程与技术"
    },
    {
        "subject_rank_id": "化学工程",
        "institution_id": "化学工程与技术"
    },
    {
        "subject_rank_id": "化学工艺",
        "institution_id": "化学工程与技术"
    },
    {
        "subject_rank_id": "生物化工",
        "institution_id": "化学工程与技术"
    },
    {
        "subject_rank_id": "应用化学",
        "institution_id": "化学工程与技术"
    },
    {
        "subject_rank_id": "工业催化",
        "institution_id": "化学工程与技术"
    },
    {
        "subject_rank_id": "地质资源与地质工程",
        "institution_id": "地质资源与地质工程"
    },
    {
        "subject_rank_id": "矿产普查与勘探",
        "institution_id": "地质资源与地质工程"
    },
    {
        "subject_rank_id": "地球探测与信息技术",
        "institution_id": "地质资源与地质工程"
    },
    {
        "subject_rank_id": "地质工程",
        "institution_id": "地质资源与地质工程"
    },
    {
        "subject_rank_id": "矿业工程",
        "institution_id": "矿业工程"
    },
    {
        "subject_rank_id": "采矿工程",
        "institution_id": "矿业工程"
    },
    {
        "subject_rank_id": "矿物加工工程",
        "institution_id": "矿业工程"
    },
    {
        "subject_rank_id": "安全技术及工程",
        "institution_id": "矿业工程"
    },
    {
        "subject_rank_id": "石油与天然气工程",
        "institution_id": "石油与天然气工程"
    },
    {
        "subject_rank_id": "油气井工程",
        "institution_id": "石油与天然气工程"
    },
    {
        "subject_rank_id": "油气田开发工程",
        "institution_id": "石油与天然气工程"
    },
    {
        "subject_rank_id": "油气储运工程",
        "institution_id": "石油与天然气工程"
    },
    {
        "subject_rank_id": "纺织科学与工程",
        "institution_id": "纺织科学与工程"
    },
    {
        "subject_rank_id": "纺织工程",
        "institution_id": "纺织科学与工程"
    },
    {
        "subject_rank_id": "纺织材料与纺织品设计",
        "institution_id": "纺织科学与工程"
    },
    {
        "subject_rank_id": "纺织化学与染整工程",
        "institution_id": "纺织科学与工程"
    },
    {
        "subject_rank_id": "服装设计与工程",
        "institution_id": "纺织科学与工程"
    },
    {
        "subject_rank_id": "轻工技术与工程",
        "institution_id": "轻工技术与工程"
    },
    {
        "subject_rank_id": "制浆造纸工程",
        "institution_id": "轻工技术与工程"
    },
    {
        "subject_rank_id": "制糖工程",
        "institution_id": "轻工技术与工程"
    },
    {
        "subject_rank_id": "发酵工程",
        "institution_id": "轻工技术与工程"
    },
    {
        "subject_rank_id": "皮革化学与工程",
        "institution_id": "轻工技术与工程"
    },
    {
        "subject_rank_id": "交通运输工程",
        "institution_id": "交通运输工程"
    },
    {
        "subject_rank_id": "道路与铁道工程",
        "institution_id": "交通运输工程"
    },
    {
        "subject_rank_id": "交通信息工程及控制",
        "institution_id": "交通运输工程"
    },
    {
        "subject_rank_id": "交通运输规划与管理",
        "institution_id": "交通运输工程"
    },
    {
        "subject_rank_id": "载运工具运用工程",
        "institution_id": "交通运输工程"
    },
    {
        "subject_rank_id": "船舶与海洋工程",
        "institution_id": "船舶与海洋工程"
    },
    {
        "subject_rank_id": "船舶与海洋结构物设计制造",
        "institution_id": "船舶与海洋工程"
    },
    {
        "subject_rank_id": "轮机工程",
        "institution_id": "船舶与海洋工程"
    },
    {
        "subject_rank_id": "水声工程",
        "institution_id": "船舶与海洋工程"
    },
    {
        "subject_rank_id": "航空宇航科学与技术",
        "institution_id": "航空宇航科学与技术"
    },
    {
        "subject_rank_id": "飞行器设计",
        "institution_id": "航空宇航科学与技术"
    },
    {
        "subject_rank_id": "航空宇航推进理论与工程",
        "institution_id": "航空宇航科学与技术"
    },
    {
        "subject_rank_id": "航空宇航制造工程",
        "institution_id": "航空宇航科学与技术"
    },
    {
        "subject_rank_id": "人机与环境工程",
        "institution_id": "航空宇航科学与技术"
    },
    {
        "subject_rank_id": "兵器科学与技术",
        "institution_id": "兵器科学与技术"
    },
    {
        "subject_rank_id": "武器系统与运用工程",
        "institution_id": "兵器科学与技术"
    },
    {
        "subject_rank_id": "兵器发射理论与技术",
        "institution_id": "兵器科学与技术"
    },
    {
        "subject_rank_id": "火炮、自动武器与弹药工程",
        "institution_id": "兵器科学与技术"
    },
    {
        "subject_rank_id": "军事化学与烟火技术",
        "institution_id": "兵器科学与技术"
    },
    {
        "subject_rank_id": "核科学与技术",
        "institution_id": "核科学与技术"
    },
    {
        "subject_rank_id": "核能科学与工程",
        "institution_id": "核科学与技术"
    },
    {
        "subject_rank_id": "核燃料循环与材料",
        "institution_id": "核科学与技术"
    },
    {
        "subject_rank_id": "核技术及应用",
        "institution_id": "核科学与技术"
    },
    {
        "subject_rank_id": "辐射防护及环境保护",
        "institution_id": "核科学与技术"
    },
    {
        "subject_rank_id": "农业工程",
        "institution_id": "农业工程"
    },
    {
        "subject_rank_id": "农业机械化工程",
        "institution_id": "农业工程"
    },
    {
        "subject_rank_id": "农业水土工程",
        "institution_id": "农业工程"
    },
    {
        "subject_rank_id": "农业生物环境与能源工程",
        "institution_id": "农业工程"
    },
    {
        "subject_rank_id": "农业电气化与自动化",
        "institution_id": "农业工程"
    },
    {
        "subject_rank_id": "林业工程",
        "institution_id": "林业工程"
    },
    {
        "subject_rank_id": "森林工程",
        "institution_id": "林业工程"
    },
    {
        "subject_rank_id": "木材科学与技术",
        "institution_id": "林业工程"
    },
    {
        "subject_rank_id": "林产化学加工工程",
        "institution_id": "林业工程"
    },
    {
        "subject_rank_id": "环境科学与工程",
        "institution_id": "环境科学与工程"
    },
    {
        "subject_rank_id": "环境科学",
        "institution_id": "环境科学与工程"
    },
    {
        "subject_rank_id": "环境工程",
        "institution_id": "环境科学与工程"
    },
    {
        "subject_rank_id": "生物医学工程",
        "institution_id": "生物医学工程"
    },
    {
        "subject_rank_id": "食品科学与工程",
        "institution_id": "食品科学与工程"
    },
    {
        "subject_rank_id": "食品科学",
        "institution_id": "食品科学与工程"
    },
    {
        "subject_rank_id": "粮食、油脂及植物蛋白工程",
        "institution_id": "食品科学与工程"
    },
    {
        "subject_rank_id": "农产品加工及贮藏工程",
        "institution_id": "食品科学与工程"
    },
    {
        "subject_rank_id": "水产品加工及贮藏工程",
        "institution_id": "食品科学与工程"
    },
    {
        "subject_rank_id": "城乡规划学",
        "institution_id": "城乡规划学"
    },
    {
        "subject_rank_id": "风景园林学",
        "institution_id": "风景园林学"
    },
    {
        "subject_rank_id": "软件工程",
        "institution_id": "软件工程"
    },
    {
        "subject_rank_id": "生物工程",
        "institution_id": "生物工程"
    },
    {
        "subject_rank_id": "安全科学与工程",
        "institution_id": "安全科学与工程"
    },
    {
        "subject_rank_id": "公安技术",
        "institution_id": "公安技术"
    },
    {
        "subject_rank_id": "网络空间安全",
        "institution_id": "网络空间安全"
    },
    {
        "subject_rank_id": "科学技术史",
        "institution_id": "科学技术史"
    },
    {
        "subject_rank_id": "管理科学与工程",
        "institution_id": "管理科学与工程"
    },
    {
        "subject_rank_id": "设计学",
        "institution_id": "设计学"
    },
    {
        "subject_rank_id": "作物学",
        "institution_id": "作物学"
    },
    {
        "subject_rank_id": "作物栽培学与耕作学",
        "institution_id": "作物学"
    },
    {
        "subject_rank_id": "作物遗传育种",
        "institution_id": "作物学"
    },
    {
        "subject_rank_id": "园艺学",
        "institution_id": "园艺学"
    },
    {
        "subject_rank_id": "果树学",
        "institution_id": "园艺学"
    },
    {
        "subject_rank_id": "蔬菜学",
        "institution_id": "园艺学"
    },
    {
        "subject_rank_id": "茶学",
        "institution_id": "园艺学"
    },
    {
        "subject_rank_id": "农业资源与环境",
        "institution_id": "农业资源与环境"
    },
    {
        "subject_rank_id": "土壤学",
        "institution_id": "农业资源与环境"
    },
    {
        "subject_rank_id": "植物营养学",
        "institution_id": "农业资源与环境"
    },
    {
        "subject_rank_id": "植物保护",
        "institution_id": "植物保护"
    },
    {
        "subject_rank_id": "植物病理学",
        "institution_id": "植物保护"
    },
    {
        "subject_rank_id": "农业昆虫与害虫防治",
        "institution_id": "植物保护"
    },
    {
        "subject_rank_id": "农药学",
        "institution_id": "植物保护"
    },
    {
        "subject_rank_id": "畜牧学",
        "institution_id": "畜牧学"
    },
    {
        "subject_rank_id": "动物遗传育种与繁殖",
        "institution_id": "畜牧学"
    },
    {
        "subject_rank_id": "动物营养与饲料科学",
        "institution_id": "畜牧学"
    },
    {
        "subject_rank_id": "特种经济动物饲养",
        "institution_id": "畜牧学"
    },
    {
        "subject_rank_id": "兽医学",
        "institution_id": "兽医学"
    },
    {
        "subject_rank_id": "基础兽医学",
        "institution_id": "兽医学"
    },
    {
        "subject_rank_id": "预防兽医学",
        "institution_id": "兽医学"
    },
    {
        "subject_rank_id": "临床兽医学",
        "institution_id": "兽医学"
    },
    {
        "subject_rank_id": "林学",
        "institution_id": "林学"
    },
    {
        "subject_rank_id": "林木遗传育种",
        "institution_id": "林学"
    },
    {
        "subject_rank_id": "森林培育",
        "institution_id": "林学"
    },
    {
        "subject_rank_id": "森林保护学",
        "institution_id": "林学"
    },
    {
        "subject_rank_id": "森林经理学",
        "institution_id": "林学"
    },
    {
        "subject_rank_id": "野生动植物保护与利用",
        "institution_id": "林学"
    },
    {
        "subject_rank_id": "园林植物与观赏园艺",
        "institution_id": "林学"
    },
    {
        "subject_rank_id": "水土保持与荒漠化防治",
        "institution_id": "林学"
    },
    {
        "subject_rank_id": "水产",
        "institution_id": "水产"
    },
    {
        "subject_rank_id": "水产养殖",
        "institution_id": "水产"
    },
    {
        "subject_rank_id": "捕捞学",
        "institution_id": "水产"
    },
    {
        "subject_rank_id": "渔业资源",
        "institution_id": "水产"
    },
    {
        "subject_rank_id": "草学",
        "institution_id": "草学"
    },
    {
        "subject_rank_id": "科学技术史",
        "institution_id": "科学技术史"
    },
    {
        "subject_rank_id": "环境科学与工程",
        "institution_id": "环境科学与工程"
    },
    {
        "subject_rank_id": "环境科学",
        "institution_id": "环境科学与工程"
    },
    {
        "subject_rank_id": "环境工程",
        "institution_id": "环境科学与工程"
    },
    {
        "subject_rank_id": "食品科学与工程",
        "institution_id": "食品科学与工程"
    },
    {
        "subject_rank_id": "食品科学",
        "institution_id": "食品科学与工程"
    },
    {
        "subject_rank_id": "粮食、油脂及植物蛋白工程",
        "institution_id": "食品科学与工程"
    },
    {
        "subject_rank_id": "农产品加工及贮藏工程",
        "institution_id": "食品科学与工程"
    },
    {
        "subject_rank_id": "水产品加工及贮藏工程",
        "institution_id": "食品科学与工程"
    },
    {
        "subject_rank_id": "风景园林学",
        "institution_id": "风景园林学"
    },
    {
        "subject_rank_id": "基础医学",
        "institution_id": "基础医学"
    },
    {
        "subject_rank_id": "人体解剖与组织胚胎学",
        "institution_id": "基础医学"
    },
    {
        "subject_rank_id": "免疫学",
        "institution_id": "基础医学"
    },
    {
        "subject_rank_id": "病原生物学",
        "institution_id": "基础医学"
    },
    {
        "subject_rank_id": "病理学与病理生理学",
        "institution_id": "基础医学"
    },
    {
        "subject_rank_id": "法医学",
        "institution_id": "基础医学"
    },
    {
        "subject_rank_id": "放射医学",
        "institution_id": "基础医学"
    },
    {
        "subject_rank_id": "临床医学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "内科学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "儿科学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "老年医学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "神经病学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "精神病与精神卫生学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "皮肤病与性病学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "影像医学与核医学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "临床检验诊断学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "外科学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "妇产科学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "眼科学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "耳鼻咽喉科学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "肿瘤学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "康复医学与理疗学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "运动医学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "麻醉学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "急诊医学",
        "institution_id": "临床医学"
    },
    {
        "subject_rank_id": "口腔医学",
        "institution_id": "口腔医学"
    },
    {
        "subject_rank_id": "口腔基础医学",
        "institution_id": "口腔医学"
    },
    {
        "subject_rank_id": "口腔临床医学",
        "institution_id": "口腔医学"
    },
    {
        "subject_rank_id": "公共卫生与预防医学",
        "institution_id": "公共卫生与预防医学"
    },
    {
        "subject_rank_id": "流行病与卫生统计学",
        "institution_id": "公共卫生与预防医学"
    },
    {
        "subject_rank_id": "劳动卫生与环境卫生学",
        "institution_id": "公共卫生与预防医学"
    },
    {
        "subject_rank_id": "营养与食品卫生学",
        "institution_id": "公共卫生与预防医学"
    },
    {
        "subject_rank_id": "儿少卫生与妇幼保健学",
        "institution_id": "公共卫生与预防医学"
    },
    {
        "subject_rank_id": "卫生毒理学",
        "institution_id": "公共卫生与预防医学"
    },
    {
        "subject_rank_id": "军事预防医学",
        "institution_id": "公共卫生与预防医学"
    },
    {
        "subject_rank_id": "中医学",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中医基础理论",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中医临床基础",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中医医史文献",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "方剂学",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中医诊断学",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中医内科学",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中医外科学",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中医骨伤科学",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中医妇科学",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中医儿科学",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中医五官科学",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "针灸推拿学",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "民族医学（含：藏医学、蒙医学等）",
        "institution_id": "中医学"
    },
    {
        "subject_rank_id": "中西医结合",
        "institution_id": "中西医结合"
    },
    {
        "subject_rank_id": "中西医结合基础",
        "institution_id": "中西医结合"
    },
    {
        "subject_rank_id": "中西医结合临床",
        "institution_id": "中西医结合"
    },
    {
        "subject_rank_id": "药学",
        "institution_id": "药学"
    },
    {
        "subject_rank_id": "药物化学",
        "institution_id": "药学"
    },
    {
        "subject_rank_id": "药剂学",
        "institution_id": "药学"
    },
    {
        "subject_rank_id": "生药学",
        "institution_id": "药学"
    },
    {
        "subject_rank_id": "药物分析学",
        "institution_id": "药学"
    },
    {
        "subject_rank_id": "微生物与生化药学",
        "institution_id": "药学"
    },
    {
        "subject_rank_id": "药理学",
        "institution_id": "药学"
    },
    {
        "subject_rank_id": "中药学",
        "institution_id": "中药学"
    },
    {
        "subject_rank_id": "特种医学",
        "institution_id": "特种医学"
    },
    {
        "subject_rank_id": "医学技术",
        "institution_id": "医学技术"
    },
    {
        "subject_rank_id": "护理学",
        "institution_id": "护理学"
    },
    {
        "subject_rank_id": "科学技术史",
        "institution_id": "科学技术史"
    },
    {
        "subject_rank_id": "生物医学工程",
        "institution_id": "生物医学工程"
    },
    {
        "subject_rank_id": "军事思想及军事历史",
        "institution_id": "军事思想及军事历史"
    },
    {
        "subject_rank_id": "军事思想",
        "institution_id": "军事思想及军事历史"
    },
    {
        "subject_rank_id": "军事历史",
        "institution_id": "军事思想及军事历史"
    },
    {
        "subject_rank_id": "战略学",
        "institution_id": "战略学"
    },
    {
        "subject_rank_id": "国家安全战略学",
        "institution_id": "战略学"
    },
    {
        "subject_rank_id": "军事战略学",
        "institution_id": "战略学"
    },
    {
        "subject_rank_id": "军种战略学",
        "institution_id": "战略学"
    },
    {
        "subject_rank_id": "国防动员学",
        "institution_id": "战略学"
    },
    {
        "subject_rank_id": "战役学",
        "institution_id": "战役学"
    },
    {
        "subject_rank_id": "联合战役学",
        "institution_id": "战役学"
    },
    {
        "subject_rank_id": "军种战役学",
        "institution_id": "战役学"
    },
    {
        "subject_rank_id": "战术学",
        "institution_id": "战术学"
    },
    {
        "subject_rank_id": "联合战术学",
        "institution_id": "战术学"
    },
    {
        "subject_rank_id": "合同战术学",
        "institution_id": "战术学"
    },
    {
        "subject_rank_id": "兵种战术学",
        "institution_id": "战术学"
    },
    {
        "subject_rank_id": "军队指挥学",
        "institution_id": "军队指挥学"
    },
    {
        "subject_rank_id": "作战指挥学",
        "institution_id": "军队指挥学"
    },
    {
        "subject_rank_id": "作战环境学",
        "institution_id": "军队指挥学"
    },
    {
        "subject_rank_id": "军事运筹学",
        "institution_id": "军队指挥学"
    },
    {
        "subject_rank_id": "军事信息学",
        "institution_id": "军队指挥学"
    },
    {
        "subject_rank_id": "军事情报学",
        "institution_id": "军队指挥学"
    },
    {
        "subject_rank_id": "军事密码学",
        "institution_id": "军队指挥学"
    },
    {
        "subject_rank_id": "非战争军事行动",
        "institution_id": "军队指挥学"
    },
    {
        "subject_rank_id": "军事管理学",
        "institution_id": "军事管理学"
    },
    {
        "subject_rank_id": "军事组织编制学",
        "institution_id": "军事管理学"
    },
    {
        "subject_rank_id": "军队管理学",
        "institution_id": "军事管理学"
    },
    {
        "subject_rank_id": "军事法制学",
        "institution_id": "军事管理学"
    },
    {
        "subject_rank_id": "军队政治工作学",
        "institution_id": "军队政治工作学"
    },
    {
        "subject_rank_id": "军队政治工作学原理",
        "institution_id": "军队政治工作学"
    },
    {
        "subject_rank_id": "部队政治工作学",
        "institution_id": "军队政治工作学"
    },
    {
        "subject_rank_id": "政治机关工作学",
        "institution_id": "军队政治工作学"
    },
    {
        "subject_rank_id": "军事任务政治工作",
        "institution_id": "军队政治工作学"
    },
    {
        "subject_rank_id": "军事后勤学",
        "institution_id": "军事后勤学"
    },
    {
        "subject_rank_id": "军事后勤建设学",
        "institution_id": "军事后勤学"
    },
    {
        "subject_rank_id": "后方专业勤务",
        "institution_id": "军事后勤学"
    },
    {
        "subject_rank_id": "军事物流学",
        "institution_id": "军事后勤学"
    },
    {
        "subject_rank_id": "军事装备学",
        "institution_id": "军事装备学"
    },
    {
        "subject_rank_id": "军事装备论证学",
        "institution_id": "军事装备学"
    },
    {
        "subject_rank_id": "军事装备实验学",
        "institution_id": "军事装备学"
    },
    {
        "subject_rank_id": "军事装备采购学",
        "institution_id": "军事装备学"
    },
    {
        "subject_rank_id": "军事装备保障学",
        "institution_id": "军事装备学"
    },
    {
        "subject_rank_id": "军事装备管理学",
        "institution_id": "军事装备学"
    },
    {
        "subject_rank_id": "军事训练学",
        "institution_id": "军事训练学"
    },
    {
        "subject_rank_id": "联合训练学",
        "institution_id": "军事训练学"
    },
    {
        "subject_rank_id": "军兵种训练学",
        "institution_id": "军事训练学"
    },
    {
        "subject_rank_id": "军事教育学",
        "institution_id": "军事训练学"
    },
    {
        "subject_rank_id": "管理科学与工程",
        "institution_id": "管理科学与工程"
    },
    {
        "subject_rank_id": "工商管理",
        "institution_id": "工商管理"
    },
    {
        "subject_rank_id": "会计学",
        "institution_id": "工商管理"
    },
    {
        "subject_rank_id": "企业管理",
        "institution_id": "工商管理"
    },
    {
        "subject_rank_id": "旅游管理",
        "institution_id": "工商管理"
    },
    {
        "subject_rank_id": "技术经济及管理",
        "institution_id": "工商管理"
    },
    {
        "subject_rank_id": "农林经济管理",
        "institution_id": "农林经济管理"
    },
    {
        "subject_rank_id": "农业经济管理",
        "institution_id": "农林经济管理"
    },
    {
        "subject_rank_id": "林业经济管理",
        "institution_id": "农林经济管理"
    },
    {
        "subject_rank_id": "公共管理",
        "institution_id": "公共管理"
    },
    {
        "subject_rank_id": "行政管理",
        "institution_id": "公共管理"
    },
    {
        "subject_rank_id": "社会医学与卫生事业管理",
        "institution_id": "公共管理"
    },
    {
        "subject_rank_id": "教育经济与管理",
        "institution_id": "公共管理"
    },
    {
        "subject_rank_id": "社会保障",
        "institution_id": "公共管理"
    },
    {
        "subject_rank_id": "土地资源管理",
        "institution_id": "公共管理"
    },
    {
        "subject_rank_id": "图书情报与档案管理",
        "institution_id": "图书情报与档案管理"
    },
    {
        "subject_rank_id": "图书馆学",
        "institution_id": "图书情报与档案管理"
    },
    {
        "subject_rank_id": "情报学",
        "institution_id": "图书情报与档案管理"
    },
    {
        "subject_rank_id": "档案学",
        "institution_id": "图书情报与档案管理"
    },
    {
        "subject_rank_id": "艺术学理论",
        "institution_id": "艺术学理论"
    },
    {
        "subject_rank_id": "音乐与舞蹈学",
        "institution_id": "音乐与舞蹈学"
    },
    {
        "subject_rank_id": "音乐学",
        "institution_id": "音乐与舞蹈学"
    },
    {
        "subject_rank_id": "戏剧与影视学",
        "institution_id": "戏剧与影视学"
    },
    {
        "subject_rank_id": "戏剧戏曲学",
        "institution_id": "戏剧与影视学"
    },
    {
        "subject_rank_id": "广播电视艺术学",
        "institution_id": "戏剧与影视学"
    },
    {
        "subject_rank_id": "美术学",
        "institution_id": "美术学"
    },
    {
        "subject_rank_id": "设计学",
        "institution_id": "设计学"
    },
    {
        "subject_rank_id": "设计艺术学",
        "institution_id": "设计学"
    },
    {
        "subject_rank_id": "集成电路科学与工程",
        "institution_id": "集成电路科学与工程"
    },
    {
        "subject_rank_id": "国家安全学",
        "institution_id": "国家安全学"
    }];

let handelFirst2Second = (first2seconds)=>{
    let first2secondDir = {};
    for(let i = 0;i<first2seconds.length;i++){
        let item = first2seconds[i];
        // first2secondDir.set(item.subject_rank_id,item.institution_id)
        first2secondDir[item.subject_rank_id] = item.institution_id;
        // let obj = {}
        // obj[item.subject_rank_id] = item.institution_id
        // first2secondDir.push(obj)
    }
    return first2secondDir;

}

let Dir1 = handelFirst2Second(first2secondsList1);
let Dir2 = handelFirst2Second(first2secondsList2);
// console.log(first2secondDir)

// 数据库中的对应表
let majorDir1 = {
    '安全工程': '安全科学与工程',
    '火炮、自动武器与弹药工程': '兵器科学与技术',
    '智能武器技术与工程': '兵器科学与技术',
    '兵器工程': '兵器科学与技术',
    '兵器科学与技术': '兵器科学与技术',
    '磁光电材料物性与器件': '材料科学与工程',
    '材料学': '材料科学与工程',
    '材料物理与化学': '材料科学与工程',
    '材料加工工程': '材料科学与工程',
    '材料工程': '材料科学与工程',
    '材料科学与工程（核技术与材料工程）': '材料科学与工程',
    '材料与化工': '材料科学与工程',
    '材料科学与工程': '材料科学与工程',
    '地图学与地理信息系统': '地理学',
    '地图制图学与地理信息工程': '测绘科学与技术',
    '测绘工程': '测绘科学与技术',
    '大地测量学与测量工程': '测绘科学与技术',
    '城乡规划学': '城乡规划学',
    '城市规划': '城乡规划学',
    '畜牧': '畜牧学',
    '畜牧学': '畜牧学',
    '船舶与海洋工程': '船舶与海洋工程',
    '人文地理学': '地理学',
    '地质资源与地质工程': '地质资源与地质工程',
    '地质工程': '地质资源与地质工程',
    '电工理论与新技术': '电气工程',
    '电力电子与电力传动': '电气工程',
    '高电压与绝缘技术': '电气工程',
    '电力系统及其自动化': '电气工程',
    '智能电网与控制': '电气工程',
    '电气工程': '电气工程',
    '通信工程（含宽带网络、移动通信等）': '电子科学与技术',
    '物理电子学': '电子科学与技术',
    '微电子学与固体电子学': '电子科学与技术',
    '电路与系统': '电子科学与技术',
    '集成电路工程': '电子科学与技术',
    '化工过程机械': '动力工程及工程热物理',
    '流体机械及工程': '动力工程及工程热物理',
    '新能源科学与工程': '动力工程及工程热物理',
    '热能工程': '动力工程及工程热物理',
    '工程热物理': '动力工程及工程热物理',
    '动力工程': '动力工程及工程热物理',
    '能源动力': '动力工程及工程热物理',
    '动力工程与工程热物理': '动力工程与工程热物理',
    '刑法学': '法学',
    '民商法学': '法学',
    '经济法学': '法学',
    '环境与资源保护法学': '法学',
    '法学理论': '法学',
    '财税法学': '法学',
    '诉讼法学': '法学',
    '宪法学与行政法学': '法学',
    '法律': '法学',
    '法律（非法学）': '法学',
    '法律（法学）': '法学',
    '法学': '法学',
    '服装产业经济与管理': '纺织科学与工程',
    '服装设计与工程': '纺织科学与工程',
    '纺织化学与染整工程': '纺织科学与工程',
    '纺织工程': '纺织科学与工程',
    '风景园林': '风景园林学',
    '风景园林学': '风景园林学',
    '大数据与财务管理': '工商管理',
    '大数据与会计': '工商管理',
    '电子商务': '工商管理',
    '项目管理（领域）': '工商管理',
    '技术经济及管理': '工商管理',
    '知识产权与科技创新管理': '工商管理',
    '商务统计学': '工商管理',
    '高级工商管理': '工商管理',
    '会计学': '工商管理',
    '企业管理': '工商管理',
    '工商管理': '工商管理',
    '会计': '工商管理学',
    '公共管理（MPA)': '公共管理',
    '教育经济与管理': '公共管理',
    '行政管理': '公共管理',
    '公共管理': '公共管理',
    '公共管理学': '公共管理学',
    '流行病与卫生统计学': '公共卫生与预防医学',
    '公共卫生与预防医学': '公共卫生与预防医学',
    '公共卫生': '公共卫生与预防医学',
    '健康管理': '管理科学与工程',
    '工程造价': '管理科学与工程',
    '现代物流管理': '管理科学与工程',
    '物流管理': '管理科学与工程',
    '工程管理MEM': '管理科学与工程',
    '项目管理': '管理科学与工程',
    '知识产权': '管理科学与工程',
    '物流工程与管理': '管理科学与工程',
    '工业工程与管理': '管理科学与工程',
    '管理科学与工程': '管理科学与工程',
    '工程管理': '管理科学与工程',
    '光电功能与信息材料': '光学工程',
    '光电信息工程': '光学工程',
    '光学工程': '光学工程',
    '航天仿生科学与技术': '航空宇航科学与技术',
    '航空宇航推进理论与工程': '航空宇航科学与技术',
    '航空宇航制造工程': '航空宇航科学与技术',
    '人机与环境工程': '航空宇航科学与技术',
    '航空宇航科学与技术': '航空宇航科学与技术',
    '核科学与技术': '核科学与技术',
    '护理': '护理学',
    '高分子化学与物理': '化学',
    '物理化学': '化学',
    '有机化学': '化学',
    '化学工程（领域）': '化学工程与技术',
    '化学工艺': '化学工程与技术',
    '工业催化': '化学工程与技术',
    '生物化工': '化学工程与技术',
    '应用化学': '化学工程与技术',
    '化学工程': '化学工程与技术',
    '化学工程与技术': '化学工程与技术',
    '环境科学': '环境科学与工程',
    '海岸带资源与环境': '环境科学与工程',
    '资源与环境': '环境科学与工程',
    '环境工程': '环境科学与工程',
    '环境科学与工程': '环境科学与工程',
    '机械设计制造及其自动化': '机械工程',
    '机器人工程': '机械工程',
    '汽车服务工程': '机械工程',
    '工业机器人技术': '机械工程',
    '机械设计制造及自动化': '机械工程',
    '工业设计工程（领域）': '机械工程',
    '先进制造': '机械工程',
    '能源装备与过程控制': '机械工程',
    '车辆工程': '机械工程',
    '机械电子工程': '机械工程',
    '工业工程': '机械工程',
    '工业设计工程': '机械工程',
    '机械设计及理论': '机械工程',
    '机械工程（精密驱动与振动利用）': '机械工程',
    '机械制造及其自动化': '机械工程',
    '航空工程': '机械工程',
    '机械工程': '机械工程',
    '机械': '机械工程',
    '人体解剖与组织胚胎学': '基础医学',
    '法医学': '基础医学',
    '基础医学': '基础医学',
    '免疫学': '基础医学',
    '病理学与病理生理学': '基础医学',
    '大数据工程技术': '计算机科学与技术',
    '网络工程技术': '计算机科学与技术',
    '数字媒体技术': '计算机科学与技术',
    '计算机技术（领域）': '计算机科学与技术',
    '网络与信息安全': '计算机科学与技术',
    '计算机应用技术': '计算机科学与技术',
    '计算机技术': '计算机科学与技术',
    '计算机科学与技术': '计算机科学与技术',
    '环境设计及其理论': '建筑学',
    '建筑': '建筑学',
    '建筑学': '建筑学',
    '建筑与土木工程': '建筑学',
    '交通运输工程（领域）': '交通运输工程',
    '载运工具运用工程': '交通运输工程',
    '交通运输规划与管理': '交通运输工程',
    '轨道交通运输': '交通运输工程',
    '道路与铁道工程': '交通运输工程',
    '水路交通运输': '交通运输工程',
    '交通运输工程': '交通运输工程',
    '交通运输': '交通运输工程',
    '教育': '教育学',
    '学科教学（音乐）': '教育学',
    '学科教学（美术）': '教育学',
    '现代教育技术': '教育学',
    '教育技术学': '教育学',
    '学科教学（生物）': '教育学',
    '学科教学（英语）': '教育学',
    '职业技术教育学': '教育学',
    '学科教学（语文）': '教育学',
    '学科教学（物理）': '教育学',
    '学科教学（思政）': '教育学',
    '学科教学（数学）': '教育学',
    '学科教学（历史）': '教育学',
    '学科教学（化学）': '教育学',
    '学科教学（地理）': '教育学',
    '小学教育': '教育学',
    '汉语国际教育': '教育学',
    '职业技术教育': '教育学',
    '国际中文教育': '教育学',
    '教育管理': '教育学',
    '科学技术史': '科学技术史',
    '控制工程（领域）': '控制科学与工程',
    '农业工程与信息技术': '控制科学与工程',
    '模式识别与智能系统': '控制科学与工程',
    '检测技术与自动化装置': '控制科学与工程',
    '控制理论与控制工程': '控制科学与工程',
    '导航、制导与控制': '控制科学与工程',
    '电子与信息': '控制科学与工程',
    '控制工程': '控制科学与工程',
    '电子信息': '控制科学与工程',
    '控制科学与工程': '控制科学与工程',
    '口腔临床医学': '口腔医学',
    '口腔医学': '口腔医学',
    '采矿工程': '矿业工程',
    '世界经济': '理论经济学',
    '固体力学': '力学',
    '工程力学': '力学',
    '一般力学与力学基础': '力学',
    '流体力学': '力学',
    '力学': '力学',
    '老年医学': '临床医学',
    '临床病理学': '临床医学',
    '儿外科学': '临床医学',
    '重症医学': '临床医学',
    '精神病与精神卫生学': '临床医学',
    '核医学': '临床医学',
    '全科医学': '临床医学',
    '肿瘤学': '临床医学',
    '临床检验诊断学': '临床医学',
    '影像医学与核医学': '临床医学',
    '眼科学': '临床医学',
    '神经病学': '临床医学',
    '内科学': '临床医学',
    '临床病理': '临床医学',
    '外科学': '临床医学',
    '麻醉学': '临床医学',
    '临床医学': '临床医学',
    '康复医学与理疗学': '临床医学',
    '急诊医学': '临床医学',
    '骨科学': '临床医学',
    '妇产科学': '临床医学',
    '放射肿瘤学': '临床医学',
    '放射影像学': '临床医学',
    '耳鼻咽喉科学': '临床医学',
    '儿科学': '临床医学',
    '超声医学': '临床医学',
    '皮肤病与性病学': '临床医学',
    '农业管理': '旅游管理',
    '马克思主义与女性主义': '马克思主义理论',
    '马克思主义中国化研究': '马克思主义理论',
    '思想政治教育': '马克思主义理论',
    '马克思主义基本原理': '马克思主义理论',
    '美术': '美术学',
    '农村发展': '农林经济管理',
    '农业水土资源保护': '农业工程',
    '农业生物环境与能源工程': '农业工程',
    '农业水土工程': '农业工程',
    '农田水土工程': '农业工程',
    '资源利用与植物保护': '农业资源与环境',
    '土壤学': '农业资源与环境',
    '软件工程': '软件工程',
    '艺术设计（领域）': '设计学',
    '视觉艺术学': '设计学',
    '工业设计': '设计学',
    '设计艺术学': '设计学',
    '社会工作': '社会学',
    '植物生态学': '生态学',
    '生物工程': '生物工程',
    '植物学': '生物学',
    '遗传学': '生物学',
    '细胞生物学': '生物学',
    '生物化学与分子生物学': '生物学',
    '生理学': '生物学',
    '微生物学': '生物学',
    '生物技术与工程': '生物医学工程',
    '生物与医药': '生物医学工程',
    '制药工程': '生物医学工程',
    '油气井工程': '石油与天然气工程',
    '石油与天然气工程': '石油与天然气工程',
    '食品加工与安全': '食品科学与工程',
    '食品科学与工程': '食品科学与工程',
    '应用数学': '数学',
    '水利水电建设与管理': '水利工程',
    '水利工程': '水利工程',
    '港口海岸与近海工程': '水利工程',
    '港口、海岸与近海工程': '水利工程',
    '水利水电工程': '水利工程',
    '港口、海岸及近海工程': '水利工程',
    '水力学及河流动力学': '水利工程',
    '水工结构工程': '水利工程',
    '水文学及水资源': '水利工程',
    '运动训练': '体育学',
    '体育教育训练学': '体育学',
    '体育教学': '体育学',
    '体育': '体育学',
    '应用统计学': '统计学',
    '应用统计': '统计学',
    '图书情报': '图书情报与档案管理',
    '建筑与土木工程（领域）': '土木工程',
    '供热、供燃气、通风及空调工程': '土木工程',
    '岩土工程': '土木工程',
    '市政工程': '土木工程',
    '防灾减灾工程及防护工程': '土木工程',
    '结构工程': '土木工程',
    '桥梁与隧道工程': '土木工程',
    '土木水利': '土木工程',
    '土木工程': '土木工程',
    '应用英语': '外国语言文学',
    '商务英语': '外国语言文学',
    '翻译': '外国语言文学',
    '英语口译': '外国语言文学',
    '日语语言文学': '外国语言文学',
    '俄语语言文学': '外国语言文学',
    '外国语言学及应用语言学': '外国语言文学',
    '英语语言文学': '外国语言文学',
    '英语笔译': '外国语言文学',
    '网络空间安全': '网络空间安全',
    '光学': '物理学',
    '凝聚态物理': '物理学',
    '理论物理': '物理学',
    '艺术': '戏剧与影视学',
    '广播电视': '戏剧与影视学',
    '广播电视艺术学': '戏剧与影视学',
    '系统科学': '系统科学',
    '心理健康教育': '心理学',
    '网络与新媒体': '新闻传播学',
    '新闻与传播': '新闻传播学',
    '物联网技术与应用': '信息与通信工程',
    '信号与信息处理': '信息与通信工程',
    '电子与通信工程': '信息与通信工程',
    '通信工程': '信息与通信工程',
    '通信与信息系统': '信息与通信工程',
    '信息与通信工程': '信息与通信工程',
    '学前教育': '学前教育学',
    '药物分析学': '药学',
    '药物化学': '药学',
    '药剂学': '药学',
    '生药学': '药学',
    '药理学': '药学',
    '药学': '药学',
    '医学技术': '医学技术',
    '仪器科学与技术（生物医学信息与仪器）': '仪器科学与技术',
    '仪器仪表工程': '仪器科学与技术',
    '精密仪器及机械': '仪器科学与技术',
    '微系统与测控技术': '仪器科学与技术',
    '测试计量技术及仪器': '仪器科学与技术',
    '生物医学信息与仪器': '仪器科学与技术',
    '仪器科学与技术': '仪器科学与技术',
    '艺术设计': '艺术学',
    '环境艺术设计': '艺术学理论',
    '音乐': '音乐与舞蹈学',
    '舞蹈': '音乐与舞蹈学',
    '音乐学': '音乐与舞蹈学',
    '政府经济学': '应用经济学',
    '国民经济学': '应用经济学',
    '劳动经济学': '应用经济学',
    '区域经济学': '应用经济学',
    '国际商务': '应用经济学',
    '金融学': '应用经济学',
    '金融': '应用经济学',
    '科学技术哲学': '哲学',
    '中共党史': '政治学',
    '政治学理论': '政治学',
    '科学社会主义与国际共产主义运动': '政治学',
    '国际政治': '政治学',
    '历史文献学（含∶敦煌学、古文字学）': '中国史',
    '中国少数民族语言文学': '中国语言文学',
    '文艺学': '中国语言文学',
    '中西医结合基础': '中西医结合',
    '中西医结合临床': '中西医结合',
    '中药': '中药学',
    '中医临床及基础': '中医学',
    '中医诊断学': '中医学',
    '中医医史文献': '中医学',
    '中医临床基础': '中医学',
    '中医基础理论': '中医学',
    '方剂学': '中医学',
    '中医五官科学': '中医学',
    '中医外科学': '中医学',
    '中医内科学': '中医学',
    '中医骨伤科学': '中医学',
    '中医妇科学': '中医学',
    '中医儿科学': '中医学',
    '针灸推拿学': '中医学',

}
// 学硕对应表
let majorDir2 = {
    '哲学': '哲学',
    '马克思主义哲学': '哲学',
    '中国哲学': '哲学',
    '外国哲学': '哲学',
    '逻辑学': '哲学',
    '伦理学': '哲学',
    '美学': '哲学',
    '宗教学': '哲学',
    '理论经济学': '理论经济学',
    '政治经济学': '理论经济学',
    '经济思想史': '理论经济学',
    '经济史': '理论经济学',
    '西方经济学': '理论经济学',
    '人口、资源与环境经济学': '理论经济学',
    '应用经济学': '应用经济学',
    '财政学': '应用经济学',
    '产业经济学': '应用经济学',
    '国际贸易学': '应用经济学',
    '统计学': '统计学',
    '数量经济学': '应用经济学',
    '国防经济': '应用经济学',
    '法律史': '法学',
    '国际法学': '法学',
    '军事法学': '法学',
    '政治学': '政治学',
    '中外政治制度': '政治学',
    '国际关系': '政治学',
    '外交学': '政治学',
    '社会学': '社会学',
    '人口学': '社会学',
    '人类学': '社会学',
    '民俗学': '社会学',
    '民族学': '民族学',
    '马克思主义民族理论与政策': '民族学',
    '中国少数民族经济': '民族学',
    '中国少数民族史': '民族学',
    '中国少数民族艺术': '民族学',
    '马克思主义理论': '马克思主义理论',
    '马克思主义发展史': '马克思主义理论',
    '国外马克思主义研究': '马克思主义理论',
    '中国近现代史基本问题研究': '马克思主义理论',
    '公安学': '公安学',
    '教育学': '教育学',
    '教育学原理': '教育学',
    '课程与教学论': '教育学',
    '教育史': '教育学',
    '比较教育学': '教育学',
    '学前教育学': '教育学',
    '高等教育学': '教育学',
    '成人教育学': '教育学',
    '特殊教育学': '教育学',
    '教育法学': '教育学',
    '心理学': '心理学',
    '基础心理学': '心理学',
    '发展与教育心理学': '心理学',
    '应用心理学': '心理学',
    '体育学': '体育学',
    '体育人文社会学': '体育学',
    '运动人体科学': '体育学',
    '民族传统体育学': '体育学',
    '中国语言文学': '中国语言文学',
    '语言学及应用语言学': '中国语言文学',
    '汉语言文字学': '中国语言文学',
    '中国古典文献学': '中国语言文学',
    '中国古代文学': '中国语言文学',
    '中国现当代文学': '中国语言文学',
    '比较文学与世界文学': '中国语言文学',
    '外国语言文学': '外国语言文学',
    '法语语言文学': '外国语言文学',
    '德语语言文学': '外国语言文学',
    '印度语言文学': '外国语言文学',
    '西班牙语语言文学': '外国语言文学',
    '阿拉伯语语言文学': '外国语言文学',
    '欧洲语言文学': '外国语言文学',
    '亚非语言文学': '外国语言文学',
    '新闻传播学': '新闻传播学',
    '新闻学': '新闻传播学',
    '传播学': '新闻传播学',
    '考古学': '考古学',
    '中国史': '中国史',
    '中国古代史': '中国史',
    '中国近现代史': '中国史',
    '世界史': '世界史',
    '数学': '数学',
    '基础数学': '数学',
    '计算数学': '数学',
    '概率论与数理统计': '数学',
    '运筹学与控制论': '数学',
    '物理学': '物理学',
    '粒子物理与原子核物理': '物理学',
    '原子与分子物理': '物理学',
    '等离子体物理': '物理学',
    '声学': '物理学',
    '无线电物理': '物理学',
    '化学': '化学',
    '无机化学': '化学',
    '分析化学': '化学',
    '天文学': '天文学',
    '天体物理': '天文学',
    '天体测量与天体力学': '天文学',
    '地理学': '地理学',
    '自然地理学': '地理学',
    '大气科学': '大气科学',
    '气象学': '大气科学',
    '大气物理学与大气环境': '大气科学',
    '海洋科学': '海洋科学',
    '物理海洋学': '海洋科学',
    '海洋化学': '海洋科学',
    '海洋生物学': '海洋科学',
    '海洋地质': '海洋科学',
    '地球物理学': '地球物理学',
    '固体地球物理学': '地球物理学',
    '空间物理学': '地球物理学',
    '地质学': '地质学',
    '矿物学、岩石学、矿床学': '地质学',
    '地球化学': '地质学',
    '古生物学与地层学': '地质学',
    '构造地质学': '地质学',
    '第四纪地质学': '地质学',
    '生物学': '生物学',
    '动物学': '生物学',
    '水生生物学': '生物学',
    '神经生物学': '生物学',
    '发育生物学': '生物学',
    '生物物理学': '生物学',
    '系统理论': '系统科学',
    '系统分析与集成': '系统科学',
    '生态学': '生态学',
    '电子科学与技术': '电子科学与技术',
    '电磁场与微波技术': '电子科学与技术',
    '计算机系统结构': '计算机科学与技术',
    '计算机软件与理论': '计算机科学与技术',
    '生物医学工程': '生物医学工程',
    '病原生物学': '基础医学',
    '放射医学': '基础医学',
    '劳动卫生与环境卫生学': '公共卫生与预防医学',
    '营养与食品卫生学': '公共卫生与预防医学',
    '儿少卫生与妇幼保健学': '公共卫生与预防医学',
    '卫生毒理学': '公共卫生与预防医学',
    '军事预防医学': '公共卫生与预防医学',
    '微生物与生化药学': '药学',
    '中药学': '中药学',
    '护理学': '护理学',
    '冶金工程': '冶金工程',
    '冶金物理化学': '冶金工程',
    '钢铁冶金': '冶金工程',
    '有色金属冶金': '冶金工程',
    '动力工程及工程热物理': '动力工程及工程热物理',
    '动力机械及工程': '动力工程及工程热物理',
    '制冷及低温工程': '动力工程及工程热物理',
    '电机与电器': '电气工程',
    '系统工程': '控制科学与工程',
    '建筑历史与理论': '建筑学',
    '建筑设计及其理论': '建筑学',
    '建筑技术科学': '建筑学',
    '测绘科学与技术': '测绘科学与技术',
    '摄影测量与遥感': '测绘科学与技术',
    '矿产普查与勘探': '地质资源与地质工程',
    '地球探测与信息技术': '地质资源与地质工程',
    '矿业工程': '矿业工程',
    '矿物加工工程': '矿业工程',
    '安全技术及工程': '矿业工程',
    '油气田开发工程': '石油与天然气工程',
    '油气储运工程': '石油与天然气工程',
    '纺织科学与工程': '纺织科学与工程',
    '纺织材料与纺织品设计': '纺织科学与工程',
    '轻工技术与工程': '轻工技术与工程',
    '制浆造纸工程': '轻工技术与工程',
    '制糖工程': '轻工技术与工程',
    '发酵工程': '轻工技术与工程',
    '皮革化学与工程': '轻工技术与工程',
    '交通信息工程及控制': '交通运输工程',
    '船舶与海洋结构物设计制造': '船舶与海洋工程',
    '轮机工程': '船舶与海洋工程',
    '水声工程': '船舶与海洋工程',
    '飞行器设计': '航空宇航科学与技术',
    '武器系统与运用工程': '兵器科学与技术',
    '兵器发射理论与技术': '兵器科学与技术',
    '军事化学与烟火技术': '兵器科学与技术',
    '核能科学与工程': '核科学与技术',
    '核燃料循环与材料': '核科学与技术',
    '核技术及应用': '核科学与技术',
    '辐射防护及环境保护': '核科学与技术',
    '农业工程': '农业工程',
    '农业机械化工程': '农业工程',
    '农业电气化与自动化': '农业工程',
    '林业工程': '林业工程',
    '森林工程': '林业工程',
    '木材科学与技术': '林业工程',
    '林产化学加工工程': '林业工程',
    '食品科学': '食品科学与工程',
    '粮食、油脂及植物蛋白工程': '食品科学与工程',
    '农产品加工及贮藏工程': '食品科学与工程',
    '水产品加工及贮藏工程': '食品科学与工程',
    '安全科学与工程': '安全科学与工程',
    '公安技术': '公安技术',
    '设计学': '设计学',
    '作物学': '作物学',
    '作物栽培学与耕作学': '作物学',
    '作物遗传育种': '作物学',
    '园艺学': '园艺学',
    '果树学': '园艺学',
    '蔬菜学': '园艺学',
    '茶学': '园艺学',
    '农业资源与环境': '农业资源与环境',
    '植物营养学': '农业资源与环境',
    '植物保护': '植物保护',
    '植物病理学': '植物保护',
    '农业昆虫与害虫防治': '植物保护',
    '农药学': '植物保护',
    '动物遗传育种与繁殖': '畜牧学',
    '动物营养与饲料科学': '畜牧学',
    '特种经济动物饲养': '畜牧学',
    '兽医学': '兽医学',
    '基础兽医学': '兽医学',
    '预防兽医学': '兽医学',
    '临床兽医学': '兽医学',
    '林学': '林学',
    '林木遗传育种': '林学',
    '森林培育': '林学',
    '森林保护学': '林学',
    '森林经理学': '林学',
    '野生动植物保护与利用': '林学',
    '园林植物与观赏园艺': '林学',
    '水土保持与荒漠化防治': '林学',
    '水产': '水产',
    '水产养殖': '水产',
    '捕捞学': '水产',
    '渔业资源': '水产',
    '草学': '草学',
    '运动医学': '临床医学',
    '口腔基础医学': '口腔医学',
    '中医学': '中医学',
    '民族医学（含：藏医学、蒙医学等）': '中医学',
    '中西医结合': '中西医结合',
    '特种医学': '特种医学',
    '军事思想及军事历史': '军事思想及军事历史',
    '军事思想': '军事思想及军事历史',
    '军事历史': '军事思想及军事历史',
    '战略学': '战略学',
    '国家安全战略学': '战略学',
    '军事战略学': '战略学',
    '军种战略学': '战略学',
    '国防动员学': '战略学',
    '战役学': '战役学',
    '联合战役学': '战役学',
    '军种战役学': '战役学',
    '战术学': '战术学',
    '联合战术学': '战术学',
    '合同战术学': '战术学',
    '兵种战术学': '战术学',
    '军队指挥学': '军队指挥学',
    '作战指挥学': '军队指挥学',
    '作战环境学': '军队指挥学',
    '军事运筹学': '军队指挥学',
    '军事信息学': '军队指挥学',
    '军事情报学': '军队指挥学',
    '军事密码学': '军队指挥学',
    '非战争军事行动': '军队指挥学',
    '军事管理学': '军事管理学',
    '军事组织编制学': '军事管理学',
    '军队管理学': '军事管理学',
    '军事法制学': '军事管理学',
    '军队政治工作学': '军队政治工作学',
    '军队政治工作学原理': '军队政治工作学',
    '部队政治工作学': '军队政治工作学',
    '政治机关工作学': '军队政治工作学',
    '军事任务政治工作': '军队政治工作学',
    '军事后勤学': '军事后勤学',
    '军事后勤建设学': '军事后勤学',
    '后方专业勤务': '军事后勤学',
    '军事物流学': '军事后勤学',
    '军事装备学': '军事装备学',
    '军事装备论证学': '军事装备学',
    '军事装备实验学': '军事装备学',
    '军事装备采购学': '军事装备学',
    '军事装备保障学': '军事装备学',
    '军事装备管理学': '军事装备学',
    '军事训练学': '军事训练学',
    '联合训练学': '军事训练学',
    '军兵种训练学': '军事训练学',
    '军事教育学': '军事训练学',
    '旅游管理': '工商管理',
    '农林经济管理': '农林经济管理',
    '农业经济管理': '农林经济管理',
    '林业经济管理': '农林经济管理',
    '社会医学与卫生事业管理': '公共管理',
    '社会保障': '公共管理',
    '土地资源管理': '公共管理',
    '图书情报与档案管理': '图书情报与档案管理',
    '图书馆学': '图书情报与档案管理',
    '情报学': '图书情报与档案管理',
    '档案学': '图书情报与档案管理',
    '艺术学理论': '艺术学理论',
    '音乐与舞蹈学': '音乐与舞蹈学',
    '戏剧与影视学': '戏剧与影视学',
    '戏剧戏曲学': '戏剧与影视学',
    '美术学': '美术学',
    '集成电路科学与工程': '集成电路科学与工程',
    '国家安全学': '国家安全学'
}


export  {
    majorDir1,
    majorDir2
};