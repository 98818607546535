import h from '@/api/http/http'

const http = h.http;
const get = h.get;
const post = h.post;
const postJson = h.postJson

const account = {
    postExpertAccounts(params) {
        return postJson("/account/expert/getExpertAccounts",params);
    },

    getAllInstitutionAccount(params){
        return post('/account/institution/getAllInstitutionAccount',params);
    },

    updateExpertInfo(params){
        return post('/account/expert/updateExpertAccount',params);
    },

    getOperationDetail(params){
        return post('/account/expert/getOperationDetail',params);
    }
}

export default account