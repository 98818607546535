<template>
  <div class='app-container'>
    <el-page-header @back="goBack" class="header">
      <template slot="content">
        规则定制：{{firstName}}-{{secondName}}
      </template>
    </el-page-header>

    <el-divider style="margin:0px"></el-divider>

    <!-- <el-card :body-style="{padding: '0px'}" shadow="hover" class="box">
            <div title="添加送审规则" @click="insertRuleClick"
                style="text-align:center;font-size:100px;line-height:230px;color:rgb(187, 187, 187);">
                <i class="el-icon-plus"></i>
            </div>
        </el-card> -->

    <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;">
      <template #buttons>
        <el-button type="success" size="mini" icon="el-icon-plus" @click="insertRuleClick">添加</el-button>
        <!--                <el-button type="success" size="mini" icon="el-icon-plus" @click="uploadSubjectInfo">上传学科信息表</el-button>-->
      </template>
    </vxe-toolbar>

    <!-- 上传送审规则 -->
    <vxe-modal v-model="showSubjectInfoModel" :position="{top: '0px'}" width="600" :show-footer='true' show-zoom
               resize :transfer='true'>
      <template #title>
        上传学科信息表
      </template>
      <el-upload class="upload-demo" ref='subjectInfo' :auto-upload='false' drag action="#">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将学科信息表拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">请上传xls/xlsx文件。</div>
        <div class="el-upload__tip" slot="tip">请以“一级单位-二级单位”的方式命名。</div>
      </el-upload>
      <template #footer>
        <el-button type="primary" size="small" @click='cfmUploadSubjectInfo'>确认</el-button>
      </template>
    </vxe-modal>

    <!-- :loading="loading" -->
    <vxe-table class="mytable-scrollbar" round align="left" ref="sendingRuleList" highlight-current-row
               highlight-hover-row :keyboard-config="{isArrow: true}" :data="sendingRuleList">
      <vxe-column type='seq' title="序号" width="50" :show-overflow="'tooltip'"></vxe-column>
      <vxe-column field="degreeName" title="学位类型" width="350" :show-overflow="'tooltip'"></vxe-column>
      <vxe-column field="reviewTemplateName" title="评阅书" width="350" :show-overflow="'tooltip'"></vxe-column>
      <vxe-column title="操作">
        <template #default="{ row }">
          <el-button type="text" size="small" icon="el-icon-view" @click='lookClick(row.sendingRuleDetailId)'>
            预览</el-button>
          <el-button type="text" size="small" icon="el-icon-edit" @click='editClick(row.sendingRuleDetailId)'>
            修改</el-button>
          <el-popconfirm style="margin-left:10px" confirm-button-text='是' cancel-button-text='否'
                         icon="el-icon-info" icon-color="red" title="是否删除？" @confirm='removeRuleHandle(row)'>
            <el-button type="text" size="small" icon="el-icon-delete" slot="reference">删除
            </el-button>
          </el-popconfirm>
        </template>
      </vxe-column>
    </vxe-table>

    <!-- <el-card v-for="item in sendingRuleList" shadow="hover" :body-style="{padding: '0px'}" class="box">
                <div style="text-align:center">
                    <img src="@/assets/img/paper.png" class="image">
                </div>
                <div class="text">
                    学位类型：{{item.degreeName}}
                </div>
                <div class="text">
                    评阅书：{{item.reviewTemplateName}}
                </div>
                <div class="tool">
                    <el-button type="success" size="mini" icon="el-icon-view" circle
                        @click='lookClick(item.sendingRuleDetailId)'></el-button>
                    <el-button type="primary" size="mini" icon="el-icon-edit" circle @click='editClick'></el-button>
                    <el-popconfirm style="margin-left:10px" confirm-button-text='是' cancel-button-text='否'
                        icon="el-icon-info" icon-color="red" title="是否删除？">
                        <el-button type="danger" slot="reference" size="mini" icon="el-icon-delete" circle></el-button>
                    </el-popconfirm>
                </div>
            </el-card> -->

    <vxe-modal v-model="insertModel" width="1000" height="100%" :show-footer='true' show-zoom resize
               :transfer='true'>
      <template #title>
        <span>{{isInsert?'添加送审规则':'修改送审规则'}}</span>
      </template>
      <template #default>
        <el-steps class="step" :active="step" finish-status='success' align-center>
          <el-step v-for='item in stepText' :title="item"></el-step>
        </el-steps>
        <el-divider></el-divider>
        <div class="stepContent">
          <div v-show="step==0">
            <vxe-form :data="formData" title-align="right" title-width="100">
              <vxe-form-item field="degreeIds" title="学位类型:" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-select v-model="data.degreeIds" placeholder="请选择" size="medium">
                    <vxe-option v-for="item in degreeList" :key="item.degreeId"
                                :value="item.degreeId" :label="item.degreeName">
                    </vxe-option>
                  </vxe-select>
                </template>
              </vxe-form-item>
              <vxe-form-item field="reviewTemplateId" title="评阅书:" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-select v-model="data.reviewTemplateIds" placeholder="请选择" size="medium">
                    <vxe-option v-for="item in templateList" :key="item.reviewTemplateId"
                                :value="item.reviewTemplateId" :label="item.templateName">
                    </vxe-option>
                  </vxe-select>
                </template>
              </vxe-form-item>
            </vxe-form>
          </div>
          <div v-show="step == 1">
            <el-form class="editName">
              <el-form-item  label-width="20%" >
                                <span slot="label" class="highlight-label">
                                  常用信息：
                                </span>
                <el-checkbox-group v-model="formData.customNames">
                  <!--                                    <el-checkbox v-for="(value,key) in namingAttrPos_base" :key="key" :label="key"-->
                  <!--                                                 @change="selectChange($event,formData,key)">{{key}}</el-checkbox>-->
                  <el-checkbox v-for="(value,key) in namingAttrPos_base" :key="key" :label="key" @change="selectChange($event,formData,key)">{{key}}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item  label-width="20%" >
                                <span slot="label" class="highlight-label">
                                  学生信息：
                                </span>
                <el-checkbox-group v-model="formData.nameStudentInfos">
                  <el-checkbox v-for="(value,key) in nameStudentInfos" :key="key" :label="key">{{key}}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
<!--              <el-form-item label="论文信息：" label-width="20%" >-->
<!--                                <span slot="label" class="highlight-label">-->
<!--                                  论文信息：-->
<!--                                </span>-->
<!--                <el-checkbox-group v-model="formData.namePaperInfos">-->
<!--                  <el-checkbox v-for="(value,key) in namePaperInfos" :key="key" :label="key">{{key}}</el-checkbox>-->
<!--                </el-checkbox-group>-->
<!--              </el-form-item>-->
              <el-form-item label="专家信息：" label-width="20%">
                <el-checkbox-group v-model="formData.nameExpertInfos">
                  <el-checkbox v-for="(value,key) in nameExpertInfos" :key="key" :label="key">{{key}}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <!--                                <el-form-item label="评阅书信息：" label-width="20%">-->
              <!--                                    <el-checkbox-group v-model="formData.nameReviewInfos">-->
              <!--                                        <el-checkbox v-for="item in nameReviewInfos" :label="item.namingAttrId">-->
              <!--                                            {{item.description}}</el-checkbox>-->
              <!--                                    </el-checkbox-group>-->
              <!--                                </el-form-item>-->
              <!--                            <el-form-item label="其他信息：" label-width="20%">-->
              <!--                                <el-checkbox-group v-model="formData.nameElseInfos">-->
              <!--                                    <el-checkbox v-for="(value,key) in nameElseInfos" :key="key" :label="key">{{key}}</el-checkbox>-->
              <!--                                </el-checkbox-group>-->
              <!--                            </el-form-item>-->
              <el-form-item label="选择序号类型：" label-width="20%">
                <el-radio-group v-model="formData.selectType">
                  <el-radio v-for="item in sequenceDescVos" :key="item.seqType" :label="item.seqType">
                    {{item.description}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item style="text-align: right;">
                <el-button @click='save_choose_1' type="success_choose" round>保存</el-button>
              </el-form-item>
              <el-form-item label="已选方式：" label-width="20%">
                <div ref='drap'>
                  <el-tag :key="tag" :data-id='tag' v-for="tag in formData.dynamicTags" closable
                          :disable-transitions="false" @close="handleClose(tag,formData)">
                    {{tag}}
                  </el-tag>
                  <!--                                    <span v-for="(tag, index) in formData.dynamicTags" :key="tag" :data-id="tag">-->
                  <!--                                        <el-input v-model="infoMapper[tag].modifyName"-->
                  <!--                                        @blur="checkInput(tag)"-->
                  <!--                                        style="width: auto; margin: 5px"-->
                  <!--                                        placeholder="请输入标签" ></el-input>-->
                  <!--                                    </span>-->
                </div>
                <el-input class="input-new-tag" v-if="formData.inputVisible"
                          v-model="formData.inputValue" ref="saveTagInput" size="small"
                          @keyup.enter.native="handleInputConfirm(formData)"
                          @blur="handleInputConfirm(formData)">
                </el-input>
<!--                <el-input class="input-new-tag" v-if="formData.inputVisible"-->
<!--                          v-model="formData.inputValue" ref="saveTagInput" size="small"-->

<!--                          @blur="handleInputConfirm(formData)">-->
<!--                </el-input>-->
                <el-button v-else class="button-new-tag" size="small" @click="showInput(formData)">+ 自定义</el-button>
              </el-form-item>
              <el-form-item style="text-align: right;">
                <el-button @click='save_sort_1' type="success_sort" round>确认</el-button>
              </el-form-item>
            </el-form>
          </div>

          <!--                    <div v-show="step==2">-->

          <!--                        <vxe-form :data="formData" title-align="right" title-width="150">-->
          <!--                            <vxe-form-item  v-for="item in formData.anonymousRuleData"  :title="item.reviewPlaceholderDescription+':'"  :span="11" :item-render="{}">-->
          <!--                                <template #default="{ data }">-->
          <!--                                    <vxe-input :key="item.reviewPlaceholderId" v-model="item.anonymousInfoMap" placeholder="替换规则" clearable></vxe-input>-->
          <!--                                </template>-->
          <!--                            </vxe-form-item>-->
          <!--                        </vxe-form>-->
          <!--                    </div>-->
          <div v-show="step==2">

            <vxe-form :data="formData.anonymousRuleData" title-align="right" title-width="150">
              <vxe-form-item  v-for="(item,index) in formData.anonymousRuleData['arr']"  :title="item.reviewPlaceholderDescription+':'"  :span="11" :item-render="{}">
                <template >
                  <!--                                    :key="item.reviewPlaceholderId"-->
                  <vxe-input :key="index" v-model="item.anonymousInfoMap" placeholder="替换规则" clearable></vxe-input>
                </template>
              </vxe-form-item>
            </vxe-form>
          </div>

          <div v-show="step == 3">
            <el-form class="editName">
              <!-- <el-form-item label="论文信息：" label-width="20%">
                                <el-checkbox-group v-model="formData.paperInfos">
                                    <el-checkbox v-for="item in paperInfos" :label="item"></el-checkbox>
                                </el-checkbox-group>
                            </el-form-item> -->
              <el-form-item label="选择信息" class="custom-label">
              </el-form-item>
              <el-form-item label="学生信息：" label-width="20%">
                <el-checkbox-group v-model="formData.studentInfos">
                  <el-checkbox v-for="item in studentInfos" :label="item.statisticsFieldsId">
                    {{item.statisticsName}}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="论文信息：" label-width="20%">
                <el-checkbox-group v-model="formData.paperInfos">
                  <el-checkbox v-for="item in paperInfos" :label="item.statisticsFieldsId">
                    {{item.statisticsName}}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="专家信息：" label-width="20%">
                <el-checkbox-group v-model="formData.expertInfos">
                  <el-checkbox v-for="item in expertInfos" :label="item.statisticsFieldsId">
                    {{item.statisticsName}}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="评阅书信息：" label-width="20%">
                <el-checkbox-group v-model="formData.reviewInfos">
                  <el-checkbox v-for="item in reviewInfos" :label="item.statisticsFieldsId">
                    {{item.statisticsName}}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="其他信息：" label-width="20%">
                <el-checkbox-group v-model="formData.elseInfos">
                  <el-checkbox v-for="item in elseInfos" :label="item.statisticsFieldsId">
                    {{item.statisticsName}}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
<!--              <el-form-item style="text-align: right;">-->
<!--                <el-button @click='save_choose' type="success_choose" round>保存</el-button>-->
<!--              </el-form-item>-->

              <el-form-item label="排序信息" class="custom-label">
              </el-form-item>
              <el-form-item label="已选方式：" label-width="20%">
                <div ref='drap1'>
                  <!--                                    <el-tag :key="tag" :data-id='tag' v-for="tag in infoArr"-->
                  <!--                                        :disable-transitions="false">-->
                  <!--                                        {{infoMapper[tag].statisticsName}}-->
                  <!--                                    </el-tag>-->
                  <!--                                    <el-input-->
                  <!--                                            type="text"-->
                  <!--                                            v-for="tag in infoArr"-->
                  <!--                                            :key="tag"-->
                  <!--                                            v-model="infoMapper[tag].statisticsName"-->
                  <!--                                            :data-id="tag"-->
                  <!--                                    >-->
                  <!--                                    </el-input>-->
                  <span v-for="(id,index) in infoArr" :data-id='id' :key="id">
                                        <el-input
                                            v-model="infoMapper[id].modifyName"
                                            @blur="checkInput(id)"
                                            style="width: auto; margin: 5px"
                                        >

                                        </el-input>
                                    </span>
                </div>
                <!-- <el-input class="input-new-tag" v-if="formData.inputVisible"
                                    v-model="formData.inputValue" ref="saveTagInput" size="small"
                                    @keyup.enter.native="handleInputConfirm(formData)"
                                    @blur="handleInputConfirm(formData)">
                                </el-input> -->
                <!-- <el-button v-else class="button-new-tag" size="small" @click="showInput(formData)">+
                                    自定义</el-button> -->
              </el-form-item>
              <el-form-item style="text-align: right;">
                <el-button @click='save_sort' type="success_sort" round>确认</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div v-show="step == 4">
            <vxe-form :data="formData.infoChange" title-align="right" title-width="100">
              <vxe-form-item field="universityLevel" title="单位类型" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-select v-model="data.universityLevel" placeholder="请选择" size="medium" multiple
                              clearable>
                    <vxe-option v-for="item in options.universityLevel" :key="item" :value="item"
                                :label="item">
                    </vxe-option>
                  </vxe-select>
                </template>
              </vxe-form-item>
              <vxe-form-item field="universityRanking" title="院校排名" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.universityRanking[0]" placeholder="开始" type="number"
                             className="number"></vxe-input> -
                  <vxe-input v-model="data.universityRanking[1]" placeholder="结束" type="number"
                             className="number"></vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item field="supervisorType" title="导师类型" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-select v-model="data.supervisorType" placeholder="请选择" size="medium" multiple
                              clearable>
                    <vxe-option v-for="item in options.supervisorType" :key="item.supervisorTypeId"
                                :value="String(item.supervisorTypeId)" :label="item.type">
                    </vxe-option>
                  </vxe-select>
                </template>
              </vxe-form-item>
              <vxe-form-item field="expertTitle" title="专家职称" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-select v-model="data.expertTitle" placeholder="请选择" size="medium" multiple
                              clearable>
                    <vxe-option v-for="item in options.expertTitle" :key="item.expertTitleId"
                                :value="String(item.expertTitleId)" :label="item.title">
                    </vxe-option>
                  </vxe-select>
                </template>
              </vxe-form-item>

              <vxe-form-item field="sendingNum" title="送审意见数" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.sendingNum" placeholder="整数类型" type="integer">
                  </vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item field="localSendingNum" title="本校意见数" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.localSendingNum" placeholder="整数类型" type="integer">
                  </vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item field="expertReviewNum" title="专家最大接审数" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.expertReviewNum" placeholder="整数类型" type="integer">
                  </vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item field="debarb" title="是否回避" :span="24" :item-render="{}">
                <template #default="{ data }">
                  <vxe-radio-group v-model="data.debarb">
                    <vxe-radio :label="1" content="是"></vxe-radio>
                    <vxe-radio :label="0" content="否"></vxe-radio>
                  </vxe-radio-group>
                </template>
              </vxe-form-item>
              <vxe-form-item field="debarbUniversity" title="回避院校" :span="18"
                             v-if="formData.infoChange.debarb == 1" key="debarbUniversity" :item-render="{}">
                <template #default="{ data }">
                  <el-input type="textarea" :autosize="{ minRows: 1,maxRows:4}"
                            key="debarbUniversity_key" placeholder="院校请用中文顿号‘、’分隔"
                            v-model="data.debarbUniversity">
                  </el-input>
                </template>
              </vxe-form-item>
              <vxe-form-item :span="6" key="debarbUniversity0" v-if="formData.infoChange.debarb == 1"
                             :item-render="{}">
                <template #default="{ data }">
                  <el-upload class="upload-demo" action='#' :auto-upload='false'
                             key="debarbUniversity0_key" :show-file-list='false' accept=".xls,.xlsx"
                             :on-change='uploadDebarb' :limit="1" style="float:left">
                    <el-button size="mini" type="primary" style="margin-right:10px">上传excel
                    </el-button>
                  </el-upload>
                  <el-link type="info" href='/static/指定回避院校模板.xlsx' class="mylink">院校模板下载
                  </el-link>
                </template>
              </vxe-form-item>
              <vxe-form-item field="debarbRegion" title="回避地区" :span="18"
                             v-if="formData.infoChange.debarb == 1" key="debarbRegion" :item-render="{}">
                <template #default="{ data }">
                  <vxe-select v-model="data.debarbRegion" placeholder="请选择地区" filterable multiple clearable transfer>
                    <vxe-optgroup v-for="province in locations" :label="province.location" >
                      <vxe-option v-if="province.markets.length > 1" :value="province.location" :label="province.location"></vxe-option>
                      <vxe-option :value="market" :label="market" v-for="market in province.markets"></vxe-option>
                    </vxe-optgroup>
                  </vxe-select>
                </template>
              </vxe-form-item>
              <vxe-form-item field="appoint" title="是否指定" :span="24" :item-render="{}">
                <template #default="{ data }">
                  <vxe-radio-group v-model="data.appoint">
                    <vxe-radio :label="1" content="是"></vxe-radio>
                    <vxe-radio :label="0" content="否"></vxe-radio>
                  </vxe-radio-group>
                </template>
              </vxe-form-item>
              <vxe-form-item field="appointUniversity" title="指定院校" key="appointUniversity" :span="18"
                             v-if="formData.infoChange.appoint == 1" :item-render="{}">
                <template #default="{ data }">
                  <el-input type="textarea" :autosize="{ minRows: 1,maxRows:4}"
                            placeholder="院校请用中文顿号‘、’分隔" v-model="data.appointUniversity">
                  </el-input>
                </template>
              </vxe-form-item>

              <vxe-form-item field="appointUniversity" :span="6" v-if="formData.infoChange.appoint == 1"
                             key="appointUniversity0" :item-render="{}">
                <template #default="{ data }">
                  <el-upload class="upload-demo" action='#' :auto-upload='false'
                             :show-file-list='false' accept=".xls,.xlsx" :on-change='uploadAppoint'
                             :limit="1" style="float:left">
                    <el-button size="mini" type="primary" style="margin-right:10px">上传excel
                    </el-button>
                  </el-upload>
                  <el-link type="info" href='/static/指定回避院校模板.xlsx' class="mylink">院校模板下载
                  </el-link>
                </template>
              </vxe-form-item>
              <vxe-form-item field="appointRegion" key="appointRegion" title="指定地区" :span="18"
                             v-if="formData.infoChange.appoint == 1" :item-render="{}">
                <template #default="{ data }">
                  <vxe-select v-model="data.appointRegion" placeholder="请选择地区" filterable multiple clearable transfer>
                    <vxe-optgroup v-for="province in locations" :label="province.location">
                      <vxe-option v-if="province.markets.length > 1" :value="province.location" :label="province.location"></vxe-option>
                      <vxe-option :value="market" :label="market" v-for="market in province.markets"></vxe-option>
                    </vxe-optgroup>
                  </vxe-select>
                </template>
              </vxe-form-item>
            </vxe-form>
          </div>
          <div v-show="step == 5">
            <!-- <vxe-form title-align="right" title-width="100">
                            <vxe-form-item field="universityLevel" title="学科匹配方式" :span="12" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-select placeholder="请选择" size="medium" multiple
                                        clearable>
                                        <vxe-option  value="指定学科" label="指定学科"></vxe-option>
                                        <vxe-option  value="关联学科" label="关联学科"></vxe-option>
                                    </vxe-select>
                                </template>
                            </vxe-form-item>
                        </vxe-form> -->
            <vxe-toolbar>
              <template #buttons>
                <el-button type="success" size="mini" icon="el-icon-plus"
                           @click="insertSubjectHandle()">添加
                </el-button>
                <el-button type="danger" size="mini" icon="el-icon-minus"

                           @click="deleteSelectCheckBoxRow">删除
                </el-button>
                <el-button type="success" size="mini" icon="el-icon-plus" @click="uploadSubjectInfo">上传学科信息表</el-button>
                <el-button type="success" size="mini" icon="el-icon-plus"
                           @click="saveSubjectHandle()">保存
                </el-button>
              </template>
            </vxe-toolbar>
            <vxe-table :data="subjectMatch" ref="subjectMatch" round align="center" keep-source
                       :edit-config="{trigger: 'dblclick', mode: 'cell'}"
                       @checkbox-change="selectChangeEvent"
            >

              <vxe-column type="checkbox" width="10%"></vxe-column>
              <vxe-column field="subjectName" title="学科(双击修改)" width="18%" :show-overflow="'tooltip'"
                          :edit-render="{placeholder: '双击选择...'}">
                <template #default="{row}">
                  {{row.subjectName}}
                </template>
                <template #edit="{ row }">
                  <vxe-input v-model="row.subjectName" placeholder="请输入"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column field="matchSubject" title="映射学硕一级学科" width="18%" :show-overflow="'tooltip'"
                          :edit-render="{placeholder: '双击选择...'}">
                <template #default="{row}">
                  {{row.matchSubject}}
                </template>
                <template #edit="{ row }">
                  <vxe-input v-model="row.matchSubject" placeholder="请输入"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column field="matchSecondMajor" title="映射二级学科" width="18%" :show-overflow="'tooltip'"
                          :edit-render="{placeholder: '双击选择...'}">
                <template #default="{row}">
                  {{row.matchSecondMajor}}
                </template>
                <template #edit="{ row }">
                  <vxe-input v-model="row.matchSecondMajor" placeholder="请输入"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column field="ranks" title="等级(双击修改)" width="15%" :show-overflow="'tooltip'"
                          :edit-render="{placeholder: '双击选择...'}">
                <template #default="{row}">
                  {{row.ranks}}
                </template>
                <template #edit="{ row }">
                  <vxe-select v-model="row.ranks" placeholder="请选择" clearable transfer>
                    <vxe-option v-for="item in levels" :key="item" :value="item" :label="item">
                    </vxe-option>
                  </vxe-select>
                </template>
              </vxe-column>
              <vxe-column field="matchRank" title="匹配等级(双击修改)" width="20%" :show-overflow="'tooltip'"
                          :edit-render="{placeholder: '双击选择...'}">
                <template #default="{row}">
                  {{row.matchRank.join(',')}}
                </template>
                <template #edit="{ row }">
                  <vxe-select v-model="row.matchRank" placeholder="请选择" clearable multiple transfer>
                    <vxe-option v-for="item in levels" :key="item" :value="item" :label="item">
                    </vxe-option>
                  </vxe-select>
                </template>
              </vxe-column>
            </vxe-table>

          </div>
          <div v-show="step == 6">
            <vxe-form :data="formData.cost" title-align="right" title-width="100">
              <vxe-form-item field="firstPayRole" title="首次支付角色" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-select v-model="data.firstPayRole" placeholder="请选择" size="medium">
                    <vxe-option v-for="item in payRole" :key='item.payRoleId'
                                :value="item.payRoleId" :label="item.payRole">
                    </vxe-option>
                  </vxe-select>
                </template>
              </vxe-form-item>
              <vxe-form-item field="firstPayPrice" title="首次收费单价" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.firstPayPrice" placeholder="整数类型" type="integer">
                  </vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item field="rePayRole" title="复审支付角色" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-select v-model="data.rePayRole" placeholder="请选择" size="medium">
                    <vxe-option v-for="item in payRole" :key='item.payRoleId'
                                :value="item.payRoleId" :label="item.payRole">
                    </vxe-option>
                  </vxe-select>
                </template>
              </vxe-form-item>
              <vxe-form-item field="rePayPrice" title="复审收费单价" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.rePayPrice" placeholder="整数类型" type="integer">
                  </vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item field="expertPay" title="专家费" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.expertPay" placeholder="整数类型" type="integer">
                  </vxe-input>
                </template>
              </vxe-form-item>
            </vxe-form>
          </div>
          <div v-show="step == 7">
            <vxe-form :data="formData.others" title-align="right" title-width="100">
              <vxe-form-item field="duplicate" title="开通查重" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-radio-group v-model="data.duplicate">
                    <vxe-radio label="0" content="不开通"></vxe-radio>
                    <vxe-radio label="1" content="开通"></vxe-radio>
                  </vxe-radio-group>
                </template>
              </vxe-form-item>
              <vxe-form-item field="sendingModeId" title="送审模式" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-select v-model="data.sendingModeId" placeholder="请选择" size="medium">
                    <vxe-option v-for="item in sendingMode" :key='item.sendingModeId'
                                :value="item.sendingModeId" :label="item.sendingMode">
                    </vxe-option>
                  </vxe-select>
                </template>
              </vxe-form-item>
              <vxe-form-item field="reviewDay" title="时间（天）" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.reviewDay" placeholder="整数类型" type="integer">
                  </vxe-input>
                </template>
              </vxe-form-item>
            </vxe-form>
          </div>
        </div>
      </template>
      <template #footer>
        <el-row :gutter="10">
          <el-col :span="12" style="text-align: left">
            <el-button @click='prevStep' size="medium" v-show='step != 0' :disabled='nextLoading'>上一步
            </el-button>
            &nbsp;
          </el-col>
          <el-col :span="12">
            <el-button @click='nextStep_v2' size="medium" v-show='step != 7' :disabled='nextLoading'>下一步
            </el-button>
            <el-button @click='nextStep_v2' type="primary" size="medium" v-show='step == 7'>提交</el-button>
          </el-col>
        </el-row>
      </template>
    </vxe-modal>
    <!-- {
    "degreeName": "专业硕士",
    "templateName": "1的专业硕士",
    "namingAttrDesc": [
        "学生姓名",
        "论文名称",
        "A,B,C",
        null
    ],
    "statisticalDesc": null,
    "filterCondition": {
        "id": 1552650177275437000,
        "isDeleted": false,
        "universityLevel": "985",
        "supervisorType": null,
        "expertTitle": "1",
        "debarb": 0,
        "appoint": 0,
        "debarbUniversity": null,
        "appointUniversity": null,
        "sendingNum": 2,
        "localSendingNum": 0,
        "expertReviewNum": 2,
        "pattern": null
    },
    "subjectMatchVos": [
        {
            "subjectRankId": 1,
            "subjectName": "计算机科学与技术",
            "ranks": "A+",
            "matchRank": "A+,A,A-"
        },
        {
            "subjectRankId": 2,
            "subjectName": "月抛",
            "ranks": "A",
            "matchRank": "S+,A+"
        }
    ],
    "payConfigVo": {
        "sendingRuleDetailId": null,
        "firstPayRole": "1",
        "firstPayPrice": "200",
        "rePayRole": "2",
        "rePayPrice": "2",
        "expertPay": "200"
    },
    "otherPreview": {
        "duplicate": "万方",
        "sendingMode": null,
        "reviewDay": "20"
    }
} -->
    <vxe-modal v-model="lookModel" width="1000" height="100%" show-zoom resize :transfer='true'>
      <template #title>
        <span>查看送审规则</span>
      </template>
      <el-descriptions :column="2" :labelStyle="{width:'15%'}" :contentStyle="{width:'35%'}" border>
        <el-descriptions-item label="学位类型">{{lookData.degreeName}}</el-descriptions-item>
        <el-descriptions-item label="评阅书模板">{{lookData.templateName}}</el-descriptions-item>
        <el-descriptions-item label="评阅书命名规则" :span="2">
          <el-tag v-for="item in lookData.namingAttrDesc" size="small" v-show="item">{{item}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="统计表字段" :span="2" labelStyle>
          <el-tag v-for="item in lookData.statisticalDesc" size="small">{{item}}</el-tag>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="desc" title="遴选条件" :column="2" :labelStyle="{width:'15%'}"
                       :contentStyle="{width:'35%'}" border>
        <el-descriptions-item label="院校级别">
          <el-tag v-for="it in lookData.filterCondition.universityLevel.split('、')" size="mini">
            {{it}}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="院校排名">
          {{lookData.filterCondition.universityRanking?lookData.filterCondition.universityRanking.replace('、',' - '):''}}
        </el-descriptions-item>

        <el-descriptions-item label="导师类型">
          <el-tag v-for="it in lookData.filterCondition.supervisorType.split('、')" size="mini">
            {{$store.state.mapper.typeMap[it]}}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="专家职称">
          <el-tag v-for="it in lookData.filterCondition.expertTitle.split('、')" size="mini">
            {{$store.state.mapper.titleMap[it]}}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="送审意见数">{{lookData.filterCondition.sendingNum}} 份</el-descriptions-item>
        <el-descriptions-item label="专家最大接审数">{{lookData.filterCondition.expertReviewNum}} 份
        </el-descriptions-item>
        <el-descriptions-item label="是否回避" :span="2">
          {{lookData.filterCondition.debarb?'是':'否'}}
        </el-descriptions-item>
        <el-descriptions-item label="回避院校" :span="2" v-if='lookData.filterCondition.debarb'>
          {{lookData.filterCondition.debarbUniversity}}</el-descriptions-item>
        <el-descriptions-item label="回避地区" :span="2" v-if='lookData.filterCondition.debarb'>
          {{lookData.filterCondition.debarbRegion}}</el-descriptions-item>
        <el-descriptions-item label="是否指定" :span="2">
          {{lookData.filterCondition.appoint?'是':'否'}}
        </el-descriptions-item>
        <el-descriptions-item label="指定院校" :span="2" v-if='lookData.filterCondition.appoint'>
          {{lookData.filterCondition.appointUniversity}}</el-descriptions-item>
        <el-descriptions-item label="指定地区" :span="2" v-if='lookData.filterCondition.appoint'>
          {{lookData.filterCondition.appointRegion}}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="desc" title="学科匹配" :column="2" :labelStyle="{width:'15%'}"
                       :contentStyle="{width:'35%'}" border>
        <el-descriptions-item v-for="item in lookData.subjectMatchVos" :label="item.subjectName">
          <el-tag v-for="it in item.matchRank.split(',')" size="mini">{{it}}</el-tag>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="desc" title="费用信息" :column="2" :labelStyle="{width:'15%'}"
                       :contentStyle="{width:'35%'}" border>
        <el-descriptions-item label="首次支付角色">{{lookData.payConfigVo.firstPayRole}}</el-descriptions-item>
        <el-descriptions-item label="首次收费单价">{{lookData.payConfigVo.firstPayPrice}} 元</el-descriptions-item>
        <el-descriptions-item label="复审支付角色">{{lookData.payConfigVo.rePayRole}}</el-descriptions-item>
        <el-descriptions-item label="复审收费单价">{{lookData.payConfigVo.rePayPrice}} 元</el-descriptions-item>
        <el-descriptions-item label="专家费">{{lookData.payConfigVo.expertPay}} 元</el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="desc" title="其他配置" :column="2" :labelStyle="{width:'15%'}"
                       :contentStyle="{width:'35%'}" border>
        <el-descriptions-item label="查重">{{lookData.otherPreview.duplicate}}</el-descriptions-item>
        <el-descriptions-item label="送审模式">{{lookData.otherPreview.sendingMode}}</el-descriptions-item>
        <el-descriptions-item label="时间">{{lookData.otherPreview.reviewDay}} 天</el-descriptions-item>
      </el-descriptions>
    </vxe-modal>
  </div>
</template>

<script>
import qs from 'qs'
import Sortable from 'sortablejs'
import XLSX from 'xlsx'
subjectFileHandle
import subjectFileHandle from "@/tools/subjectFileHandle"
import {majorDir1,majorDir2} from "@/tools/second2first"

export default {
  data() {
    return {
      insertModel: false,
      isInsert: true,
      lookModel: false,
      sendingRuleList: [],
      step: 0,
      stepText: ['选择评阅书模板', '评阅书命名规则','匿名规则', '配置统计表', '遴选条件', '学科匹配', '费用配置', '其他设置'],
      formData: {
        sendingDetailId: 0,
        // 1
        degreeIds: "",
        reviewTemplateIds: "",
        //2
        dynamicTags: [],
        customNames: [],
        nameStudentInfos: [],
        namePaperInfos:[],
        nameExpertInfos:[],
        nameReviewInfos:[],
        nameElseInfos: [],
        selectType: '1',
        inputValue: '',
        inputVisible: false,
        choose: false,
        // 3
        studentInfos: [],
        paperInfos: [],
        expertInfos: [],
        reviewInfos: [],
        elseInfos: [],
        oldStudentInfos: [],  // 保存初始的学生信息
        oldPaperInfos: [],    // 保存初始的论文信息
        oldExpertInfos: [],   // 保存初始的专家信息
        oldReviewInfos: [],   // 保存初始的评阅书信息
        oldElseInfos: [],   // 保存初始的其他信息
        //4
        infoChange: {
          "universityLevel": [],
          "supervisorType": [],
          "expertTitle": [],
          "debarb": "0",
          "appoint": "0",
          "sendingNum": "2",
          "localSendingNum": "0",
          "expertReviewNum": "2",
          "debarbUniversity": "",
          "appointUniversity": "",
          "debarbProvince": "",
          "appointProvince": "",
          "appointRegion": '',
          "debarbRegion": '',
          "universityRanking": ['', ''],
        },
        //6
        cost: {
          "firstPayRole": "",
          "firstPayPrice": "",
          "rePayRole": "",
          "rePayPrice": "",
          "expertPay": ""
        },
        // 7
        others: {
          duplicate: false,
          sendingModeId: '',
          reviewDay: ''
        },
        anonymousRuleData:{
          "arr" : [],
        },
      },
      lookData: {
        "degreeName": "",
        "templateName": "",
        "namingAttrDesc": [],
        "statisticalDesc": null,
        "filterCondition": {
          "id": 1552650177275437000,
          "isDeleted": false,
          "universityLevel": "",
          "supervisorType": "",
          "expertTitle": "",
          "debarb": 0,
          "appoint": 0,
          "debarbUniversity": null,
          "appointUniversity": null,
          "sendingNum": 2,
          "localSendingNum": 0,
          "expertReviewNum": 2,
          "pattern": null,
        },
        "subjectMatchVos": [{
          "subjectRankId": 1,
          "subjectName": "计算机科学与技术",
          "ranks": "A+",
          "matchRank": "A+,A,A-"
        },
          {
            "subjectRankId": 2,
            "subjectName": "月抛",
            "ranks": "A",
            "matchRank": "S+,A+"
          }
        ],
        "payConfigVo": {
          "sendingRuleDetailId": null,
          "firstPayRole": "1",
          "firstPayPrice": "200",
          "rePayRole": "2",
          "rePayPrice": "2",
          "expertPay": "200"
        },
        "otherPreview": {
          "duplicate": "万方",
          "sendingMode": null,
          "reviewDay": "20"
        }
      },
      // 1
      dynamicTags: [],
      customNames: [],
      inputValue: '',
      inputVisible: false,
      //5
      subjectMatch: [],
      levels: ['A+', 'A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-','其他'],
      studentInfos: [],
      paperInfos: [],
      expertInfos: [],
      reviewInfos: [],
      elseInfos:[],
      infoMapper: {},
      infoArr: [],
      paperExpertInfos: [],
      institutionId: '',
      // 第一步数据
      degreeList: [],
      templateList: [],
      degreeReview: {
        "degreeId": "34",
        "reviewTemplateId": "14"
      },
      // 第二步数据
      namingAttrPos: {},  //整体命名信息接收的数据
      namingAttrPos_base: {}, //基础的
      nameStudentInfos:{},
      namePaperInfos: {},
      nameExpertInfos: {},
      nameReviewInfos: {},
      nameElseInfos: {},
      sequenceDescVos: [],
      // 第4步数据
      options: {},
      // 第6步数据
      payRole: [],
      sendingMode: [],
      sendingRuleDetailId: '',
      // 下一步加载中
      nextLoading: false,
      locations:[],
      fakeresdata:{
        "code": 200,
        "msg": null,
        "count": null,
        "data": [
          {
            "reviewPlaceholderId": 1,
            "reviewPlaceholderName": "expertName",
            "reviewPlaceholderDescription": "评审专家姓名",
            "reviewPlaceholderType": 1,
            "anonymousInfoMap": "***评审专家姓名***"
          },
          {
            "reviewPlaceholderId": 2,
            "reviewPlaceholderName": "expertDirection",
            "reviewPlaceholderDescription": "评审专家研究方向",
            "reviewPlaceholderType": 1,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 3,
            "reviewPlaceholderName": "expertTitle",
            "reviewPlaceholderDescription": "评审专家职称",
            "reviewPlaceholderType": 1,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 4,
            "reviewPlaceholderName": "expertInstitution",
            "reviewPlaceholderDescription": "评审专家单位",
            "reviewPlaceholderType": 1,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 5,
            "reviewPlaceholderName": "expertMobile",
            "reviewPlaceholderDescription": "评审专家联系电话",
            "reviewPlaceholderType": 1,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 6,
            "reviewPlaceholderName": "expertEmail",
            "reviewPlaceholderDescription": "评审专家邮箱",
            "reviewPlaceholderType": 1,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 7,
            "reviewPlaceholderName": "reviewDate",
            "reviewPlaceholderDescription": "评审日期",
            "reviewPlaceholderType": 1,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 8,
            "reviewPlaceholderName": "expertType",
            "reviewPlaceholderDescription": "导师类型",
            "reviewPlaceholderType": 1,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 9,
            "reviewPlaceholderName": "expertSignature",
            "reviewPlaceholderDescription": "专家签名",
            "reviewPlaceholderType": 1,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 10,
            "reviewPlaceholderName": "firstGradeSubject",
            "reviewPlaceholderDescription": "评审专家一级学科",
            "reviewPlaceholderType": 1,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 11,
            "reviewPlaceholderName": "secondGradeSubject",
            "reviewPlaceholderDescription": "评审专家二级学科",
            "reviewPlaceholderType": 1,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 12,
            "reviewPlaceholderName": "ExpertIdNum",
            "reviewPlaceholderDescription": "专家身份证号",
            "reviewPlaceholderType": 1,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 13,
            "reviewPlaceholderName": "studentName",
            "reviewPlaceholderDescription": "学生姓名",
            "reviewPlaceholderType": 0,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 14,
            "reviewPlaceholderName": "studentMajor",
            "reviewPlaceholderDescription": "学生学科",
            "reviewPlaceholderType": 0,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 15,
            "reviewPlaceholderName": "studentNum",
            "reviewPlaceholderDescription": "学生学号",
            "reviewPlaceholderType": 0,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 16,
            "reviewPlaceholderName": "studentCollege",
            "reviewPlaceholderDescription": "学生单位",
            "reviewPlaceholderType": 0,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 17,
            "reviewPlaceholderName": "studentDirection",
            "reviewPlaceholderDescription": "研究方向",
            "reviewPlaceholderType": 0,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 18,
            "reviewPlaceholderName": "tutorName",
            "reviewPlaceholderDescription": "导师姓名",
            "reviewPlaceholderType": 0,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 19,
            "reviewPlaceholderName": "paperOriginId",
            "reviewPlaceholderDescription": "论文编号",
            "reviewPlaceholderType": 0,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 20,
            "reviewPlaceholderName": "index",
            "reviewPlaceholderDescription": "评阅书序号",
            "reviewPlaceholderType": 0,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 21,
            "reviewPlaceholderName": "paperTitle",
            "reviewPlaceholderDescription": "论文标题",
            "reviewPlaceholderType": 0,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 22,
            "reviewPlaceholderName": "paperKeywords",
            "reviewPlaceholderDescription": "论文关键词",
            "reviewPlaceholderType": 0,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 23,
            "reviewPlaceholderName": "paperType",
            "reviewPlaceholderDescription": "论文类型",
            "reviewPlaceholderType": 0,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 24,
            "reviewPlaceholderName": "studentFirstMajor",
            "reviewPlaceholderDescription": "学生一级学科",
            "reviewPlaceholderType": 0,
            "anonymousInfoMap": null
          },
          {
            "reviewPlaceholderId": 25,
            "reviewPlaceholderName": "studentSecondMajor",
            "reviewPlaceholderDescription": "学生二级学科",
            "reviewPlaceholderType": 0,
            "anonymousInfoMap": null
          }
        ]
      },
      showSubjectInfoModel:false,
      //第二步判断
      flag_1: true,
      //第三步判断条件
      flag_2: true,
      infoArrNames: {},
      hasInput: false,  // 标记输入框有无变化
      //第四步是否为空的判断
      isEmpty:false,
    }
  },
  computed: {
    firstName() {
      return this.$store.state.custom.institution.first;
    },
    secondName() {
      return this.$store.state.custom.institution.second;
    },
    // tableFileds: {
    //     get() {
    //         this.infoArr = [...this.formData.studentInfos, ...this.formData.paperInfos, ...this.formData
    //             .expertInfos, ...this.formData.reviewInfos
    //         ];
    //         return this.infoArr;
    //     },
    //     set(val) {
    //         this.infoArr = val;
    //     }
    // }
  },
  watch: {
      'formData.studentInfos': function (ne, old) {
          this.solveSort(ne, old);
      },
      'formData.paperInfos': function (ne, old) {
          this.solveSort(ne, old);
      },
      'formData.expertInfos': function (ne, old) {
          this.solveSort(ne, old);
      },
      'formData.reviewInfos': function (ne, old) {
          this.solveSort(ne, old);
      },
      'formData.elseInfos': function (ne, old) {
        this.solveSort(ne, old);
      },
  },
  created() {
    this.institutionId = this.$route.params.id;
    this.getDegree();
    this.getTemplate();
    this.getFilterOptions();
    this.getSubjects();
    this.getPayRole();
    this.getSendingMode();
    this.getPreviews();
    this.getLocation();
  },
  methods: {
    getLocation(){
      this.$api.schoolCustom.getLocation()
          .then(res=>{
            if(res.data.code == 200){
              this.locations = res.data.data;
            }
          })
    },
    solveSort(ne, old) {
      ne.forEach(item => {
        if (old.indexOf(item) == -1) {
          this.flag_2 = false;
          this.infoArr.push(item);
          this.infoMapper[item].modifyName = this.infoMapper[item].statisticsName;
          // this.infoMapper[item].modifyId = this.infoMapper[item].statisticsFieldsId;
        }
      })
      old.forEach(item => {
        let index = ne.indexOf(item);
        console.log("old的值",old)
        console.log("item的值",item)
        console.log("index的值",index)
        if (index == -1) {
          this.flag_2 = false;
          console.log("确定删item的值",item)
          console.log("确定删infoArr的值",this.infoArr)
          // let delete_index = this.infoArr.indexOf(String(item));
          let delete_index = this.infoArr.findIndex(el => Number(el) === Number(item));
          console.log("删除定位的index",delete_index)
          this.infoArr.splice(delete_index, 1);
        }
      })
    },
    goBack() {
      this.$router.push({
        name: 'schoolCustom_index'
      })
    },
    // 获取某院校送审规则预览
    getPreviews() {
      let params = {
        institutionId: this.institutionId
      };
      this.$api.schoolCustom.getPreviews(params).then(res => {
        if (res.data.code) {
          this.sendingRuleList = res.data.data;
        }
      }).catch(err => {
        this.$message.warning("请求出错");
      })
    },
    insertRuleClick() {
      // 点击插入规则
      // this.resetFormData()
      this.step = 0;
      this.resetFormData()
      this.isInsert = true;
      this.insertModel = true;
    },
    editClick(id) {
      // 点击编辑规则
      this.nextLoading = false;
      this.sendingRuleDetailId = id;
      this.step = 0;
      this.resetFormData()
      this.getInfo1();
      this.insertModel = true;
      this.$nextTick(() => {
        this.isInsert = false;
      })
    },
    lookClick(id) {
      // 查看送审规则
      let params = {
        sendingRuleDetailId: id
      };
      this.$api.schoolCustom.getOverview(params)
          .then(res => {
            if (res.data.code == 200) {
              this.lookData = res.data.data;
            }
          }).catch(err => {
        this.$message.warning("请求出错！")
      })
      this.lookModel = true;
    },
    // 删除规则
    removeRuleHandle(row) {
      const loading = this.$loading({
        lock: true,
        text: '删除中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let params = {
        sendingRuleDetailId: row.sendingRuleDetailId
      }
      this.$api.schoolCustom.postDeleteSendingRule(params)
          .then(res => {
            if (res.data.code == 200) {
              this.$message.success("删除成功！");
              this.getPreviews();
            } else {
              this.$message.warning("删除失败！");
            }
            loading.close();
          }).catch(err => {
        this.$message.warning("服务器维护！");
        loading.close();
      })
    },
    // 获取某个送审策略的总览
    getDegree() {
      let params = {
        institutionId: this.institutionId
      };
      this.$api.schoolCustom.getDegree(params)
          .then(res => {
            this.degreeList = res.data.data;
          }).catch(err => {
        this.$message.warning("获取学位类型错误");
      })
    },
    getTemplate() {
      let params = {
        institutionId: this.institutionId
      }
      this.$api.schoolCustom.getTemplate(params)
          .then(res => {
            this.templateList = res.data.data;
          })
    },
    // 保存第一步
    // 获取学位类型和评阅书
    getInfo1() {
      let params = {
        sendingRuleDetailId: this.sendingRuleDetailId
      }
      this.$api.schoolCustom.getSendingPolicy1(params)
          .then(res => {
            this.formData.degreeIds = res.data.data.degree.degreeId;
            this.formData.reviewTemplateIds = res.data.data.reviewTemplate.reviewTemplateId;
          }).catch(err => {
        this.$message.warning("服务器维护！");
      })
    },
    postDegreeReview() {
      let params = {
        institutionId: this.institutionId,
        "degreeIds": [this.formData.degreeIds],
        "reviewTemplateIds": [this.formData.reviewTemplateIds]
      };
      this.$api.schoolCustom.postDegreeReview(params).then(res => {
        if (res.data.code == 200) {
          this.step++;
          this.formData.sendingDetailId = res.data.data.sendingRuleDetailIds[0];
          this.getNamingRule();
        }
      }).catch(err => {
        console.log('请求出错！');
      })
    },
    // 获取命名规则字段
    getNamingRule() {
      this.$api.schoolCustom.getNamingRule().then(res => {
        if (res.data.code == 200) {
          let arr = {};
          let arr_1 = {};
          let arr_2 = {};
          let arr_3 = {};
          let arr_4 = {};
          let arr_5 = {};
          console.log( " res.data.data",res.data.data)
          res.data.data.namingAttrPos.forEach(item => {
            arr[item.description] = item.namingAttrId;
            if (item.type === 5) {
              arr_1[item.description] = item.namingAttrId;
            } else if (item.type === 0) {
              // 针对 type === 0 的操作
              arr_2[item.description] = item.namingAttrId;
            } else if (item.type === 2) {
              // 针对 type === 2 的操作
              arr_3[item.description] = item.namingAttrId;
            } else if (item.type === 3) {
              // 针对 type === 3 的操作
              arr_4[item.description] = item.namingAttrId;
            } else if (item.type === 4) {
              arr_5[item.description] = item.namingAttrId;
            }
          });
          this.namingAttrPos_base = arr_1;
          this.nameStudentInfos = arr_2;
          this.namePaperInfos = arr_3;
          this.nameExpertInfos = arr_4;
          this.nameElseInfos = arr_5;
          this.namingAttrPos = arr;
          console.log("namingAttrPos的值",this.namingAttrPos)

          console.log("显示其他",this.nameElseInfos)
          console.log("显示基础",this.namingAttrPos_base)
          this.sequenceDescVos = res.data.data.sequenceDescVos;
          this.$nextTick(() => {
            let el = this.$refs.drap;
            console.log(el);
            let that = this;
            let opt = {
              animation: 1000,
              //拖动结束
              onEnd: function (evt) {
                console.log(evt);
                //获取拖动后的排序
                that.formData.dynamicTags = sortable.toArray();
                console.log("拖动后的排序",that.formData.dynamicTags);
                // 只要拖动，不点击确认按钮，就点击下一步的时候，就要提示错误
                that.flag_1 = false;
              },
            };
            var sortable = Sortable.create(el, opt)
          });
        }
      }).catch(err => {
        this.$message.warning("请求出错");
      })
    },
    // 获取第二步的信息
    getInfo2() {
      console.log("进入第二步")
      let params = {
        sendingRuleDetailId: this.sendingRuleDetailId
      }
      this.$api.schoolCustom.getSendingPolicy2(params)
          .then(res => {
            if (res.data.code == 200) {

              let arr = [];
              let arr_1 = [];
              let arr_2 = [];
              let arr_3 = [];
              let arr_4 = [];
              let arr_5 = [];
              res.data.data.forEach(item => {
                if (item.seqType) {
                  this.formData.selectType = item.seqType;
                  console.log("selectType的内容",this.formData.selectType)
                } else {
                  arr.push(item.description);
                  console.log("arr的具体数值",item)
                  if (item.naming_attr_type === 5) {
                    arr_1.push(item.description);
                  } else if (item.naming_attr_type === 0) {
                    arr_2.push(item.description);
                  } else if (item.naming_attr_type === 2) {
                    arr_3.push(item.description);
                  } else if (item.naming_attr_type === 3) {
                    arr_4.push(item.description);
                  } else if (item.naming_attr_type === 4) {
                    arr_5.push(item.description);
                  }
                }
              });
              console.log("arr_1--基础部分",arr_1)
              this.formData.customNames = arr_1;
              this.formData.nameStudentInfos = arr_2;
              this.formData.namePaperInfos = arr_3;
              this.formData.nameExpertInfos = arr_4;
              this.formData.nameElseInfos = arr_5;

              console.log("显示读取的数据库中的具体信息--基础部分",this.formData.customNames)
              console.log("显示读取的数据库中的具体信息--学生部分",this.formData.nameStudentInfos)
              console.log("显示读取的数据库中的具体信息--其他部分",this.formData.nameElseInfos)
              this.formData.dynamicTags = arr;
              console.log("arr的内容",arr)
              setTimeout(() => {
                this.nextLoading = false;
              }, 500);
            } else {
              this.$message.warning("获取信息失败");
            }
          }).catch(err => {
        this.$message.warning("服务器维护！");
      })
    },
    save_choose_1(){
      // this.formData.choose = true;
      this.flag_1 = false;
      this.updateDynamicTags();
    },
    save_sort_1(){
      this.postNamingRule();
    },
    // 保存命名规则
    postNamingRule() {
      let oldArr = [],
          newArr = [];
      let seq = [];
      this.formData.dynamicTags.forEach(item => {
        if (this.namingAttrPos.hasOwnProperty(item)) {
          oldArr.push(this.namingAttrPos[item]);
          seq.push(this.namingAttrPos[item]);
        } else {
          newArr.push(item);
          seq.push(item);
        }
      })
      let params = [{
        sendingDetailId: this.formData.sendingDetailId,
        namingAttrIds: oldArr,
        customAttrs: newArr,
        seqType: this.formData.selectType,
        order: seq,
      }];
      console.log(params);
      this.$api.schoolCustom.postNamingRule(params).then(res => {
        if (res.data.code = 200) {
          // this.step = this.step + 1;
          this.flag_1 = true;
          this.$message.success("保存信息表字段保存成功");
        }
      }).catch(err => {
        this.flag_1 = false;
        this.$message.warning("保存信息表字段保存失败");
      })
    },
    //获取第三步信息名称
    getStatisticsFields() {
      let params = {
        sendingRuleDetailId: this.formData.sendingDetailId
      }
      console.log("sendingRuleDetailId",this.formData.sendingDetailId);
      return this.$api.schoolCustom.getStatisticsFields(params)
          .then(res => {
              this.reviewInfos = [];
              this.paperExpertInfos = [];
              console.log("第三步的模板信息",res.data.data);
              res.data.data.fieldsPos.forEach(item => {
                  item["modifyName"] = "";
                  this.infoMapper[item.statisticsFieldsId] = item;
              });
              // 规则选项名称
              this.studentInfos = res.data.data.fieldsPos.filter(item => item.type == 0);
              this.paperInfos = res.data.data.fieldsPos.filter(item => item.type == 2);
              this.expertInfos = res.data.data.fieldsPos.filter(item => item.type == 3);
              this.reviewInfos = res.data.data.fieldsPos.filter(item => item.type == 1);
              this.elseInfos = res.data.data.fieldsPos.filter(item => item.type == 4);

              console.log(this.infoMapper)
              this.$nextTick(() => {
                  let el = this.$refs.drap1;
                  console.log("drap1-el:",el);
                  let that = this;
                  let opt = {
                      animation: 1000,
                      //拖动结束
                      onEnd: function (evt) {
                          console.log(evt);
                          //获取拖动后的排序
                          console.log(sortable.toArray());
                          // that.infoArr = sortable.toArray();
                          that.infoArr = [...sortable.toArray()];  // 使用展开运算符重新赋值数组
                          console.log("拖动后的顺序",that.infoArr);
                          // console.log("拖动后的顺序",that.formData.dynamicTags);
                          // 只要拖动，不点击确认按钮，就点击下一步的时候，就要提示错误
                          that.flag_2 = false;
                    },
                  };
                  var sortable = Sortable.create(el, opt)
                  console.log(sortable)
              });
          }).catch(err => {
              this.$message.warning("配置信息功能出错！");
          })
    },

    // // 保存信息表字段
    // postTableFileds(d) {
    //     // let arr = [];
    //     // this.formData.expertInfos.forEach(item => {
    //     //     arr.push(item);
    //     // })
    //     // this.formData.reviewInfos.forEach(item => {
    //     //     arr.push(item);
    //     // })
    //     let params = {
    //         sendingRuleDetailId: this.formData.sendingDetailId,
    //         statisticsFieldsIds: this.infoArr
    //     }
    //     this.$api.schoolCustom.postStatisticsFields(params)
    //         .then(res => {
    //             this.step++;
    //         })
    //         .catch(err => {
    //             this.$message.warning("保存信息表字段错误");
    //         })
    // },
    // 获取第三步数据库中已有信息
    getInfo3() {
      let params = {
        sendingRuleDetailId: this.sendingRuleDetailId
      }
      this.$api.schoolCustom.getSendingPolicy3(params)
          .then(res => {
            if (res.data.code == 200) {
              // console.log(res.data.data)
              console.log("配置统计表信息",res.data.data);
              this.infoArr = [];

              // res.data.data.fieldsPos.forEach(item => {
              //     item["modifyName"] = item.statisticsName;
              //     this.infoMapper[item.statisticsFieldsId] = item;
              // });

              res.data.data.forEach(item => {
                this.infoArr.push(item.statisticsFieldsId);
                console.log("infoMapper",this.infoMapper)
                console.log("infoMapper[i]",this.infoMapper[item.statisticsFieldsId],item.statisticsFieldsId)
                this.infoMapper[item.statisticsFieldsId]["modifyName"] = item.statisticsName;
                // console.log("显示inforarr里面的内容是什么",this.infoArr)
                if (item.type == 0) this.formData.studentInfos.push(item.statisticsFieldsId)
                if (item.type == 2) this.formData.paperInfos.push(item.statisticsFieldsId)
                if (item.type == 3) this.formData.expertInfos.push(item.statisticsFieldsId)
                if (item.type == 1) this.formData.reviewInfos.push(item.statisticsFieldsId)
                if (item.type == 4) this.formData.elseInfos.push(item.statisticsFieldsId)
                // tmp[item.statisticsFieldsId] = item;
              });
              // this.infoMapper = tmp;
              // console.log(res.data.data,this.infoMapper)
              // 初始加载数据时保存当前已选中的状态
              this.formData.oldStudentInfos = this.formData.studentInfos
              this.formData.oldPaperInfos = this.formData.paperInfos
              this.formData.oldExpertInfos = this.formData.expertInfos
              this.formData.oldReviewInfos = this.formData.reviewInfos
              this.formData.oldElseInfos = this.formData.elseInfos
              setTimeout(() => {
                this.nextLoading = false;
              }, 500);
              // res.data.data.forEach(item => {
              // })
            }
          }).catch(err => {
            console.log(err)
          this.$message.warning("获取信息字段错误");
        })
    },
    // 保存规则定制的结果
    // 保存前三部分的结果
    // save_choose() {
    //   // if(this.formData.oldStudentInfos.length === 0 && this.formData.oldPaperInfos.length === 0 && this.formData.oldExpertInfos.length === 0 && this.formData.oldReviewInfos.length === 0 && this.formData.oldElseInfos.length === 0){
    //   //     this.formData.oldStudentInfos = this.formData.studentInfos
    //   //     this.formData.oldPaperInfos = this.formData.paperInfos
    //   //     this.formData.oldExpertInfos = this.formData.expertInfos
    //   //     this.formData.oldReviewInfos = this.formData.reviewInfos
    //   //     this.formData.oldElseInfos = this.formData.elseInfos
    //   // }
    //   this.formData.oldStudentInfos = this.formData.oldStudentInfos || [];
    //   this.formData.oldPaperInfos = this.formData.oldPaperInfos || [];
    //   this.formData.oldExpertInfos = this.formData.oldExpertInfos || [];
    //   this.formData.oldReviewInfos = this.formData.oldReviewInfos || [];
    //   this.formData.oldElseInfos = this.formData.oldElseInfos || [];
    //
    //   //调用 solveSort 方法来处理新的值和旧的值
    //   this.solveSort(this.formData.studentInfos, this.formData.oldStudentInfos);
    //   this.solveSort(this.formData.paperInfos, this.formData.oldPaperInfos);
    //   this.solveSort(this.formData.expertInfos, this.formData.oldExpertInfos);
    //   this.solveSort(this.formData.reviewInfos, this.formData.oldReviewInfos);
    //   this.solveSort(this.formData.elseInfos, this.formData.oldElseInfos);
    //
    //   this.formData.oldStudentInfos = this.formData.studentInfos
    //   this.formData.oldPaperInfos = this.formData.paperInfos
    //   this.formData.oldExpertInfos = this.formData.expertInfos
    //   this.formData.oldReviewInfos = this.formData.reviewInfos
    //   this.formData.oldElseInfos = this.formData.elseInfos
    //
    //   // this.flag_1= true
    //   this.flag_2= false
    // },
    handleNameChange() {
      // 确保输入框的值在拖动后被正确更新
      this.$forceUpdate();

      // this.infoMapper.forEach((item)=>{
      //     if(item.statisticsFieldsId == id){
      //         item.statisticsName = id;
      //     }
      // })

    },
    checkInput(id) {
      const input = this.infoMapper[id].modifyName;
      if (input.trim() === '') {
        console.log(`输入框 ${id} 没有输入`)
      } else {
        // this.flag_2 = false;
        console.log(`输入框 ${id} 有输入: ${input}`);
      }
      if(!input){
        this.isEmpty = true;
        this.$message.error('标签内容不能为空');
      }
      else{
        this.isEmpty = false;
      }
    },
    //保存移动之后的结果
    save_sort(){
      // 如果字段改名为空，要提示
      if(this.isEmpty){
        this.$message.error("保存的字段内容不能为空")
        return; // 停止后续代码执行
      }
      // 生成仅包含 statisticsName 的数组
      console.log("保存排序后的顺序：",this.infoArr)
      this.infoArrNames = this.infoArr.map(tag => this.infoMapper[tag]?.modifyName);
      console.log(this.infoArrNames)
      // return;
      console.log("显示 infoArrNames", this.infoArrNames);

      let params = {
        sendingRuleDetailId: this.formData.sendingDetailId,
        statisticsFieldsIds: this.infoArr,
        statisticsFieldsNames: this.infoArrNames
      }
      this.$api.schoolCustom.postStatisticsFields(params)
          .then(res => {
            // this.step++;
            // if(!this.flag_1){
            //     this.$message.warning("上述信息表字段未保存");
            //     this.flag_2 = false;
            // }
            // else this.flag_2 = true
            this.flag_2 = true
            this.$message.success("保存信息表字段保存成功");
          })
          .catch(err => {
            this.$message.warning("保存信息表字段修改错误");
            this.flag_2 = false;
          })
    },

    // 获取专家遴选选项
    getFilterOptions() {
      this.$api.schoolCustom.getFilterOptions().then(res => {
        if (res.data.code = 200) {
          this.options = res.data.data;
        }
      }).catch(err => {
        this.$message.warning("请求出错");
      })
    },
    // 上传指定院校
    uploadAppoint(file, fileList) {
      const loading = this.$loading({
        lock: true,
        text: '上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      // let rawFile = this.$refs.templateFile.uploadFiles[0];
      // console.log(rawFile.raw);
      var reader = new FileReader(); //读取文件内容
      reader.readAsArrayBuffer(file.raw); //防止中文乱码问题，不加reader.onload方法都不会触发
      reader.onload = (e) => {
        var dd = e.target.result;
        var workbook = XLSX.read(dd, {
          type: 'buffer'
        });
        var sheetname = workbook.SheetNames[0]

        var tables = XLSX.utils.sheet_to_json(workbook.Sheets[sheetname]);
        var schools = [];
        for (let i in tables) {
          let row = tables[i];
          schools.push(row['院校名称']);
        };
        console.log(schools);
        this.formData.infoChange.appointUniversity = schools.join('、');
        loading.close();
      }
    },
    // 上传回避院校
    uploadDebarb(file, fileList) {
      const loading = this.$loading({
        lock: true,
        text: '上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      // let rawFile = this.$refs.templateFile.uploadFiles[0];
      // console.log(rawFile.raw);
      var reader = new FileReader(); //读取文件内容
      reader.readAsArrayBuffer(file.raw); //防止中文乱码问题，不加reader.onload方法都不会触发
      reader.onload = (e) => {
        var dd = e.target.result;
        var workbook = XLSX.read(dd, {
          type: 'buffer'
        });
        var sheetname = workbook.SheetNames[0]

        var tables = XLSX.utils.sheet_to_json(workbook.Sheets[sheetname]);
        var schools = [];
        for (let i in tables) {
          let row = tables[i];
          schools.push(row['院校名称']);
        };
        console.log(schools);
        this.formData.infoChange.debarbUniversity = schools.join('、');
        loading.close();
      }
    },
    // 获取第4步信息
    getInfo4() {
      let params = {
        sendingRuleDetailId: this.sendingRuleDetailId
      }
      this.$api.schoolCustom.getSendingPolicy4(params)
          .then(res => {

            if (res.data.code == 200) {
              let item = res.data.data;
              this.formData.infoChange = {
                "universityLevel": item.universityLevel?item.universityLevel.split(
                    '、'):[],
                "supervisorType": item.supervisorType?item.supervisorType.split('、'):[],
                "expertTitle": item.expertTitle?item.expertTitle.split('、'):[],
                "debarb": item.debarb,
                "appoint": item.appoint,
                "sendingNum": item.sendingNum,
                "localSendingNum": item.localSendingNum,
                "expertReviewNum": item.expertReviewNum,
                "debarbUniversity": item.debarbUniversity,
                "appointUniversity": item.appointUniversity,
                "appointRegion": item.appointRegion?item.appointRegion.split('、'):[],
                "debarbRegion": item.debarbRegion?item.debarbRegion.split('、'):[],
                "universityRanking": item.universityRanking == null ? ['', ''] : item
                    .universityRanking.split('-'),
              };
              setTimeout(() => {
                this.nextLoading = false;
              }, 500);
            } else {
              this.$message.warning("获取信息失败");
            }
          }).catch(err => {
        this.$message.warning("服务器维护！");
      })
    },
    // 保存遴选条件
    postFilter(d) {
      console.log(this.formData.infoChange)
      let params = [{
        "sendingRuleDetailId": this.formData.sendingDetailId,
        "filterCondition": {
          "universityLevel": this.formData.infoChange.universityLevel.join('、'),
          "supervisorType": this.formData.infoChange.supervisorType.join('、'),
          "expertTitle": this.formData.infoChange.expertTitle.join('、'),
          "appointRegion": this.formData.infoChange.appointRegion?this.formData.infoChange.appointRegion.join('、'):'',
          "debarbRegion": this.formData.infoChange.appointRegion && this.formData.infoChange.debarbRegion != null?this.formData.infoChange.debarbRegion.join('、'):'',
          "universityRanking": this.formData.infoChange.appointRegion?this.formData.infoChange.universityRanking.join('-'):'',
          "debarb": this.formData.infoChange.debarb,
          "appoint": this.formData.infoChange.appoint,
          "sendingNum": this.formData.infoChange.sendingNum,
          "localSendingNum": this.formData.infoChange.localSendingNum,
          "expertReviewNum": this.formData.infoChange.expertReviewNum,
          "debarbUniversity": this.formData.infoChange.debarbUniversity,
          "appointUniversity": this.formData.infoChange.appointUniversity
        }
      }];
      this.$api.schoolCustom.postFilter(params).then(res => {
        if (res.data.code == 200) {
          this.step += d;
        }
      }).catch(err => {
        this.$message.warning("请求出错！");
      })
    },
    // 根据学校id获取学科评估
    getSubjects() {
      let params = {
        institutionId: this.institutionId
      };
      this.$api.schoolCustom.getSubjects(params)
          .then(res => {
            let arr = [];
            res.data.data.forEach(item => {
              item.matchRank = item.matchRank.split('、');
              arr.push(item);
            })
            this.subjectMatch = arr;
            console.log(this.subjectMatch);
          })
    },
    deleteSelectCheckBoxRow(){
      console.log(this.$refs.subjectMatch)
      this.$refs.subjectMatch.removeCheckboxRow();
      const { insertRecords, removeRecords, updateRecords } = this.$refs.subjectMatch.getRecordset();
      let filterSubjects = this.subjectMatch.filter(item=>{
        return !removeRecords.includes(item);
      });

      this.subjectMatch = filterSubjects;
      console.log(this.subjectMatch)

    },
    insertSubjectHandle(row) {
      this.subjectMatch.unshift({
        "subjectRankId": '',
        "ranks": '',
        "matchRank": [],
        "subjectName": '',
        'matchSubject': '',
        'matchSecondMajor': ''
      })
      let $subjectMatch = this.$refs.subjectMatch;
      $subjectMatch.setEditRow(this.subjectMatch[0]);
    },

    selectChangeEvent ({ checked }) {
      // this.$refs.subjectMatch.getCheckboxRecords()

      // console.log(checked ? '勾选事件' : '取消事件', records)
    },
    // 获取第5步信息
    getInfo5() {

    },
    // 保存学科评估以及对应的送审评估等级
    postSubjects(d) {
      let subjectRankEntities = [];
      console.log(this.subjectMatch);
      this.subjectMatch.forEach(item => {
        subjectRankEntities.push({
          "subjectRankId": item.subjectRankId,
          "ranks": item.ranks,
          "matchRank": item.matchRank.join('、'),
          "subjectName": item.subjectName,
          'matchSubject': item.matchSubject,
          'matchSecondMajor': item.matchSecondMajor
        })
      });
      let params = {
        subjectRankEntities: subjectRankEntities,
        institutionId: this.institutionId,
      };
      this.$api.schoolCustom.postSubjects(params)
          .then(res => {
            if (res.data.code == 200) {
              this.step += d;
            }
          })
    },
    // 获取支付角色
    getPayRole() {
      this.$api.schoolCustom.getPayRole()
          .then(res => {
            this.payRole = res.data.data;
          })
    },
    // 获取第6步信息
    getInfo6() {
      let params = {
        sendingRuleDetailId: this.sendingRuleDetailId
      }
      this.$api.schoolCustom.getSendingPolicy6(params)
          .then(res => {
            if (res.data.code == 200) {
              let item = res.data.data.pay;
              this.formData.cost = {
                firstPayRole: Number(item.payRole),
                firstPayPrice: item.reReward,
                rePayRole: item.rePayRole,
                rePayPrice: item.reReward,
                expertPay: item.expertReward,
              }
              setTimeout(() => {
                this.nextLoading = false;
              }, 500);
            } else {
              this.$message.warning("获取信息失败");
            }
          }).catch(err => {
        this.$message.warning("服务器维护！");
      })
    },
    // 保存支付模式以及其他内容
    postPay(d) {
      let params = [{
        sendingRuleDetailId: this.formData.sendingDetailId,
        firstPayRole: this.formData.cost.firstPayRole,
        firstPayPrice: this.formData.cost.firstPayPrice,
        rePayRole: this.formData.cost.rePayRole,
        rePayPrice: this.formData.cost.rePayPrice,
        expertPay: this.formData.cost.expertPay,
      }];
      this.$api.schoolCustom.postPay(params)
          .then(res => {
            if (res.data.code == 200) {
              this.step += d;
            }
          })
    },
    // 获取送审模式
    getSendingMode() {
      this.$api.schoolCustom.getSendingMode()
          .then(res => {
            this.sendingMode = res.data.data;
          })
    },
    // 获取第7步信息
    getInfo7() {
      let params = {
        sendingRuleDetailId: this.sendingRuleDetailId
      }
      this.$api.schoolCustom.getSendingPolicy7(params)
          .then(res => {
            if (res.data.code == 200) {
              let item = res.data.data.data;
              this.formData.others = {
                duplicate: item.duplicate,
                sendingModeId: item.sendingModeId,
                reviewDay: item.reviewDay
              }
              setTimeout(() => {
                this.nextLoading = false;
              }, 500);
            } else {
              this.$message.warning("获取信息失败");
            }
          }).catch(err => {
        this.$message.warning("服务器维护！");
      })
    },
    // 保存其他
    postOther(d) {
      let params = [{
        sendingRuleDetailId: this.formData.sendingDetailId,
        otherDetail: {
          duplicate: this.formData.others.duplicate,
          sendingModeId: this.formData.others.sendingModeId,
          reviewDay: this.formData.others.reviewDay
        }
      }];
      this.$api.schoolCustom.postOther(params).then(res => {
        if (res.data.code == 200) {
          this.step += d;
          this.insertModel = false;
          this.getPreviews();
          this.getTemplate();
        }
      })
    },
    // 下一步
    // nextStep() {
    //     if (!this.isInsert) this.nextLoading = true;
    //     console.log('this.nextLoading ' + this.nextLoading);
    //     switch (this.step) {
    //         case 0:
    //             this.postDegreeReview();
    //             if (!this.isInsert) this.getInfo2();
    //             break;
    //         case 1:
    //             this.postNamingRule(1);
    //             this.getStatisticsFields();
    //             if (!this.isInsert) this.getInfo3();
    //             break;
    //
    //         case 2:
    //             this.postTableFileds(1);
    //             if (!this.isInsert) this.getInfo4();
    //             break;
    //         case 3:
    //             this.postFilter(1);
    //             if (!this.isInsert) setTimeout(() => {
    //                 this.nextLoading = false;
    //             }, 500);
    //             break;
    //         case 4:
    //             this.postSubjects(1);
    //             console.log(this.isInsert)
    //             if (!this.isInsert) this.getInfo6();
    //             break;
    //         case 5:
    //             this.postPay(1);
    //             if (!this.isInsert) this.getInfo7();
    //             break;
    //         case 6:
    //             this.postOther(0);
    //             break;
    //
    //     }
    //     // if (this.step < 6) this.step++;
    // },
    async nextStep_v2() {
      if (!this.isInsert) this.nextLoading = true;
      console.log('this.nextLoading ' + this.nextLoading);
      switch (this.step) {
        case 0:
          this.postDegreeReview();
          if (!this.isInsert) this.getInfo2();
          break;
        case 1:
          if (this.flag_1) this.step++;
          else this.$message.warning("修改信息未确认，请点击确认按钮！");
          // this.postNamingRule(1);
          this.getInfoAnonymous();
          // /school/sendingPolicy/2_1

          // if (!this.isInsert) this.getInfoAnonymous();
          console.log(1)
          break;
        case 2:
          console.log(this.formData.anonymousRuleData);
          this.savePlaceholderMap(1);

          // this.step++;
          this.getStatisticsFields().then(()=>{
              if (!this.isInsert) this.getInfo3();
          });
          this.flag_2 = true;
          break;
        case 3:
          // this.postTableFileds(1);
          if (this.flag_2) this.step++;
          else this.$message.warning("修改信息未确认，请点击确认按钮！");
          if (!this.isInsert) this.getInfo4();
          break;
        case 4:
          console.log("遴选条件")
          this.postFilter(1);
          if (!this.isInsert) setTimeout(() => {
            this.nextLoading = false;
          }, 500);
          break;
        case 5:
          // 学科匹配
          this.postSubjects(1);
          if (!this.isInsert) this.getInfo6();
          break;
        case 6:
          this.postPay(1);
          if (!this.isInsert) this.getInfo7();
          break;
        case 7:
          this.postOther(0);
          this.nextLoading = false;
          break;

      }
      // if (this.step < 6) this.step++;
    },
    // 上一步
    prevStep() {
      if (this.step > 0) this.step--;
    },
    //2
    selectChange(val, item, key) {
      console.log(val, key);
      if (key == "序号") {
        this.formData.selectType = val ? '1' : '';
      }
    },
    //     // item.dynamicTags = item.customNames;
    //     // this.updateDynamicTags(item.customNames);
    //     this.updateDynamicTags();
    // },
    // selectChange_student(val, item, key) {
    //     console.log(val, key);
    //     if (key == "序号") {
    //         this.formData.selectType = val ? '1' : '';
    //     }
    //     // item.dynamicTags = item.nameStudentInfos;
    //     // this.updateDynamicTags(item.nameStudentInfos);
    //     this.updateDynamicTags();
    // },
    // selectChange_paper(val, item, key) {
    //     console.log(val, key);
    //     if (key == "序号") {
    //         this.formData.selectType = val ? '1' : '';
    //     }
    //     // item.dynamicTags = item.namePaperInfos;
    //     // this.updateDynamicTags(item.namePaperInfos);
    //     this.updateDynamicTags();
    // },
    // selectChange_expert(val, item, key) {
    //     console.log(val, key);
    //     if (key == "序号") {
    //         this.formData.selectType = val ? '1' : '';
    //     }
    //     // item.dynamicTags = item.nameExpertInfos;
    //     // this.updateDynamicTags(item.nameExpertInfos);
    //     this.updateDynamicTags();
    // },
    // selectChange_else(val, item, key) {
    //     console.log(val, key);
    //     if (key == "序号") {
    //         this.formData.selectType = val ? '1' : '';
    //     }
    //     // item.dynamicTags = item.nameElseInfos;
    //     // this.updateDynamicTags(item.nameElseInfos);
    //     this.updateDynamicTags();
    // },

    updateDynamicTags() {
      // 提取所有字段合并后的标签
      let allTagsFromFields = [
        ...this.formData.customNames,
        ...this.formData.nameStudentInfos,
        ...this.formData.namePaperInfos,
        ...this.formData.nameExpertInfos,
        ...this.formData.nameElseInfos,
        ...this.formData.inputValue
      ];

      // 去重，生成新的标签集合
      const allTagsSet = new Set(allTagsFromFields);

      const manualTags = this.formData.dynamicTags.filter(tag => {
        // 确保 this.namingAttrPos_base 是一个数组
        const isInBase = this.namingAttrPos_base && tag in this.namingAttrPos_base;
        const isInStudentInfo = this.nameStudentInfos && tag in this.nameStudentInfos;
        const isInPaperInfo = this.namePaperInfos && tag in this.namePaperInfos;
        const isInExpertInfo = this.nameExpertInfos && tag in this.nameExpertInfos;
        const isInElseInfo = this.nameElseInfos && tag in this.nameElseInfos;

        // 返回过滤后的结果
        return !(isInBase || isInStudentInfo || isInPaperInfo || isInExpertInfo || isInElseInfo);
      });

      console.log("namingAttrPos_base",this.namingAttrPos_base)
      console.log("manualTags",manualTags)
      // 合并去重后的标签和手动标签
      const updatedDynamicTags = [
        ...Array.from(allTagsSet),  // 合并所有动态标签并去重
        ...manualTags               // 保留手动添加的标签
      ];

      // 更新 dynamicTags
      this.formData.dynamicTags = Array.from(new Set(updatedDynamicTags)); // 最终去重并赋值给 dynamicTags

    },




    resetFormData() {
      // 重置弹窗内容
      this.formData = {
        sendingDetailId: 0,
        // 1
        degreeIds: "",
        reviewTemplateIds: "",
        //2
        dynamicTags: [],
        customNames: [],
        //新添加
        nameStudentInfos: [],
        namePaperInfos: [],
        nameExpertInfos: [],
        nameElseInfos: [],
        selectType: '',
        inputValue: '',
        inputVisible: false,
        // 3
        studentInfos: [],
        paperInfos: [],
        expertInfos: [],
        reviewInfos: [],
        elseInfos: [],
        //4
        infoChange: {
          "universityLevel": [],
          "supervisorType": [],
          "expertTitle": [],
          "debarb": 0,
          "appoint": 0,
          "sendingNum": 0,
          "localSendingNum": 0,
          "expertReviewNum": 0,
          "debarbUniversity": "",
          "appointUniversity": "",
          "appointRegion": '',
          "debarbRegion": '',
          "universityRanking": [0, 1],
        },
        //6
        cost: {
          "firstPayRole": "",
          "firstPayPrice": "",
          "rePayRole": "",
          "rePayPrice": "",
          "expertPay": ""
        },
        // 7
        others: {
          duplicate: false,
          sendingModeId: '',
          reviewDay: ''
        },
        anonymousRuleData: {
          arr:[]
        }
      }
    },
    handleClose(tag, item) {
      item.dynamicTags.splice(item.dynamicTags.indexOf(tag), 1);
      // 定义所有需要处理的数组
      const arraysToUpdate = [
        this.formData.customNames,
        this.formData.nameStudentInfos,
        this.formData.namePaperInfos,
        this.formData.nameExpertInfos,
        this.formData.nameElseInfos
      ];

      // 遍历并尝试从每个数组中移除 tag
      arraysToUpdate.forEach(array => {
        if (Array.isArray(array)) {
          const tagIndex = array.indexOf(tag);
          if (tagIndex > -1) {
            array.splice(tagIndex, 1);
          }
        }
      });

      // 检查 inputValue 是否为数组并移除 tag
      if (Array.isArray(this.formData.inputValue)) {
        const inputValueIndex = this.formData.inputValue.indexOf(tag);
        if (inputValueIndex > -1) {
          this.formData.inputValue.splice(inputValueIndex, 1);
        }
      }
    },
    handleInputConfirm(item) {
      let inputValue = item.inputValue;
      if (inputValue) {
        item.dynamicTags.push(inputValue);
      }
      item.inputVisible = false;
      item.inputValue = '';
    },
    showInput(item) {
      item.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    getInfoAnonymous(){
      // let data = this.fakeresdata.data;
      // this.anonymousRuleData = data;
      // let temp = {};
      // this.formData.anonymousRuleData= data;
      // console.log(this.anonymousRuleData)
      // let params = {
      //     sendingRuleDetailId: this.formData.sendingDetailId
      // };
      let params = new URLSearchParams()
      params.append('sendingRuleDetailId', this.formData.sendingDetailId)
      console.log(params)

      this.$api.schoolCustom.getAnonymousInfo(params).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          this.formData.anonymousRuleData={
            "arr" : res.data.data
          }

          setTimeout(() => {
            this.nextLoading = false;
          }, 500);
        } else {
          this.$message.warning("获取信息失败");
        }
      }).catch(err => {
        console.log(err)
        this.$message.warning("服务器维护！");
      });

    },
    savePlaceholderMap(d){
      let params = {
        sendingRuleDetailId: this.formData.sendingDetailId,
        placeholderInfoMapDTOs:this.formData.anonymousRuleData.arr
      };
      // console.log(params)
      // return;
      this.$api.schoolCustom.savePlaceholderMap(params).then(res => {
        console.log(res);
        if (res.data.code = 200) {
          this.step = this.step + d;

        }
      }).catch(err => {
        console.log(err)
        this.$message.warning("请求出错");
      })
    },
    uploadSubjectInfo(){
      this.showSubjectInfoModel = true;
    },
    cfmUploadSubjectInfo(){
      if (this.$refs.subjectInfo.uploadFiles.length != 1) {
        this.$message.warning("请选择审核信息");
        return;
      }
      var xlsFile = this.$refs.subjectInfo.uploadFiles[0];
      // console.log(xlsFile)
      let schoolName = xlsFile.name.split("-")[0];
      this.readLine(xlsFile,schoolName);
    },
    readLine(xlsFile,schoolName){
      //  去重前的数组
      let arrOrigin = [];
      // 去重后的数组
      let arrFiller = [];
      // 全部处理完成的数组
      let res = [];
      // 专硕学科信息数组
      let proArr = [];
      // 学硕学科信息数组
      let masArr = [];
      let subRender = new FileReader();
      subRender.readAsArrayBuffer(xlsFile.raw); //防止中文乱码问题，不加reader.onload方法都不会触发
      subRender.onload = (e) => {

        let dd = e.target.result;
        let workbook = XLSX.read(dd, {
          type: 'buffer'
        });
        let sheetname = workbook.SheetNames[0];
        let tables = XLSX.utils.sheet_to_json(workbook.Sheets[sheetname]);

        // console.log(tables)
        // 1 去重
        let s = new Set();
          for (let item of tables) {
              s.add(item["二级学科名称（必填）"])
          }

        // 1.1 去重列表中已有的
          let subjectMatchSet = new Set();
          for(let item of this.subjectMatch){
              subjectMatchSet.add(item.matchSecondMajor);
          }
          // console.log(s,subjectMatchSet)
          for(let item of s){
              if(subjectMatchSet.has(item) ){
                  s.delete(item)
              }
          }
          // console.log(s)




        // 2 转换为标准格式
        for( let item of s ) {
          let row = {
            subjectRankId: '',
            // 学科
            subjectName:item,
            // 一级学科
            matchSubject: "",
            // 二级学科-与学科相同
            matchSecondMajor:item,
            // 学科等级
            ranks: "",
            // 匹配等级
            matchRank: [],


          };
          // 3 每个数据中，查询二级学科对应的一级学科
          let majorObj = this.getSchoolMajor_v2(item);
          // 3.1 查询得到
          if(majorObj){
            row.matchSubject = majorObj.data;
            // 4 查询一级学科对应的学科id
            let ranks = this.findSubjectRank(schoolName,majorObj.data);

            if(ranks){
              row.ranks = ranks;
              row.matchRank = this.findSubjectMatchRank(ranks);
            }else{
              // 查不到
              row.ranks = '';
              row.matchRank = this.findSubjectMatchRank('其他');
            }
            if(majorObj.type == "学硕"){
              masArr.push(row);
            }else{
              proArr.push(row);
            }

          }else{
            // 3.2 查询不到
            // 4 访问数据库
            proArr.push(row)
          }



          // 4 查询一级学科对应的学科id


          // arrOrigin.push(row)
        }

        // console.log("学科信息表：",arrOrigin);
        this.subjectMatch.unshift(...masArr);
        this.subjectMatch.unshift(...proArr);
        // const { insertRecords, removeRecords, updateRecords } = this.$refs.subjectMatch.getRecordset();
        // console.log(insertRecords, removeRecords, updateRecords)
        this.showSubjectInfoModel = false;
        this.$message.info("共有"+proArr.length+"条数据待确认");
        // let $subjectMatch = this.$refs.subjectMatch;
        // // console.log(proArr)
        // $subjectMatch.insert(proArr).then(res=>{
        //     console.log(res);
        //     this.subjectMatch.unshift(...masArr);
        // });

        // this.subjectMatch.unshift({
        //     "subjectRankId": '',
        //     "ranks": '',
        //     "matchRank": [],
        //     "subjectName": '',
        //     'matchSubject': '',
        //     'matchSecondMajor': ''
        // })
        // let $subjectMatch = this.$refs.subjectMatch;
        // $subjectMatch.setEditRow(this.subjectMatch[0]);

      }

    },
    findSubjectRank(schoolName, firstMajorName){
      let flag = false;
      // 1 遍历所有学校
      // console.log("得到学科等级-院校名称",schoolName);
      // console.log("得到学科等级-一级学科名称",firstMajorName);
      for(let i = 0;i<subjectFileHandle.length;i++){
        // 2 找到对应学校
        if(subjectFileHandle[i].name == schoolName){
          // 3 遍历对应学校所有的一级学科
          let majorList = subjectFileHandle[i].majorList;
          for(let j = 0; j < majorList.length; j++){
            let major = majorList[j];
            let fitstMajorList = major.majorList;
            // 遍历一级学科下的所有二级学科
            for(let w = 0; w<fitstMajorList.length; w++){
              let fitstMajor = fitstMajorList[w];
              // 二级学科找到，返回对应的一级学科name
              if(fitstMajor.majorName == firstMajorName){
                return fitstMajor.ranks;
              }
            }
          }
          break;
        }
      }
      return flag;
    },
    findSubjectMatchRank(ranks){
      let arr = [];
      switch (ranks) {
        case '其他':
          arr.push('其他');
        case 'C-':
          arr.push('C-');
        case 'C':
          arr.push('C');
        case 'C+':
          arr.push('C+');
        case 'B-':
          arr.push('B-');
        case 'B':
          arr.push('B');
        case 'B+':
          arr.push('B+');
        case 'A-':
          arr.push('A-');
        case 'A':
          arr.push('A');
        case 'A+':
          arr.push('A+');
      }
      return arr;
    },
    getSchoolMajor_v2(secondMajorName){
      let res = null;
      // 二级学科换一级学科
      // console.log(secondMajorName)
      if(majorDir2[secondMajorName]){
        res = {
          data:majorDir2[secondMajorName],
          type:"学硕"
        }

      }else if(majorDir1[secondMajorName]){
        res = {
          data:majorDir1[secondMajorName],
          type:"专硕"
        }
      }
      return res;
    },
    getSchoolMajor(subjectFileHandle,schoolName,secondMajorName){
      let flag = false;
      // 1 遍历所有学校
      for(let i = 0;i<subjectFileHandle.length;i++){
        // 2 找到对应学校
        if(subjectFileHandle[i].name == schoolName){
          // 3 遍历对应学校所有的一级学科
          let majorList = subjectFileHandle[i].majorList;
          for(let j = 0;j<majorList.length;j++){
            let major = majorList[j];
            let secondMajorList = major.majorList;
            // 遍历一级学科下的所有二级学科
            for(let w = 0;w<secondMajorList;w++){
              let secondMajor = secondMajorList[w];
              // 二级学科找到，返回对应的一级学科name
              if(secondMajor.name == secondMajorName){
                return major.name;
              }
            }
          }
          break;
        }
      }
      return flag;
    },
    saveSubjectHandle(d){
      let subjectRankEntities = [];
      console.log(this.subjectMatch);
      this.subjectMatch.forEach(item => {
        subjectRankEntities.push({
          "subjectRankId": item.subjectRankId,
          "ranks": item.ranks,
          "matchRank": item.matchRank.join('、'),
          "subjectName": item.subjectName,
          'matchSubject': item.matchSubject,
          'matchSecondMajor': item.matchSecondMajor
        })
      });
      let params = {
        subjectRankEntities: subjectRankEntities,
        institutionId: this.institutionId,
      };
      this.$api.schoolCustom.postSubjects(params)
          .then(res => {
            this.$message.success("保存成功！");
          })
    }

  },
  components: {

  }
}
</script>

<style lang='css' scoped>
/* .header {
        padding-bottom: 20px;
        border-bottom: 1px #000 solid;
        box-shadow: 0 0px 4px rgb(0 21 42 / 8%);
    } */

.box {
  width: 200px;
  height: 230px;
  margin: 0 20px 20px 0px;
  float: left;
}

.image {
  width: 130px;
  height: 150px;
}

.box .text {
  color: rgb(187, 187, 187);
  font-size: 14px;
  padding-bottom: 5px;
  margin-left: 20px;
}

.box .tool {
  /* float: right; */
  text-align: right;
  padding-right: 20px;
}

.desc {
  margin-top: 15px;
}

/*滚动条的轨道*/
.box::-webkit-scrollbar-track {
  background-color: #FFFFFF;
}

/*滚动条里面的小方块，能向上向下移动*/
.box::-webkit-scrollbar {
  width: 8px;
}

.box::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 5px;
  border: 1px solid #F1F1F1;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.box::-webkit-scrollbar-thumb:hover {
  background-color: #A8A8A8;
}

.box::-webkit-scrollbar-thumb:active {
  background-color: #787878;
}

.number {
  width: 45% !important;
}
</style>

<style>
.tool .el-button--mini.is-circle {
  padding: 4px;
}

.el-divider--horizontal {
  margin: 20px 0px;
}

.step .el-step__title {
  font-size: 14px;
}

.el-tag {
  margin-right: 10px;
}

.button-new-tag {
  /* margin-left: 10px; */
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  /* margin-left: 10px; */
  vertical-align: bottom;
}

.box.el-card {
  overflow: auto;
}

.custom-label {
  font-size: 16px;
  font-weight: bold;
}

.highlight-label{
  color: #13cd66;
}
</style>