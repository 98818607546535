<template>
    <div class="app-container">
        <div class="back">
            <div class="goback">
                <el-page-header style="margin-bottom: 10px;" @back="goBack" content="送审管理">
                </el-page-header>
            </div>
        </div>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span style="font-size:15px;">本篇论文送审要求</span>
            </div>
            <div style="margin-top:5px;">
                <el-descriptions title="" :labelStyle="{'width':'10%'}" :contentStyle="{'width':'15%'}" :column="4"
                    size="medium" border>
                    <el-descriptions-item v-for="(value,key) in labels" :label="value">
                        {{auditInfo[key]}}
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions class="tdOverflow" :labelStyle="{'width':'10%'}" :contentStyle="{'width':'40%'}" :column="2"
                    size="medium" border>
                    <el-descriptions-item v-for="(value,key) in oneLabels" :label="value" >
                            <div :title="oneline[key]" :style="{'overflow':'hidden','white-space':'nowrap','textOverflow':'ellipsis'}">
                                {{oneline[key]}}
                            </div>
                    </el-descriptions-item>
                </el-descriptions>


                <el-descriptions title="" :column="3" size="medium" border>
                    <el-descriptions-item :label="paperLabel['paperName']" :labelStyle="{'width':'10%'}"
                        :contentStyle="{'width':'40%'}">
                        {{paperInfo['paperName']}}
                    </el-descriptions-item>
                    <el-descriptions-item :label="paperLabel['oldId']" :labelStyle="{'width':'10%'}"
                        :contentStyle="{'width':'15%'}">
                        {{paperInfo['oldId']}}
                    </el-descriptions-item>
<!--                    <el-descriptions-item :label="paperLabel['subMajor']" :labelStyle="{'width':'10%'}"-->
<!--                        :contentStyle="{'width':'15%'}">-->
<!--                        {{paperInfo['subMajor']}}-->
<!--                    </el-descriptions-item>-->
                    <el-descriptions-item :label="paperLabel['studentName']" :labelStyle="{'width':'10%'}"
                                          :contentStyle="{'width':'15%'}">
                      {{paperInfo['studentName']}}
                    </el-descriptions-item>
                    <el-descriptions-item :label="paperLabel['paperKeywords']" :labelStyle="{'width':'10%'}"
                        :contentStyle="{'width':'40%'}">
                        {{paperInfo['paperKeywords']}}
                    </el-descriptions-item>
                    <el-descriptions-item :label="paperLabel['note']" :labelStyle="{'width':'10%'}"
                                          :contentStyle="{'width':'40%'}">
                        {{paperInfo['note']}}
                    </el-descriptions-item>
                </el-descriptions>
            </div>
        </el-card>
        <!-- <div>论文关键词：{{paperKeywords}}</div> -->
        <div style="margin-top:10px;">
            <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;" :refresh="{query: refresh}">
                <template #buttons>
                    <el-popover placement="right" trigger="click">
                        <el-form label-position="left" >
                            <el-form :inline="true" class="demo-form-inline">
                                <el-form-item label="评审意愿" label-width="70px">
                                    <el-checkbox-group v-model="accept" size="small">
                                        <el-checkbox-button label="A">A</el-checkbox-button>
                                        <el-checkbox-button label="B">B</el-checkbox-button>
                                        <el-checkbox-button label="C">C</el-checkbox-button>
                                    </el-checkbox-group>
                                </el-form-item>
                                <!-- <el-form-item label="评审责任心" label-width="90px">
                                    <el-checkbox-group v-model="responsibility" size="small">
                                        <el-checkbox-button label="A">A</el-checkbox-button>
                                        <el-checkbox-button label="B">B</el-checkbox-button>
                                        <el-checkbox-button label="C">C</el-checkbox-button>
                                    </el-checkbox-group>
                                </el-form-item> -->
                            </el-form>
                            <el-form :inline="true" class="demo-form-inline">
                                <el-form-item label="评审速度" label-width="70px">
                                    <el-checkbox-group v-model="frequency" size="small">
                                        <el-checkbox-button label="A">A</el-checkbox-button>
                                        <el-checkbox-button label="B">B</el-checkbox-button>
                                        <el-checkbox-button label="C">C</el-checkbox-button>
                                    </el-checkbox-group>
                                </el-form-item>
                                <!-- <el-form-item label="评审奇异性" label-width="90px">
                                    <el-checkbox-group v-model="singularity" size="small">
                                        <el-checkbox-button label="A">A</el-checkbox-button>
                                        <el-checkbox-button label="B">B</el-checkbox-button>
                                        <el-checkbox-button label="C">C</el-checkbox-button>
                                    </el-checkbox-group>
                                </el-form-item> -->
                            </el-form>
                            <el-form :inline="true" class="demo-form-inline">
                                <el-form-item label="评审质量" label-width="70px">
                                    <el-checkbox-group v-model="quality" size="small">
                                        <el-checkbox-button label="A">A</el-checkbox-button>
                                        <el-checkbox-button label="B">B</el-checkbox-button>
                                    </el-checkbox-group>
                                </el-form-item>
                                <!-- <el-form-item label="评审均值" label-width="90px">
                                    <el-checkbox-group v-model="mean" size="small">
                                        <el-checkbox-button label="A">A</el-checkbox-button>
                                        <el-checkbox-button label="B">B</el-checkbox-button>
                                        <el-checkbox-button label="C">C</el-checkbox-button>
                                        <el-checkbox-button label="D">D</el-checkbox-button>
                                        <el-checkbox-button label="E">E</el-checkbox-button>
                                    </el-checkbox-group>
                                </el-form-item> -->
                            </el-form>
                            <el-form :inline="true" class="demo-form-inline">
                              <el-form-item label="严格程度" label-width="70px">
                                <el-input placeholder="选填，请输入分数区间：80-90" style="width:230px;" v-model="avgScore" size='medium'
                                          class="form-line-item">
                                </el-input>
                              </el-form-item>
                            </el-form>
                            <el-form :inline="true" class="demo-form-inline">
                                <el-form-item label="专家库" label-width="70px">
                                  <el-checkbox-group v-model="checkedExperts" size="small" @change="handleExpertChange">
                                    <el-checkbox-button :label="0">活跃专家</el-checkbox-button>
                                    <el-checkbox-button :label="1">备用专家</el-checkbox-button>
                                  </el-checkbox-group>
                                </el-form-item>
                                <!-- <el-form-item label="评审标准差" label-width="90px">
                                    <el-checkbox-group v-model="standard_deviation" size="small">
                                        <el-checkbox-button label="A">A</el-checkbox-button>
                                        <el-checkbox-button label="B">B</el-checkbox-button>
                                        <el-checkbox-button label="C">C</el-checkbox-button>
                                        <el-checkbox-button label="D">D</el-checkbox-button>
                                        <el-checkbox-button label="E">E</el-checkbox-button>
                                    </el-checkbox-group>
                                </el-form-item> -->
                                <el-form :inline="true" class="demo-form-inline">
                                <el-form-item label="匹配学科"  label-width="90px">
                                    <el-radio-group v-model="isSecondMajor" size="small">
                                        <el-radio-button :label="0">一级学科</el-radio-button>
                                        <el-radio-button :label="1">二级学科</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </el-form>
                            </el-form>
<!--                                <el-form :inline="true" class="demo-form-inline">-->
<!--                                    <el-form-item label="最低分数:">-->
<!--                                        <el-input placeholder="请输入快速匹配最低分数"  v-model="basicScore" size='small' >-->
<!--                                        </el-input>-->
<!--                                    </el-form-item>-->
<!--                                </el-form>-->

                                <el-form-item label="" label-width="80px">
                                  <!--<el-button type="success" size="small" @click="matchHandle">开始匹配</el-button>-->
                                    <el-popconfirm title="是否开始匹配？" @confirm='confirmFineMatch'>
                                      <el-button style="margin:0 10px;" slot="reference" type="success"  size="small" >开始匹配
                                      </el-button>
                                    </el-popconfirm>
<!--                                    <el-popconfirm title="是否快速匹配？" @confirm='confirmQuickMatch'>-->
<!--                                      <el-button style="margin:0 10px;" slot="reference" type="success" size="small" >快速匹配-->
<!--                                      </el-button>-->
<!--                                    </el-popconfirm>-->
                                </el-form-item>
                            </el-form>
                    
                        <el-button slot="reference" type="success" icon="el-icon-discover" style="margin-right:10px;"
                            size="small" @click="resetBasicScore">匹配专家</el-button>
                    </el-popover>
                    <!-- <el-button type="success" size="small" icon="el-icon-download" @click="downloadPaper">下载论文原件
                    </el-button> -->
                    <!-- <el-button type="success" size="small" @click="SpareMatchHandle" icon="el-icon-discover">备用专家匹配</el-button> -->
                    <el-popconfirm title="是否同批复匹？" @confirm='acceptedMatchHandle'>
                        <el-button slot="reference"  type="success" size="small"  icon="el-icon-discover">同批复匹</el-button>
                    </el-popconfirm>

                    <el-button type="success" size="small" icon="el-icon-plus" style="margin-left:5px"
                        @click="insertDia()">新增专家
                    </el-button>
                </template>
            </vxe-toolbar>
            <vxe-table :header-cell-style="headerCellStyle"  :cell-style="cellStyle" round align="center"
                ref="paperTable" :data="receiptList" element-loading-text="拼命加载中" :loading='loading'
                element-loading-spinner="el-icon-loading">
                <vxe-column field="select" title="选择" width="5%">
                    <template #default="{row}">
                        <div>
                            <!-- <vxe-radio v-model="groupSelected[row.group]" :label="row.resultId"
                                :disabled="row.state != 0 || groupDisabled[row.group]">
                            </vxe-radio> -->
                            <el-checkbox v-model="row.isSelected"
                                :disabled='row.state != 0 || (selectNumber >= expertNumber && !row.isSelected)'
                                @change="checkChange">
                            </el-checkbox>
                        </div>
                    </template>
                </vxe-column>
                <vxe-column field="expertName" title="姓名" width="4%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="institutionName" title="单位" min-width="10%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="title" title="职称" min-width="4%" :show-overflow="'tooltip'">
                    <template #default="{row}">
                        {{$store.state.mapper.titleMap[row.title]}}
                    </template>
                </vxe-column>
                <vxe-column field="type" title="导师类型" min-width="10%" :show-overflow="'tooltip'">
                    <template #default="{row}">
                        <!-- {{row.type}} -->
                        {{$store.state.mapper.typeMap[row.type]}}
                    </template>
                </vxe-column>
                <!-- <vxe-column field="expertLevel" title="接收数" width="5%" :show-overflow="'tooltip'"></vxe-column> -->
                <vxe-column field="firstMajor" title="一级学科" min-width="5%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="secondMajor" title="二级学科" min-width="10%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="accept" title="评审意愿" min-width="5%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="frequency" title="评审速度" min-width="5%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="quality" title="评审质量" min-width="5%" :show-overflow="'tooltip'"></vxe-column>
                <!-- <vxe-column field="responsibility" title="评审责任心" min-width="120" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="singularity" title="评审奇异性" min-width="120" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="mean" title="评审均值" min-width="120" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="standard_deviation" title="评审标准差" min-width="120" :show-overflow="'tooltip'"></vxe-column> -->
                <!-- <vxe-column field="keywords" title="匹配结果关键词" min-width="200" :show-overflow="'tooltip'"></vxe-column> -->
                <vxe-column field="score" title="匹配度" min-width="5%">
                    <template #default="{row}">
                        {{row.score == -1?"":row.score}}
                    </template>
                </vxe-column>
                <vxe-column field="statue" title="状态" min-width="5%" :show-overflow="'tooltip'">
                    <template #default="{row}">
                        <!-- {{row.state}} -->
                        {{$store.state.mapper.stateMap[row.state]}}
                    </template>
                </vxe-column>
                <vxe-column field="changeTime" title="状态变化时间" min-width="10%" :show-overflow="'tooltip'">
                </vxe-column>
                <vxe-column field="operate" title="操作" min-width="18%">
                    <template #default="{ row }">
<!--                      {{row}}-->
                        <el-popconfirm title="确定接审吗？"  @confirm='AcceptanceReviewHandle(row)'>
                            <el-button type="text" :disabled="!(row.state==1)" slot="reference" style="margin-left:5px;">接审
                            </el-button>
                        </el-popconfirm>
                        <el-popconfirm title="确定撤回吗？" @confirm='revokeHandle(row.resultId)'>
                            <el-button type="text" :disabled="!(row.state==2)"  slot="reference" style="margin-left:5px;">撤回
                            </el-button>
                        </el-popconfirm>
                        <el-popconfirm title="确定恢复吗？" @confirm='renewHandle(row.resultId)'>
                            <el-button type="text" :disabled="!(row.state==3 || row.state==4 || row.state==6) " slot="reference" style="margin-left:5px;">恢复
                            </el-button>
                        </el-popconfirm>
                        <el-popconfirm title="确定拒审吗？" :disabled="row.rejectUrl==null || row.rejectUrl===''" @confirm='RefusalReviewHandle(row)'>
                            <el-button type="text" :disabled="!(row.state==1)" slot="reference" style="margin-left:5px;">拒审
                            </el-button>
                        </el-popconfirm>
                        <el-popconfirm title="确定退回吗？" @confirm='goBackHandle(row.resultId)'>
                            <el-button type="text" :disabled="!(row.state==5)" slot="reference" style="margin-left:5px;">退回
                            </el-button>
                        </el-popconfirm>
                        <el-popconfirm title="确定进入专家端吗？" @confirm='changeToExpertHandle(row)'>
                            <el-button type="text" slot="reference" style="margin-left:5px;">进入
                            </el-button>
                        </el-popconfirm>
                        <el-button type="text" style="margin-left:5px;" :style="{'color':row?.urgeText ?'red':'#1890ff'}" @click="urgeHandle(row)">催审</el-button>
                        <!-- <el-button type="text" @click="renewHandle(row.resultId)">恢复</el-button>
                            <el-button type="text" style="margin-left:5px;" @click="goBackHandle(row.resultId)">
                                退回
                            </el-button>
                            <el-button type="text" style="margin-left:5px;" @click="revokeHandle(row.resultId)">
                                撤回
                            </el-button> -->
                    </template>
                </vxe-column>

            </vxe-table>

            <!-- 催审弹窗 -->
            <el-dialog :visible.sync="urgeDialogVisible" title="催审" width="70%">

              <el-table :data="urgelist" v-loading="urgeLoading" element-loading-background = "rgba(0, 0, 0, 0.5)"
                        element-loading-text = "数据正在加载中" element-loading-spinner = "el-icon-loading" :max-height="500"
                        style="display: inline-block; width: auto; margin: 0 auto;">
                <el-table-column property="num" label="序号" width="60" type="index"></el-table-column>
                <el-table-column property="institution" label="单位" width="200" show-overflow-tooltip></el-table-column>
                <el-table-column property="batch" label="批次" width="160"  show-overflow-tooltip></el-table-column>
                <el-table-column property="staff" label="负责人" width="80"></el-table-column>
                <el-table-column property="title" label="题目" width="200" show-overflow-tooltip></el-table-column>
                <el-table-column property="endTime" label="截止时间" width="120" sortable></el-table-column>
                <el-table-column property="returnTime" label="交付时间" width="120" sortable></el-table-column>
                <el-table-column property="urgeText" label="催审记录" width="240"></el-table-column>
                <el-table-column property="urgeText" label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button size="mini" type="text" :style="{'color':scope.row?.urgeText ?'red':'#1890ff'}" @click="urgeDetail(scope.row)">催审备注</el-button>

                  </template>
                </el-table-column>

              </el-table>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="confirmUrge">确认</el-button>
              </div>
            </el-dialog>
            <el-dialog title="催审备注" :visible.sync="urgeDetailDialogVisible" width="30%">
              <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="请输入催审备注"
                  v-model="urgeDetailCurrentRow.urgeText">
              </el-input>
              <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="saveUrgeText()">确 定</el-button>
              </span>
            </el-dialog>
            <div style="text-align:center;padding-top:5px;">
                <el-button type="success" size="small" @click="confirmCheck">确认审核</el-button>
            </div>
        </div>

        <!-- 下载弹窗 -->
        <el-dialog :visible.sync="insertDiaVis" width="40%" :close-on-click-modal="false" class='dialog'>
            <template slot="title">
                <div class='titleDia'>
                    添加评审人
                </div>
            </template>
            <div class="DldiaContent">
                <el-form :model="form" :rules="rules" ref='form' label-width="80px">
                    <el-form-item label="姓名：" prop='name'>
                        <el-input v-model="form.name" autocomplete="off" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="单位：" prop='university'>
                        <el-input v-model="form.university" autocomplete="off" placeholder="请输入单位"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱：" prop='mail'>
                        <el-input v-model="form.mail" autocomplete="off" placeholder="请输入邮箱，手机号和邮箱可二选一">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="手机号：" prop='phone'>
                        <el-input v-model="form.phone" autocomplete="off" placeholder="请输入手机号，手机号和邮箱可二选一">
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="batchSearch">搜索</el-button>
                        <el-button type="primary" @click="insertHandle('form')">添加</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
        <el-drawer title="选择专家" :visible.sync="drawer" size="750">
            <template slot="title">
                <div class='titleDia'>
                    选择专家
                </div>
            </template>
            <div style="margin:10px;margin-top:0px;">
                <vxe-table :header-cell-style="headerCellStyle" class="mytable-scrollbar insertList" round align="center"
                    :data="expertInfos" highlight-current-row highlight-hover-row element-loading-text="拼命加载中" :loading='drawerLoading'
                    element-loading-spinner="el-icon-loading">
                    <vxe-column title="" width="50">
                        <template #default="{ row }">
                            <el-button type="text" size="mini" @click="selectExpert(row)">添加</el-button>
                        </template>
                    </vxe-column>
                    <vxe-column field="name" title="姓名" width="100" :show-overflow="'tooltip'"></vxe-column>
                    <vxe-column field="university" title="单位" width="200" :show-overflow="'tooltip'"></vxe-column>
                    <vxe-column field="mail" title="邮箱" width="250" :show-overflow="'tooltip'">
                    </vxe-column>
                    <vxe-column field="phone" title="手机号" width="150" :show-overflow="'tooltip'">
                    </vxe-column>
                </vxe-table>
            </div>

        </el-drawer>
    </div>
</template>

<script>
import base from "@/api/http/baseUrl";
    export default {
        name: 'sendRecord',
        data() {
            var checkName = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('请输入姓名'));
                }
                callback();
            };
            var checkUniversity = (rule, value, callback) => {
                console.log(value);
                if (!value) {
                    callback(new Error('请输入单位'));
                }
                callback();
            };
            var checkPhone = (rule, value, callback) => {
                if (value != '' && this.form.mail == '') {
                    this.$refs.form.validateField('mail');
                }
                if (value === '' && this.form.mail == '') {
                    callback(new Error('请输入手机号或邮箱'));
                }
                callback();
            };
            var checkMail = (rule, value, callback) => {
                if (value != '' && this.form.phone == '') {
                    this.$refs.form.validateField('phone');
                }
                if (value === '' && this.form.phone == '') {
                    callback(new Error('请输入手机号或邮箱'));
                }
                callback();
            };
            return {
                expertLevel: [],
                paperId: '',
                batchId: '',
                //名称
                labels: {
                    paperType: '送审论文类型',
                    expertTitle: '评审专家职称',
                    expertType: '评审导师类型',
                    universityGrade: '送审单位类型',
                    majorGrade: '送审学科等级',
                    // pattern: '匹配模式',
                    expertNumber: '单篇论文专家数',
                    // expertPaperNumber: '一个专家最多审几篇',
                    // institutionNumber: '一个院校最多几个专家',
                    // endTime: '评审截止时间',
                    mappingMajor: '映射学硕一级学科',
                    mappingSecondMajor:'映射二级学科',
                    templateName: '选择评阅书模板',
                    
                    universityRanking:'院校排名',
                    subMajor: '学科',
                },
                oneLabels: {
                    appointUniversity: '指定院校',
                    debarbUniversity: '回避院校',
                    appointRegion:'指定地区',
                    debarbRegion:'回避地区',
                },
                //展示信息
                auditInfo: {
                    universityGrade: '985',
                    university: '',
                    expertTitle: '正高',
                    expertType: '硕士生导师',
                    majorGrade: 'A、A+',
                    // pattern: '',
                    expertNumber: '3',
                    // expertPaperNumber: '',
                    // institutionNumber: '',
                    paperType: '专业硕士',
                    templateName: 'njust',
                    endTime: '2021-12-12',

                },
                //展示论文信息
                paperLabel: {
                    paperName: '论文名称',
                    paperKeywords: '论文关键词',
                    oldId: '论文编号',
                    studentName: '学生姓名',
                    // subMajor: '学科',
                    note:'备注',
                },
                paperInfo: {
                    paperKeywords: '图像处理',
                    paperName: '论文名称',
                    note:'',
                },
                paperKeywords: '',
                //评阅书列表
                receiptList: [
                    //{
                    //     isSelected: '',
                    //     group: '1',
                    //     expertName: '',
                    //     university: '',
                    //     expertTitle: '',
                    //     expertType: '',
                    //     score: '',
                    //     state: '',
                    // },
                    // {
                    //     isSelected: '',
                    //     group: '1',
                    //     expertName: '',
                    //     university: '',
                    //     expertTitle: '',
                    //     expertType: '',
                    //     score: '',
                    //     state: '',
                    // }, {
                    //     isSelected: '',
                    //     group: '2',
                    //     expertName: '',
                    //     university: '',
                    //     expertTitle: '',
                    //     expertType: '',
                    //     score: '',
                    //     state: '',
                    // }, 
                    // {
                    //     isSelected: '',
                    //     group: '2',
                    //     expertName: '',
                    //     university: '',
                    //     expertTitle: '',
                    //     expertType: '',
                    //     score: '',
                    //     state: '',
                    // }
                ],
                //每组的选择
                groupSelected: {},
                groupDisabled: {},
                //添加弹窗
                insertDiaVis: false,
                //添加信息
                form: {
                    group: '',
                    name: '',
                    phone: '',
                    mail: '',
                    university: '',
                },
                rules: {
                    name: [{
                        validator: checkName,
                        trigger: 'blur'
                    }],
                    university: [{
                        validator: checkUniversity,
                        trigger: 'blur'
                    }],
                    mail: [{
                        validator: checkMail,
                        trigger: 'blur'
                    }],
                    phone: [{
                        validator: checkPhone,
                        trigger: 'blur'
                    }]
                },
                statueColor: {
                    '0': '#000', //'待送审',
                    '1': '#E6A23C', //'送审中',
                    '2': '#67C23A', //'评审中',
                    '3': 'red', //'已拒绝',
                    '4': 'red', //'已过期',
                    '5': '#67C23A', //'已提交',
                    '6': 'red', //'解锁',
                    '7': 'red', //'已撤销'
                },
                selectNumber: 0,
                expertNumber: 0,
                loading: false,
                expertInfos: [

                ],
                drawer: false,
                drawerLoading: false,
                quality: ['A','B'],
                accept: ['A','B','C'],
                frequency: ['A','B','C'],
                avgScore:'',
                checkedExperts:[0],
                isSpare:0,
                isSecondMajor:0,
                basicScore:'',
                oneline:{
                    appointUniversity:'',
                    debarbUniversity:'',
                    appointRegion:'',
                    debarbRegion:''
                },
                urgeDialogVisible: false,
                urgeDetailDialogVisible: false,
                currentRow: null,
                urgelist: [],
                urgeDetailCurrentRow:{},
                // urgeText: null,
            }
        },
        computed: {
            // universityShow() {
            //     return function (key) {
            //         if (key == 'universityGrade' || key == 'university') {
            //             return this.auditInfo[key] != '';
            //         }
            //         return true;
            //     };
            // }
        },
        beforeCreate() {

        },
        created() {
            // window.addEventListener("beforeunload", () => {
            //     localStorage.setItem("userMsg", JSON.stringify(this.$store.state))
            // })
            // if(localStorage.getItem())
            this.paperId = this.$route.params.id;
            // this.batchId = this.$store.state.sendRecord.batchId;
            // this.paperName = this.$store.state.sendRecord.paperName;
            this.getPaperDeatail();
            this.getSendRecord();
        },
        methods: {
          urgeHandle(row) {
            this.currentRow = row;
            console.log("专家这行数据", this.currentRow);
            this.urgeDialogVisible = true;
            let param = {
              expertId: this.currentRow.expertId
            };
            this.urgeLoading = true;
            this.$api.sendingP2p.getUrgeDetail(param, {
              headers: {
                'Content-Type': 'application/json' // 修改为 'application/json'
              }
            })
                .then(res => {
                  console.log(111111111);
                  console.log(res);
                  if (res.data.code == 200) {
                    // this.$message.success("查询成功")
                    this.urgelist = res.data.data;
                    this.urgeLoading = false;
                    console.log("urgelist", this.urgelist);
                  }
                }).catch(err => {
              this.$message.warning("服务器维护");
              console.log(err);
              this.urgeLoading = false;
            })
          },
          urgeDetail(row) {
            this.urgeDetailCurrentRow = row;
            // console.log("这行论文数据", this.urgeDetailCurrentRow);
            this.urgeDetailDialogVisible = true;
            let param = {
              expertId: this.currentRow.expertId,
              paperId: this.urgeDetailCurrentRow.paperId,
            };
            console.log("expertpaper", param);
            this.$api.sendingP2p.getUrgeDetailText(param, {
              headers: {
                'Content-Type': 'application/json' // 修改为 'application/json'
              }
            })
                .then(res => {
                  console.log(111111111);
                  console.log(res);
                  if (res.data.code == 200) {
                    // this.$message.success("查询成功")
                    // this.urgeText = res.data.data;
                    this.urgeDetailCurrentRow.urgeText = res.data.data;
                    this.urgeLoading = false;
                    // console.log("urgelist", this.urgelist.urgeText);
                  }
                }).catch(err => {
              this.$message.warning("服务器维护");
              console.log(err);
              this.urgeLoading = false;
            })

          },
          saveUrgeText(){
            let param = {
              urgeText: this.urgeDetailCurrentRow.urgeText,
              expertId: this.currentRow.expertId,
              paperId: this.urgeDetailCurrentRow.paperId,
            };
            console.log("保存进去的文本", param);
            this.$api.sendingP2p.postAddUrgeText(param)
                .then(res => {
                  if (res.data.code == 1) {
                    this.$message.success("添加成功!");
                    this.urgeDetailDialogVisible = false;
                  } else {
                    this.$message.warning("添加失败!");
                  }
                })
                .catch(err => {
                  this.$message.warning('服务器维护！');
                  console.log("insert",err);
                })
          },
          confirmUrge() {
            console.log('催审操作，当前行数据：', this.currentRow);
            this.urgeDialogVisible = false;
            this.getPaperDeatail();
            this.getSendRecord();
          },
            handleExpertChange(value) {
              if (value.includes(0) && value.includes(1)) {
                this.isSpare = 2;
              } else if (value.includes(0)) {
                this.isSpare = 0;
              } else if (value.includes(1)) {
                this.isSpare = 1;
              } else {
                this.isSpare = null;
              }
            },
            //返回送审管理
            goBack() {
                this.$router.push({
                    name: 'p2p'
                });
            },
            //获取批次详情
            getPaperDeatail() {
                let param = new URLSearchParams();
                param.append("paperId", this.paperId);
                // let seq = '、';
                this.$api.sendingP2p.getPaperDeatail(param)
                    .then(res => {
                        console.log("res",res)
                        let t = res.data.data;
                        this.auditInfo = {
                            universityGrade: t.universityLevel,
                            university: t.university,
                            expertTitle: this.numToStr(t.expertTitle, this.$store.state.mapper.titleMap),
                            expertType: this.numToStr(t.expertType, this.$store.state.mapper.typeMap),
                            majorGrade: t.majorGrade,
                            expertNumber: t.expertNumber,
                            paperType: t.sendingType,
                            templateName: t.reviewModel,
                            endTime: t.endTime,
                            mappingMajor: t.mappingMajor,
                            mappingSecondMajor: t.mappingSecondMajor,
                            universityRanking:t.universityRanking,
                            subMajor: t.subMajor,
                        };
                        this.oneline = {
                            appointUniversity: t.appoint == 1? t.appointUniversity:'',
                            debarbUniversity: t.debarb == 1? t.debarbUniversity:'',
                            appointRegion:t.appoint == 1? t.appointRegion:'',
                            debarbRegion:t.debarb == 1? t.debarbRegion:'',
                        }
                        this.paperInfo = {
                            paperName: t.title,
                            paperKeywords: t.keywords,
                            oldId: t.oldId,
                            studentName: t.studentName,
                            // subMajor: t.subMajor,
                            note: t.note,

                        }
                        console.log("paperInfo", this.paperInfo)
                        this.expertNumber = t.expertNumber;
                    })
                    .catch(err => {
                        this.$message.warning("服务器维护");
                        console.log("getPaperDeatail",err);
                    });
            },
            match(params) {
                //获取已选中的论文
                const loading = this.$loading({
                    lock: true,
                    text: '正在匹配',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let idInfos = [];
                params["idInfos"] = [{
                    paperId: this.paperId
                }];
                this.$api.sendingP2p.getMatch(params)
                    .then(res => {
                        loading.close();
                        if (res.data.code == 200) {
                            this.$message.success("匹配成功！");
                            this.getSendRecord();
                        } else {
                            this.$message.warning("匹配失败!");
                        }
                    }).catch(err => {
                        loading.close();
                        this.$message.warning("服务器维护！");
                        console.log("match",err);
                    })
            },
            matchHandle(isDetailMatch) {
                let params = {
                    matchType: 'in',
                    accept: this.accept.join(','),
                    frequency: this.frequency.join(','),
                    quality: this.quality.join(','),
                    isSpare:this.isSpare,
                    isUsed:0,
                    isSecondMajor:this.isSecondMajor,
                    basicScore: this.basicScore || 80,
                    avgScore:this.avgScore,
                    isDetailMatch: isDetailMatch,
                    resultIds: this.getSelected().join(',')
                };
                // 检查每个条件是否至少选择了一项
                if (!params.accept || !params.frequency || !params.quality) {
                  this.$message.warning("每个条件至少选择一项");
                  return;
                }

                // 检查 isSpare 和 isSecondMajor 是否被定义（不为 null 或 undefined）
                if (params.isSpare === null || params.isSpare === undefined ||
                    params.isSecondMajor === null || params.isSecondMajor === undefined) {
                  this.$message.warning("每个条件至少选择一项");
                  return;
                }
                this.match(params);
            },
            confirmFineMatch() {
              this.matchHandle(1); // 精细匹配时传入参数 1
            },
            confirmQuickMatch() {
              this.matchHandle(0); // 快速匹配时传入参数 0
            },
            resetBasicScore() {
              this.basicScore = ''; // 重置basicScore为空字符串
            },
            changeTime(date){
                    if(!date || date.length < 19) return date;
                    return date.substr(0,19).replace('T',' ');
                },
            
            acceptedMatchHandle(){
                let params = {
                    matchType: 'in',
                    accept: '',
                    frequency: '',
                    quality: '',
                    isSpare:0,
                    isUsed:1,
                    isSecondMajor:0,
                    resultIds: this.getSelected().join(',')
                };
                this.match(params);
            },
            SpareMatchHandle(){
                let params = {
                    matchType: 'in',
                    accept: '',
                    frequency: '',
                    quality: '',
                    isSpare:1,
                    isUsed:0,
                    isSecondMajor:0,
                    resultIds: this.getSelected().join(',')
                };
                this.match(params);
            },
            // 选择改变
            checkChange() {
                this.selectNumber = 0;
                this.receiptList.forEach(item => {
                    if (item.isSelected) {
                        this.selectNumber++;
                    }
                });
                // console.log(this.selectNumber);
            },
            //表格刷新
            refresh() {
                this.getSendRecord();
            },
            //获取论文送审情况
            getSendRecord() {
                let params = {
                    paperId: this.paperId
                };
                this.loading = true;
                this.$api.sendingP2p.getSingleCase(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            console.log("555555555555555");
                            console.log(res.data);
                            this.selectNumber = 0;
                            let arr = res.data.data;
                            arr.forEach((item, index) => {
                                console.log(item);
                                if (item.isSelected == 1) {
                                    arr[index].isSelected = true;
                                    this.selectNumber++;
                                } else arr[index].isSelected = false;
                            })
                            this.receiptList = arr;
                            console.log(this.receiptList);
                            this.loading = false;
                        }
                        // if (res.data.code == 200) {
                        //     console.log(res.data);
                        //     this.selectNumber = 0;
                        //     let arr = res.data.data;
                        //     arr.forEach((item, index) => {
                        //         if (item.isSelected == 1) {
                        //             arr[index].isSelected = true;
                        //             this.selectNumber++;
                        //         } else arr[index].isSelected = false;
                        //         if (this.selectNumber <= this.expertNumber && (item.state == 0 ||item.state == 1||item.state == 2)){
                        //             arr[index].isSelected = true;
                        //             this.selectNumber++;
                        //         }
                        //         // this.selectNumber++;
                        //     })
                        //     this.receiptList = arr;
                        //     console.log(this.receiptList);
                        //     this.loading = false;
                        // }
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                        console.log("getSendRecord",err);
                    })
            },
            //打开添加弹窗
            insertDia(group) {
                this.insertDiaVis = true;
                this.form = {
                    group: '1',
                    name: '',
                    phone: '',
                    mail: '',
                    university: '',
                };
                // this.form.group = group;
            },
            //确认添加验证
            insertHandle(form) {
                console.log(form);
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        this.insert();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //确认添加评阅书
            insert() {
                let param = {
                    group: this.form.group,
                    name: this.form.name,
                    phone: this.form.phone,
                    mail: this.form.mail,
                    university: this.form.university,
                    paperId: this.paperId
                };
                this.$api.sendingP2p.postAddExpert(param)
                    .then(res => {
                        if (res.data.code == 1) {
                            this.getSendRecord();
                            this.$message.success(res.data.msg || "添加成功!");
                            this.insertDiaVis = false;
                        } else {
                            this.$message.warning(res.data.msg || "添加失败!");
                        }

                    })
                    .catch(err => {
                        this.$message.warning('服务器维护！');
                        console.log("insert",err);
                    })
            },
            //获取专家列表
            getexpertList() {
                this.drawerLoading = true;
                let param = new URLSearchParams();
                param.append("expertName", this.form.name);
                param.append("mail", this.form.mail);
                param.append('mobile', this.form.phone);
                param.append('expertInstitution', this.form.university);
                // param.append("pageIndex", pageIndex);
                // param.append("pageSize", pageSize);
                this.$api.basicInfo.getExpertInfo(param)
                    .then(res => {
                        let arr = [];
                        res.data.data.forEach(item => {
                            arr.push({
                                name: item.expertName,
                                university: item.institutionName,
                                mail: item.mail,
                                phone: item.mobile
                            })
                        })
                        this.expertInfos = arr;
                        this.drawerLoading = false;
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                        this.drawerLoading = false;
                        console.log("getexpertList",err);
                    });
            },
            selectExpert(
                row, column, event
            ) {
                this.form = {
                    name: row.name,
                    university: row.university,
                    mail: row.mail,
                    phone: row.phone
                }
                this.drawer = false;
            },
            //搜索
            batchSearch() {
                if (!this.form.mail && !this.form.phone && !this.form.name && !this.form.university) {
                    this.$message.warning("请填写信息");
                    return;
                }
                if (!this.form.mail && !this.form.phone && !this.form.name && this.form.university) {
                    this.$message.warning("暂不支持只根据单位查询");
                    return;
                }
                this.expertInfos = [];
                this.drawer = true;
                this.getexpertList();
            },
            //撤销正在送审的专家
            revokeHandle(id) {
                let params = {
                    resultId: id
                };
                this.$api.sendingP2p.postRevoke(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("撤销成功");
                            this.getSendRecord();
                        } else {
                            this.$message.warning("撤销失败");
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                        console.log("revokeHandle",err);
                    });
            },
            //恢复
            renewHandle(id) {
                let params = {
                    resultId: id
                };
                this.$api.sendingP2p.postRenew(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("恢复成功");
                            this.getSendRecord();
                        } else {
                            this.$message.warning("恢复失败");
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                        console.log("renewHandle",err);
                    });
            },
            //退回
            goBackHandle(id) {
                let params = {
                    resultId: id
                };
                this.$api.sendingP2p.postGoBack(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("退回成功");
                            this.getSendRecord();
                        } else {
                            this.$message.warning("退回失败");
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                        console.log("goBackHandle",err);

                    });
            },
            getSelected() {
                let results = [];
                this.receiptList.forEach(item => {
                    if (item.isSelected) results.push(item.resultId);
                })
                return results;
            },
            removeHandle() {},
            confirmCheck() {
                // let resultIds = this.getSelected().join(',');
                let results = [];
                let map = {};
                let f = true;
                this.receiptList.forEach((item, index) => {
                    if (item.isSelected) {
                        results.push(item.resultId);
                        if (map.hasOwnProperty(item.institutionName)) {
                            f = false;
                        }
                        map[item.institutionName] = 1;
                    }
                })
                let resultIds = results.join(',');
                if (!f) {
                    this.$confirm('选中专家存在重复学校, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        let param = {
                            paperId: this.paperId,
                            resultIds: resultIds
                        };
                        this.$api.sendingP2p.getSelectExpert(param)
                            .then(res => {
                                if (res.data.code == 200) {
                                    this.$message.success("审核成功！");
                                    this.$router.push({
                                        name: 'p2p'
                                    });
                                } else if(res.data.code == 500){
                                    this.$message.success("审核成功！");
                                    this.$router.push({
                                        name: 'p2p'
                                    });
                                }else {
                                    this.$message.warning("审核失败！");
                                }
                            }).catch(err => {
                                this.$message.warning("服务器维护");
                                console.log("getSelectExpert",err);
                            })
                    }).catch(() => {

                    });
                } else {
                    let param = {
                        paperId: this.paperId,
                        resultIds: resultIds
                    };
                    this.$api.sendingP2p.getSelectExpert(param)
                        .then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("审核成功！");
                                this.$router.push({
                                    name: 'p2p'
                                });
                            } else if(res.data.code == 500){
                                    this.$message.success("审核成功！");
                                    this.$router.push({
                                        name: 'p2p'
                                    });
                            }else {
                                this.$message.warning("审核失败！");
                            }
                        }).catch(err => {
                            this.$message.warning("服务器维护");
                            console.log("getSelectExpert",err);
                        })
                }

            },
            //合并表格
            mergeRowMethod({
                row,
                _rowIndex,
                column,
                visibleData
            }) {
                let group = row['group'];
                if (column.property == 'group' || column.property == 'operate') {
                    let prevRow = visibleData[_rowIndex - 1];
                    let nextRow = visibleData[_rowIndex + 1];
                    if (prevRow && prevRow['group'] == group) {
                        return {
                            rowspan: 0,
                            colspan: 0
                        };
                    } else {
                        let countRowspan = 1;
                        while (nextRow && nextRow['group'] == group) {
                            nextRow = visibleData[++countRowspan + _rowIndex]
                        }
                        if (countRowspan > 1) {
                            return {
                                rowspan: countRowspan,
                                colspan: 1
                            };
                        }
                    }
                }
            },
            //数值转字符串
            numToStr(str, mapper) {

                console.log(str, mapper);
                let seq = '、';
                let arr = str.split(seq);
                for (let i = 0; i < arr.length; i++) {
                    arr[i] = mapper[arr[i]];
                }
                return arr.join(seq);
            },
            //表头样式
            headerCellStyle({
                row,
                column,
                rowIndex,
                columnIndex
            }) {

                return "background-color:#e0e0e0f1"
            },
            // //行样式
            // rowStyle({
            //     row,
            //     rowIndex
            // }) {
            //     return {
            //         'border-bottom': '2px solid red'
            //     };
            // },
            cellStyle({
                row,
                rowIndex,
                column,
                columnIndex
            }) {
                //设置状态颜色
                let styles = {};
                if (column.property == 'statue') {
                    styles['color'] = this.statueColor[this.receiptList[rowIndex].state];
                }
                // if (columnIndex == 0) {
                //     styles['border-bottom'] = '1px solid #000';
                // }
                // if (columnIndex == 10) {
                //     styles['border-bottom'] = '1px solid #000';
                //     styles['vertical-align'] = 'top';
                // }
                // if (rowIndex == this.receiptList.length - 1) {
                //     styles['border-bottom'] = '1px solid #000';
                //     return styles;
                // }
                // if (this.receiptList[rowIndex].group != this.receiptList[rowIndex + 1].group) {
                //     styles['border-bottom'] = '1px solid #000';
                // }
                return styles;
            },
            AcceptanceReviewHandle(row){
                if(row.acceptUrl==="" || row.acceptUrl===null){
                    return;
                }
                let url = this.translateHtml(row.acceptUrl);
                console.log(url)
                // return;
                var link = document.createElement('a');
                link.href = url; // 设置目标URL
                // link.innerText = '点击这里'; // 设置显示文本
                link.target = '_blank';
                // link.addEventListener('click', function(event) {
                //     event.preventDefault(); // 阻止默认行为（页面跳转）
                // });
                document.body.appendChild(link);
                link.click();
                this.getSendRecord();
            },
            RefusalReviewHandle(row){
                if(row.rejectUrl==="" || row.rejectUrl===null){
                    return;
                }
                console.log(row)
                let url = this.translateHtml(row.rejectUrl);
                console.log(url);
                // return;
                var link = document.createElement('a');
                link.href = url; // 设置目标URL
                // link.innerText = '点击这里'; // 设置显示文本
                link.target = '_blank';
                // link.addEventListener('click', function(event) {
                //     event.preventDefault(); // 阻止默认行为（页面跳转）
                // });
                document.body.appendChild(link);
                link.click();
                this.getSendRecord();

            },
            changeToExpertHandle(row){

                window.open(`${base.exprtUrl}#/explanation?token=${row.expertToken}`);
            },
            translateHtml(content){
                console.log(content)
                let url = content.split('>')[1].slice(0,-3);
                console.log(url)

                return url;
            }


        },
        components: {

        }
    }
</script>

<style scoped>
    .titleDia{
        font-size: 18px;
        color: black;
    }
    .DldiaContent {
        margin-left: 20px;
        margin-right: 40px;
    }

    .dc2 {
        margin-left: 10%;
        border-bottom-width: 2px;
    }

    .back {
        height: 40px;
        background-color: #fff;
        margin-top: -20px;
        margin-left: -20px;
        margin-bottom: 10px;
    }

    .goback {
        position: fixed;
        padding-left: 10px;
        padding-top: 10px;
        z-index: 100;
        width: 100%;
        height: 40px;
        background-color: #fff;
    }
    .el-form-item {
        margin-bottom: 10px !important;
    }
    
</style>

<style>
    .dialog .el-dialog {
        margin-top: 5vh !important;
        border-radius: 15px;
    }
    .insertList .vxe-table--body-wrapper {
        height: calc(100vh - 150px);
    }
    .tdOverflow .is-bordered{
        table-layout: fixed !important;
    }
</style>