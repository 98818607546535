let handleSubject = [
    {
        "name": "北京大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "世界史"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "哲学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "天文学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "大气科学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "地球物理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "地质学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "科学技术史"
                    },
                    {
                        "ranks": "A",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "核科学与技术"
                    },
                    {
                        "ranks": "A",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "A",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "A",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "戏剧与影视学"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国人民大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "B",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "农林经济管理"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "C",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "清华大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "大气科学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "科学技术史"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "A",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "航空宇航科学与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "核科学与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "风景园林学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京交通大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "系统科学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "工商管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京工业大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "教育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京航空航天大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "航空宇航科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京理工大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "航空宇航科学与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "兵器科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京科技大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "科学技术史"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "A",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "冶金工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "矿业工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "北方工业大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京化工大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "A",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "轻工技术与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "药学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京工商大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "新闻传播学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "食品科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京服装学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京邮电大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京印刷学院",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "新闻传播学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "材料科学与工程"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京建筑大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "风景园林学"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国农业大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "食品科学与工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "草学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "农林经济管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京农学院",
        "majorList": [
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "兽医学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "农林经济管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京林业大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "林业工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "林学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "草学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "农林经济管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京协和医学院",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中药学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "首都医科大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "生物医学工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中药学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "护理学"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京中医药大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "天文学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "系统科学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "核科学与技术"
                    },
                    {
                        "ranks": "A",
                        "majorName": "环境科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "首都师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "科学技术史"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "首都体育学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "体育学"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京外国语大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "新闻传播学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "管理科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京第二外国语学院",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京语言大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国传媒大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "新闻传播学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "B",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "中央财经大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "对外经济贸易大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京物资学院",
        "majorList": [
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "首都经济贸易大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "应用经济学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国人民公安大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "法学"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京体育大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "心理学"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "体育学"
                    }
                ]
            }
        ]
    },
    {
        "name": "中央音乐学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "音乐与舞蹈学"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国音乐学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "音乐与舞蹈学"
                    }
                ]
            }
        ]
    },
    {
        "name": "中央美术学院",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "建筑学"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "中央戏剧学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "戏剧与影视学"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国戏曲学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "戏剧与影视学"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京电影学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京舞蹈学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "音乐与舞蹈学"
                    }
                ]
            }
        ]
    },
    {
        "name": "中央民族大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "民族学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国政法大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "新闻传播学"
                    }
                ]
            }
        ]
    },
    {
        "name": "华北电力大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "物理学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "A",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京信息科技大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "管理科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "北京联合大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "考古学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "南开大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "药学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "天津大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "教育学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "船舶与海洋工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "风景园林学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "天津科技大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "轻工技术与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "食品科学与工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "药学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "天津工业大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "应用经济学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "纺织科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国民航大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "数学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "航空宇航科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "安全科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "天津理工大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "天津农学院",
        "majorList": [
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "水产"
                    }
                ]
            }
        ]
    },
    {
        "name": "天津医科大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "生物医学工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "B",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "护理学"
                    }
                ]
            }
        ]
    },
    {
        "name": "天津中医药大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "中药学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "护理学"
                    }
                ]
            }
        ]
    },
    {
        "name": "天津师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "天津职业技术师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "教育学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "控制科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "天津外国语大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "外国语言文学"
                    }
                ]
            }
        ]
    },
    {
        "name": "天津商业大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "应用经济学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "动力工程及工程热物理"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "天津财经大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "应用经济学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "天津体育学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "体育学"
                    }
                ]
            }
        ]
    },
    {
        "name": "天津音乐学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "音乐与舞蹈学"
                    }
                ]
            }
        ]
    },
    {
        "name": "天津美术学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "天津城建大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "城乡规划学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "河北大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "临床医学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "河北工程大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    }
                ]
            }
        ]
    },
    {
        "name": "河北地质大学",
        "majorList": [
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "河北工业大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生物医学工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "华北理工大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "数学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "冶金工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "矿业工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "护理学"
                    }
                ]
            }
        ]
    },
    {
        "name": "河北科技大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "环境科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "河北农业大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "林学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "农林经济管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "河北医科大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "河北师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "C",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "石家庄铁道大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "交通运输工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "燕山大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "河北经贸大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "法学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "河北中医学院",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中西医结合"
                    }
                ]
            }
        ]
    },
    {
        "name": "山西大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "科学技术史"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "太原科技大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "控制科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "中北大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "数学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "兵器科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "安全科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "太原理工大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "矿业工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "安全科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "山西农业大学",
        "majorList": [
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "草学"
                    }
                ]
            }
        ]
    },
    {
        "name": "山西医科大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "护理学"
                    }
                ]
            }
        ]
    },
    {
        "name": "山西师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "教育学"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "山西财经大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "内蒙古大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "民族学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "软件工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "内蒙古科技大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "冶金工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "矿业工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "内蒙古工业大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学工程与技术"
                    }
                ]
            }
        ]
    },
    {
        "name": "内蒙古农业大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "林业工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "食品科学与工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "B",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "林学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "草学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "农林经济管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "内蒙古医科大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "临床医学"
                    }
                ]
            }
        ]
    },
    {
        "name": "内蒙古师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "民族学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "科学技术史"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "内蒙古财经大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "应用经济学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "辽宁大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "戏剧与影视学"
                    }
                ]
            }
        ]
    },
    {
        "name": "大连理工大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "应用经济学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "船舶与海洋工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "沈阳工业大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学工程与技术"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "管理科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "沈阳航空航天大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "航空宇航科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "沈阳理工大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "东北大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "哲学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "冶金工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "矿业工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "辽宁科技大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "冶金工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "辽宁工程技术大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "矿业工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "管理科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "辽宁石油化工大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "安全科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "沈阳化工大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学工程与技术"
                    }
                ]
            }
        ]
    },
    {
        "name": "大连交通大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "软件工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "大连海事大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "船舶与海洋工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "大连工业大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "纺织科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "轻工技术与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "食品科学与工程"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "沈阳建筑大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "风景园林学"
                    }
                ]
            }
        ]
    },
    {
        "name": "辽宁工业大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "控制科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "沈阳农业大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "林学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "农林经济管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "大连海洋大学",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "海洋科学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "水产"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国医科大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "护理学"
                    }
                ]
            }
        ]
    },
    {
        "name": "锦州医科大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "临床医学"
                    }
                ]
            }
        ]
    },
    {
        "name": "大连医科大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "护理学"
                    }
                ]
            }
        ]
    },
    {
        "name": "辽宁中医药大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "沈阳药科大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "辽宁师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "沈阳师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国语言文学"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "化学工程与技术"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "渤海大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "数学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "食品科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "大连外国语大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "外国语言文学"
                    }
                ]
            }
        ]
    },
    {
        "name": "东北财经大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "法学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "沈阳体育学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "体育学"
                    }
                ]
            }
        ]
    },
    {
        "name": "沈阳音乐学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "音乐与舞蹈学"
                    }
                ]
            }
        ]
    },
    {
        "name": "鲁迅美术学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "沈阳大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "环境科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "大连大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "化学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "软件工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "吉林大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "B",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "地球物理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "地质学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "兽医学"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "延边大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "音乐与舞蹈学"
                    }
                ]
            }
        ]
    },
    {
        "name": "长春理工大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "软件工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "东北电力大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学工程与技术"
                    }
                ]
            }
        ]
    },
    {
        "name": "长春工业大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学工程与技术"
                    }
                ]
            }
        ]
    },
    {
        "name": "吉林建筑大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "城乡规划学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "吉林农业大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "食品科学与工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "兽医学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "农林经济管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "长春中医药大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "东北师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "A",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "北华大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "林业工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "林学"
                    }
                ]
            }
        ]
    },
    {
        "name": "吉林师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学"
                    }
                ]
            }
        ]
    },
    {
        "name": "长春师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "教育学"
                    }
                ]
            }
        ]
    },
    {
        "name": "吉林财经大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "应用经济学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "吉林体育学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "体育学"
                    }
                ]
            }
        ]
    },
    {
        "name": "吉林艺术学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "黑龙江大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "哈尔滨工业大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "科学技术史"
                    },
                    {
                        "ranks": "B",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "海洋科学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "A",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "航空宇航科学与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "风景园林学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "哈尔滨理工大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "数学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "哈尔滨工程大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "船舶与海洋工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "核科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "航空宇航科学与技术"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "黑龙江科技大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "安全科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "东北石油大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "石油与天然气工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "佳木斯大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "材料科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "黑龙江八一农垦大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "食品科学与工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "兽医学"
                    }
                ]
            }
        ]
    },
    {
        "name": "东北农业大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "C",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "B",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "草学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "农林经济管理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "东北林业大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "林业工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "林学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "农林经济管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "哈尔滨医科大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "生物医学工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "黑龙江中医药大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "中药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "哈尔滨师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "哈尔滨商业大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "应用经济学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "食品科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "哈尔滨音乐学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "B",
                        "majorName": "音乐与舞蹈学"
                    }
                ]
            }
        ]
    },
    {
        "name": "复旦大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "A",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "同济大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "新闻传播学"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "体育学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "海洋科学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "地球物理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "风景园林学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "药学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "上海交通大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "天文学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "科学技术史"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "A",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "A",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "船舶与海洋工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "航空宇航科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "核科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "风景园林学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "畜牧学"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "华东理工大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "药学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "上海理工大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "新闻传播学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "系统科学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "上海海事大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "船舶与海洋工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "东华大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "科学技术史"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "纺织科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "C",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "上海电力大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学工程与技术"
                    }
                ]
            }
        ]
    },
    {
        "name": "上海应用技术大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学工程与技术"
                    }
                ]
            }
        ]
    },
    {
        "name": "上海海洋大学",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "海洋科学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "食品科学与工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "水产"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "农林经济管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "上海中医药大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "中药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "华东师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "体育学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "海洋科学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "上海师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "B",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "环境科学与工程"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "上海外国语大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "新闻传播学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "上海财经大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "上海对外经贸大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "华东政法大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    }
                ]
            }
        ]
    },
    {
        "name": "上海体育学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "体育学"
                    }
                ]
            }
        ]
    },
    {
        "name": "上海音乐学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "戏剧与影视学"
                    }
                ]
            }
        ]
    },
    {
        "name": "上海戏剧学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "上海大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "冶金工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "上海工程技术大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "材料科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "上海政法学院",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "法学"
                    }
                ]
            }
        ]
    },
    {
        "name": "南京大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "A",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "天文学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "大气科学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "地质学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "海洋科学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "苏州大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "纺织科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "风景园林学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "东南大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "应用经济学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "风景园林学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "B",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "南京航空航天大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "航空宇航科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "核科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "南京理工大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "航空宇航科学与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "兵器科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "江苏科技大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "船舶与海洋工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国矿业大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "地质学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "矿业工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "南京工业大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "化学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "轻工技术与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "药学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "常州大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "安全科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "南京邮电大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "河海大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "地理学"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "海洋科学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "江南大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "教育学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "纺织科学与工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "轻工技术与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "药学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "南京林业大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "轻工技术与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "林业工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "林学"
                    }
                ]
            }
        ]
    },
    {
        "name": "江苏大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "药学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "南京信息工程大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "大气科学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "科学技术史"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "南通大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中国语言文学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "控制科学与工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "临床医学"
                    }
                ]
            }
        ]
    },
    {
        "name": "南京农业大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "风景园林学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "机械工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "水产"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "草学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "农林经济管理"
                    },
                    {
                        "ranks": "A",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "南京医科大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "护理学"
                    }
                ]
            }
        ]
    },
    {
        "name": "徐州医科大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "南京中医药大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "中药学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国药科大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中药学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "南京师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "A",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "江苏师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "光学工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "南京财经大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "南京体育学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "体育学"
                    }
                ]
            }
        ]
    },
    {
        "name": "南京艺术学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "苏州科技大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "风景园林学"
                    }
                ]
            }
        ]
    },
    {
        "name": "扬州大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "食品科学与工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "B",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "草学"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "中西医结合"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "戏剧与影视学"
                    }
                ]
            }
        ]
    },
    {
        "name": "南京审计大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "应用经济学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "浙江大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "B",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "体育学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "海洋科学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "地质学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "船舶与海洋工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "航空宇航科学与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "兽医学"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "农林经济管理"
                    },
                    {
                        "ranks": "A",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "杭州电子科技大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "浙江工业大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "物理学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "药学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "浙江理工大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "纺织科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "管理科学与工程"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "浙江海洋大学",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "海洋科学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "水产"
                    }
                ]
            }
        ]
    },
    {
        "name": "浙江农林大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "林业工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "林学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "农林经济管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "温州医科大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "生物医学工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中药学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "护理学"
                    }
                ]
            }
        ]
    },
    {
        "name": "浙江中医药大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中药学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "护理学"
                    }
                ]
            }
        ]
    },
    {
        "name": "浙江师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "C",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "杭州师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "温州大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    }
                ]
            }
        ]
    },
    {
        "name": "浙江工商大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "食品科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国美术学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国计量大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "控制科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "管理科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "浙江财经大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "法学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "宁波大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "食品科学与工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "水产"
                    }
                ]
            }
        ]
    },
    {
        "name": "安徽大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "B",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "软件工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国科学技术大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "天文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "大气科学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "地球物理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "地质学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "科学技术史"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "核科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "合肥工业大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "地质学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "安徽工业大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "冶金工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学工程与技术"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "安徽理工大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "矿业工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "安全科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "安徽工程大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "控制科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "安徽农业大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "C",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "林学"
                    }
                ]
            }
        ]
    },
    {
        "name": "安徽医科大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "心理学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "蚌埠医学院",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "临床医学"
                    }
                ]
            }
        ]
    },
    {
        "name": "安徽中医药大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中药学"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "安徽师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "体育学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "体育学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "地理学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "淮北师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "教育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "化学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "软件工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "安徽财经大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "法学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "安徽建筑大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "城乡规划学"
                    }
                ]
            }
        ]
    },
    {
        "name": "厦门大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "民族学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "B",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "海洋科学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "药学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "华侨大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "化学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "福州大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "福建农林大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "林业工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "C",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "林学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "农林经济管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "集美大学",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "数学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "食品科学与工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "水产"
                    }
                ]
            }
        ]
    },
    {
        "name": "福建中医药大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中药学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "护理学"
                    }
                ]
            }
        ]
    },
    {
        "name": "福建师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "南昌大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "食品科学与工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "华东交通大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "交通运输工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "东华理工大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "核科学与技术"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "南昌航空大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "软件工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "江西理工大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "冶金工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "矿业工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "景德镇陶瓷大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "材料科学与工程"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "江西农业大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "林学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "农林经济管理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "江西中医药大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "江西师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "地理学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "管理科学与工程"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "赣南师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C",
                        "majorName": "教育学"
                    }
                ]
            }
        ]
    },
    {
        "name": "江西财经大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "山东大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "A",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国海洋大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "大气科学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "地质学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "海洋科学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "A+",
                        "majorName": "水产"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "药学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "农林经济管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "山东科技大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "矿业工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "管理科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "青岛科技大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "化学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "安全科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "济南大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "环境科学与工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "药学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "青岛理工大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "风景园林学"
                    }
                ]
            }
        ]
    },
    {
        "name": "山东建筑大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "城乡规划学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "齐鲁工业大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "轻工技术与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "山东理工大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "应用经济学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "农业工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "山东农业大学",
        "majorList": [
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "B",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "林学"
                    }
                ]
            }
        ]
    },
    {
        "name": "青岛农业大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "兽医学"
                    }
                ]
            }
        ]
    },
    {
        "name": "山东中医药大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "C",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "山东师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "C",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "管理科学与工程"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "曲阜师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "控制科学与工程"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "音乐与舞蹈学"
                    }
                ]
            }
        ]
    },
    {
        "name": "聊城大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "软件工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "鲁东大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "外国语言文学"
                    }
                ]
            }
        ]
    },
    {
        "name": "山东财经大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "山东体育学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "体育学"
                    }
                ]
            }
        ]
    },
    {
        "name": "山东工艺美术学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "青岛大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "系统科学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "纺织科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "音乐与舞蹈学"
                    }
                ]
            }
        ]
    },
    {
        "name": "烟台大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "法学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "数学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "土木工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "华北水利水电大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "数学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "农业工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "郑州大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "音乐与舞蹈学"
                    }
                ]
            }
        ]
    },
    {
        "name": "河南理工大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "数学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "矿业工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "河南工业大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "食品科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "河南科技大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "食品科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "中原工学院",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "纺织科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "河南农业大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "C",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "林学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "草学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "农林经济管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "河南中医药大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "新乡医学院",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "河南大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "C",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学工程与技术"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "临床医学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "河南师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "环境科学与工程"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "信阳师范学院",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "数学"
                    }
                ]
            }
        ]
    },
    {
        "name": "河南财经政法大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "法学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "武汉大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "地球物理学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "轻工技术与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "A",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "华中科技大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "新闻传播学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "A",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "船舶与海洋工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "武汉科技大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "冶金工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "矿业工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "公共卫生与预防医学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "长江大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "地球物理学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "石油与天然气工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "作物学"
                    }
                ]
            }
        ]
    },
    {
        "name": "武汉工程大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "化学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学工程与技术"
                    }
                ]
            }
        ]
    },
    {
        "name": "武汉纺织大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "纺织科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "环境科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "管理科学与工程"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "武汉轻工大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "食品科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "武汉理工大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "矿业工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "船舶与海洋工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "湖北工业大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "轻工技术与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "华中农业大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "轻工技术与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "林学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "水产"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "农林经济管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "湖北中医药大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "华中师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "A",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "湖北大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "电子科学与技术"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "湖北师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "教育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学"
                    }
                ]
            }
        ]
    },
    {
        "name": "中南财经政法大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "农林经济管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "武汉体育学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "体育学"
                    }
                ]
            }
        ]
    },
    {
        "name": "湖北美术学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "中南民族大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "民族学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "江汉大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "化学工程与技术"
                    }
                ]
            }
        ]
    },
    {
        "name": "三峡大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "水利工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "湘潭大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "吉首大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "民族学"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "生态学"
                    }
                ]
            }
        ]
    },
    {
        "name": "湖南大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "城乡规划学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "中南大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "心理学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "地质学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "冶金工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "矿业工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "湖南科技大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "化学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "矿业工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "安全科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "长沙理工大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "食品科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "湖南农业大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "水产"
                    },
                    {
                        "ranks": "C",
                        "majorName": "草学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "农林经济管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "中南林业科技大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "林业工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "林学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "湖南中医药大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "湖南师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "基础医学"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "南华大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "矿业工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "核科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "基础医学"
                    }
                ]
            }
        ]
    },
    {
        "name": "湖南工业大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物医学工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "中山大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "民族学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "大气科学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "地质学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "海洋科学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "A",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "暨南大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "食品科学与工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中药学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "汕头大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "新闻传播学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "土木工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "药学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "华南理工大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "新闻传播学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "轻工技术与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "风景园林学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "华南农业大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "B",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "林学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "草学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "农林经济管理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "广东海洋大学",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "海洋科学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "食品科学与工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "水产"
                    }
                ]
            }
        ]
    },
    {
        "name": "广州医科大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "护理学"
                    }
                ]
            }
        ]
    },
    {
        "name": "广东医科大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "临床医学"
                    }
                ]
            }
        ]
    },
    {
        "name": "广州中医药大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "广东药科大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "华南师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "广州体育学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "体育学"
                    }
                ]
            }
        ]
    },
    {
        "name": "广州美术学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "星海音乐学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "音乐与舞蹈学"
                    }
                ]
            }
        ]
    },
    {
        "name": "深圳大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "新闻传播学"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "城乡规划学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "广东财经大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "广州大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "环境科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "广东工业大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "广东外语外贸大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "南方医科大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "生物医学工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "B",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中药学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "护理学"
                    }
                ]
            }
        ]
    },
    {
        "name": "广西大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "新闻传播学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "轻工技术与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "兽医学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "桂林电子科技大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "数学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "软件工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "桂林理工大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "广西医科大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "护理学"
                    }
                ]
            }
        ]
    },
    {
        "name": "广西中医药大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "桂林医学院",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "基础医学"
                    }
                ]
            }
        ]
    },
    {
        "name": "广西师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "广西艺术学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "B",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "广西民族大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "民族学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "科学技术史"
                    }
                ]
            }
        ]
    },
    {
        "name": "海南大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中国语言文学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "水产"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "海南师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生态学"
                    }
                ]
            }
        ]
    },
    {
        "name": "重庆大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "新闻传播学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "冶金工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "矿业工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "风景园林学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "重庆邮电大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "管理科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "重庆交通大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "交通运输工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "重庆医科大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "护理学"
                    }
                ]
            }
        ]
    },
    {
        "name": "西南大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "风景园林学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "B",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "水产"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "农林经济管理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "重庆师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "考古学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "系统科学"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "四川外国语大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "外国语言文学"
                    }
                ]
            }
        ]
    },
    {
        "name": "西南政法大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "新闻传播学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "四川美术学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "C",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "重庆理工大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "材料科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "重庆工商大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "环境科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "四川大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "民族学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "A",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "B",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "系统科学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "纺织科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "轻工技术与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "核科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "风景园林学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "西南交通大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "药学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "电子科技大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "西南石油大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "数学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "石油与天然气工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "成都理工大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "地球物理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "地质学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "石油与天然气工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "核科学与技术"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "管理科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "西南科技大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "环境科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "成都信息工程大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "管理科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "西华大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "四川农业大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "C",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "林学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "草学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "农林经济管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "西南医科大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "临床医学"
                    }
                ]
            }
        ]
    },
    {
        "name": "成都中医药大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "C",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中药学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "护理学"
                    }
                ]
            }
        ]
    },
    {
        "name": "四川师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "地理学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "西华师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生态学"
                    }
                ]
            }
        ]
    },
    {
        "name": "西南财经大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "成都体育学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "体育学"
                    }
                ]
            }
        ]
    },
    {
        "name": "四川音乐学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "音乐与舞蹈学"
                    }
                ]
            }
        ]
    },
    {
        "name": "西南民族大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "民族学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "畜牧学"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "贵州大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "法学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "冶金工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "C",
                        "majorName": "林学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "贵州医科大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "贵州师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生态学"
                    }
                ]
            }
        ]
    },
    {
        "name": "贵州财经大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "贵州民族大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "民族学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中国语言文学"
                    }
                ]
            }
        ]
    },
    {
        "name": "云南大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "民族学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "系统科学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "昆明理工大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "法学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "冶金工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "矿业工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "云南农业大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "科学技术史"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "食品科学与工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "B",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "B",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "草学"
                    }
                ]
            }
        ]
    },
    {
        "name": "西南林业大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "林业工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "林学"
                    }
                ]
            }
        ]
    },
    {
        "name": "昆明医科大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "云南师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "农业工程"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "戏剧与影视学"
                    }
                ]
            }
        ]
    },
    {
        "name": "云南财经大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "云南艺术学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "云南民族大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "民族学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "化学"
                    }
                ]
            }
        ]
    },
    {
        "name": "西藏民族大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "民族学"
                    }
                ]
            }
        ]
    },
    {
        "name": "西北大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "地质学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "科学技术史"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "软件工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "西安交通大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "新闻传播学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "A",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "航空宇航科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "核科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "B",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "护理学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "西北工业大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生物学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "A",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "船舶与海洋工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "航空宇航科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "兵器科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "公共管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "西安理工大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "轻工技术与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "环境科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "西安电子科技大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "A",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "管理科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "西安工业大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    }
                ]
            }
        ]
    },
    {
        "name": "西安建筑科技大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "冶金工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "管理科学与工程"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "西安科技大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "矿业工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "安全科学与工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "西安石油大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "石油与天然气工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "陕西科技大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "轻工技术与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "西安工程大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "纺织科学与工程"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "长安大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "建筑学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "城乡规划学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "软件工程"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "西北农林科技大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "社会学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "轻工技术与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "风景园林学"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "林学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "草学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "农林经济管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "陕西中医药大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "陕西师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "民族学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "考古学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中国史"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "世界史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生态学"
                    }
                ]
            }
        ]
    },
    {
        "name": "延安大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "马克思主义理论"
                    }
                ]
            }
        ]
    },
    {
        "name": "西安外国语大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "外国语言文学"
                    }
                ]
            }
        ]
    },
    {
        "name": "西北政法大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "法学"
                    }
                ]
            }
        ]
    },
    {
        "name": "西安体育学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "体育学"
                    }
                ]
            }
        ]
    },
    {
        "name": "西安音乐学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "音乐与舞蹈学"
                    }
                ]
            }
        ]
    },
    {
        "name": "西安美术学院",
        "majorList": [
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "艺术学理论"
                    },
                    {
                        "ranks": "A",
                        "majorName": "美术学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "西安邮电大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "应用经济学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "软件工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "兰州大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "民族学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "大气科学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "地质学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "核科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "环境科学与工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "草学"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "药学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "兰州理工大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学工程与技术"
                    }
                ]
            }
        ]
    },
    {
        "name": "兰州交通大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "交通运输工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "环境科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "甘肃农业大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "食品科学与工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "林学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "草学"
                    }
                ]
            }
        ]
    },
    {
        "name": "甘肃中医药大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "西北师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国史"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "地理学"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "音乐与舞蹈学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "戏剧与影视学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "兰州财经大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "应用经济学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "西北民族大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "民族学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国语言文学"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "音乐与舞蹈学"
                    }
                ]
            }
        ]
    },
    {
        "name": "青海大学",
        "majorList": [
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "草学"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "临床医学"
                    }
                ]
            }
        ]
    },
    {
        "name": "青海师范大学",
        "majorList": [
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "地理学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    }
                ]
            }
        ]
    },
    {
        "name": "宁夏大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "民族学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "外国语言文学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "水利工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "草学"
                    }
                ]
            }
        ]
    },
    {
        "name": "宁夏医科大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "临床医学"
                    }
                ]
            }
        ]
    },
    {
        "name": "北方民族大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "民族学"
                    }
                ]
            }
        ]
    },
    {
        "name": "新疆大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中国语言文学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "新闻传播学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "生态学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "软件工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "新疆农业大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "水利工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "草学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "农林经济管理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "石河子大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "教育学"
                    }
                ]
            },
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "农业工程"
                    }
                ]
            },
            {
                "name": "农学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "兽医学"
                    }
                ]
            },
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "临床医学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "农林经济管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "新疆医科大学",
        "majorList": [
            {
                "name": "医学",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "护理学"
                    }
                ]
            }
        ]
    },
    {
        "name": "新疆师范大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "民族学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "教育学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中国语言文学"
                    }
                ]
            },
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "体育学"
                    }
                ]
            },
            {
                "name": "艺术学",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "美术学"
                    }
                ]
            }
        ]
    },
    {
        "name": "新疆财经大学",
        "majorList": [
            {
                "name": "人文社科类",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "应用经济学"
                    }
                ]
            },
            {
                "name": "理学",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "统计学"
                    }
                ]
            },
            {
                "name": "管理学",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "空军工程大学",
        "majorList": [
            {
                "name": "工学",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "航空宇航科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "动力工程及工程热物理"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国科学院大学",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "天文学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "大气科学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "海洋科学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "地球物理学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "地质学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "系统科学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "科学技术史"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "统计学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "A",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "A",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "A",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "航空宇航科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "核科学与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "B",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "林学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "水产"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "中药学"
                    },
                    {
                        "ranks": "A",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国地质大学（武汉）",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "地理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "海洋科学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "地球物理学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "地质学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "统计学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "水利工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "石油与天然气工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "安全科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "工商管理"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "公共管理"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "设计学"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国石油大学（华东）",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "地球物理学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "地质学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "石油与天然气工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "安全科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "国防科技大学",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "外国语言文学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "系统科学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "力学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "A",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "航空宇航科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "软件工程"
                    },
                    {
                        "ranks": "A",
                        "majorName": "管理科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国农业科学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "农业工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "环境科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "作物学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "园艺学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "农业资源与环境"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "植物保护"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "畜牧学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "兽医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "草学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "管理科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "农林经济管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "第四军医大学",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "心理学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生物医学工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "口腔医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中药学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "护理学"
                    }
                ]
            }
        ]
    },
    {
        "name": "解放军理工大学",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "数学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "大气科学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "海洋科学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "光学工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "土木工程"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "兵器科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "软件工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "第二军医大学",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "A-",
                        "majorName": "基础医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "公共卫生与预防医学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "药学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "中药学"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "护理学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "公共管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "海军工程大学",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "材料科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "动力工程及工程热物理"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "船舶与海洋工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "兵器科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "核科学与技术"
                    }
                ]
            }
        ]
    },
    {
        "name": "上海社会科学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中国史"
                    }
                ]
            }
        ]
    },
    {
        "name": "海军航空工程学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "航空宇航科学与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "软件工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "解放军信息工程大学",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "仪器科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "电子科学与技术"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "A+",
                        "majorName": "测绘科学与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "软件工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "火箭军工程大学",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "控制科学与工程"
                    },
                    {
                        "ranks": "B",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "航空宇航科学与技术"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "兵器科学与技术"
                    }
                ]
            }
        ]
    },
    {
        "name": "郑州轻工业学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "机械工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "电气工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "计算机科学与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "食品科学与工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "软件工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "中共中央党校",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "哲学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "社会学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    }
                ]
            }
        ]
    },
    {
        "name": "煤炭科学研究总院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "B",
                        "majorName": "地质资源与地质工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "矿业工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "安全科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国环境科学研究院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "理论经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "生物学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "生态学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "环境科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "南京政治学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "B-",
                        "majorName": "新闻传播学"
                    },
                    {
                        "ranks": "B",
                        "majorName": "图书情报与档案管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "四川省社会科学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "法学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "政治学"
                    }
                ]
            }
        ]
    },
    {
        "name": "西安财经学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "应用经济学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "统计学"
                    },
                    {
                        "ranks": "C+",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国原子能科学研究院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "物理学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "化学"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "核科学与技术"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国中医科学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "A-",
                        "majorName": "中西医结合"
                    },
                    {
                        "ranks": "B+",
                        "majorName": "中药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "装备学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "信息与通信工程"
                    },
                    {
                        "ranks": "C",
                        "majorName": "航空宇航科学与技术"
                    },
                    {
                        "ranks": "C",
                        "majorName": "兵器科学与技术"
                    }
                ]
            }
        ]
    },
    {
        "name": "广西师范学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "马克思主义理论"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "教育学"
                    }
                ]
            }
        ]
    },
    {
        "name": "贵阳中医学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "中医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "中药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "国家海洋局第一海洋研究所",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B",
                        "majorName": "海洋科学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "环境科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "黑龙江省社会科学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "政治学"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "世界史"
                    }
                ]
            }
        ]
    },
    {
        "name": "四川理工学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "化学工程与技术"
                    },
                    {
                        "ranks": "C-",
                        "majorName": "管理科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "遵义医学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "临床医学"
                    },
                    {
                        "ranks": "C",
                        "majorName": "药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "成都学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "药学"
                    }
                ]
            }
        ]
    },
    {
        "name": "甘肃政法学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "法学"
                    }
                ]
            }
        ]
    },
    {
        "name": "国家海洋局第二海洋研究所",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "海洋科学"
                    }
                ]
            }
        ]
    },
    {
        "name": "湖北民族学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "民族学"
                    }
                ]
            }
        ]
    },
    {
        "name": "湖南商学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "工商管理"
                    }
                ]
            }
        ]
    },
    {
        "name": "军事经济学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B-",
                        "majorName": "应用经济学"
                    }
                ]
            }
        ]
    },
    {
        "name": "昆明贵金属研究所",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C",
                        "majorName": "材料科学与工程"
                    }
                ]
            }
        ]
    },
    {
        "name": "西安热工研究院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C+",
                        "majorName": "动力工程及工程热物理"
                    }
                ]
            }
        ]
    },
    {
        "name": "云南中医学院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "C-",
                        "majorName": "中医学"
                    }
                ]
            }
        ]
    },
    {
        "name": "中国电力科学研究院",
        "majorList": [
            {
                "name": "",
                "majorList": [
                    {
                        "ranks": "B+",
                        "majorName": "电气工程"
                    }
                ]
            }
        ]
    }
]
export default handleSubject;