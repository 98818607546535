import h from '@/api/http/http'
import {
    Upload
} from 'element-ui';

const http = h.http;
const get = h.get;
const post = h.post;

const reviewCheck = {
    // 生成评阅书zip
    getReviewsZip(params) {
        return get('/sending/generate_reviewsZip', params);
    },
    // 下载zip
    getZip(params) {
        return http.get('/sending/download_zip', {
            params: params,
            responseType: 'blob'
        });
    },
    // 获取论文列表
    getReviews(params) {
        return get('/sending/get_checking_reviews', params);
    },
    // 下载评审信息表
    getStatisticsTable(params) {
        return http.post('/sending/generateStatisticsTable', params);
    },
    // 下载临时评审信息表
    getTemporaryStatisticsTable(params) {
        return http.post('/sending/generateTemporaryStatisticsTable', params);
    },

    // 导出table
    getTables(params) {
        return http.get('/sending/download_excel', {
            params: params,
            responseType: 'blob'
        });
    },
    // 获取送审规则
    getSendingRules(params) {
        return get('/school/getSendingRules', params);
    },

    // 重新生成
    postRegenerateReviews(params) {
        return post('/sending/regenerate_reviews', params);
    },
    // 更新评阅书名称
    postRegenerateReviewsName(params) {
        return post('/sending/regenerate_reviews_name', params);
    },

    // 院校发布上传
    postUploadBackTable(params) {
        return post('sending/uploadBackTable', params);
    },
    // 修改为已退回
    cancelReview(params) {
        return get('sending/cancelReview', params);
    },
    // 上传审核信息
    UploadCheckInfo(params) {
        return http.post('/sending/uploadReviews', params, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
    },
    // 上传替换文件
    UploadReplaceInfo(params) {
        return http.post('/sending/replaceReviewFile', params, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
    },
    // 上传评审信息表
    uploadExcelData(params){
        return post('/excel/uploadExcelData',params);
    },
    downloadSchoolExcel(params){
        return http.post('/excel/downloadSchoolExcel',params,{
            responseType: 'blob'
        });
    },
    downloadSchoolExcel_v2(params){
        return http.post('/excel/downloadPublishedExcel',params,{
            responseType: 'blob'
        });
    },
   reGeneratePdf(params){
        return post('sending/reGeneratePdf',params);
   },

    // 生成特殊评阅书zip
    getSpecialReviewsZip(params) {
        return post('/sending/generate_special_reviewsZip', params);
    },

    // 下载特殊评审信息表
    getSpecialStatisticsTable(params) {
        return http.post('/sending/generateSpecialStatisticsTable', params);
    },

    downloadSpecialSchoolExcel_v2(params){
        return http.post('/excel/downloadSpecialPublishedExcel',params,{
            responseType: 'blob'
        });
    },
    getSendingRuleDetailId(params) {
        return http.get('/sending/get_sendingRuleDetailId',{
            params: params // params 会自动转化为查询字符串
        });
    },
    getSheetValues(params) {
        return http.get('/sending/getSheetValues',{
            params: params // params 会自动转化为查询字符串
        });
    }
}

export default reviewCheck